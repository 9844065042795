import { useQuery } from "react-query";
import { useParams, useSearchParams } from "react-router-dom"
import WrapperApp from "../../components/WrapperApp"
import { getPostForEdit } from "./services/post.services";
import SectionCreatePost from "./views/create"
import SkeletonPost from "./views/show/components/SkeletonPost";

const SectionPost = () => {
    const query = useParams();
    const {isLoading, data, isError} =  useQuery(query.id || "edit", () => {
        if(query.id) return getPostForEdit(query.id) 
        return null
    }, {refetchOnWindowFocus: false})

    if(isLoading){
        return(
            <WrapperApp>
                <SkeletonPost/>
            </WrapperApp>
        )
    }

    if(isError){
        return(
            <WrapperApp>
                <p>Ocurrio un error</p>
            </WrapperApp>
        )
    }

    return(
        <WrapperApp>
            <SectionCreatePost initData={data || null} />
        </WrapperApp>
    )
}

export default SectionPost