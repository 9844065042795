import ReactDropdown from "react-dropdown";
//import { useQuery } from "react-query";
//import { OptionSelect } from "../../../Posts/interfaces/post.interfaces";
//import { getPostTypes } from "../../services/publication.services";

const options = [
    {
        value: '1',
        label: <div>
            <i className="fa-solid fa-globe"></i>
            &nbsp;Publicos&nbsp;
        </div>
    },
    {
        value: '2',
        label: <div>
            <i className="fa-solid fa-lock"></i>
            &nbsp;Privados&nbsp;
        </div>
    },
    /*{
        value: '3',
        label: <div>
            <i className="fa-solid fa-bullhorn"></i>
            &nbsp;Publicados&nbsp;
        </div>
    },*/
    {
        value: '4',
        label: <div>
            <i className="fa-solid fa-eraser"></i>
            &nbsp;Borradores&nbsp;
        </div>
    }
    /*{
        value: '5',
        label: <div>
            <i className="fa-solid fa-trash"></i>
            &nbsp;Eliminados&nbsp;
        </div>
    },*/
];

interface props {
    classmane?: string
    action: (id: number) => void
    value: number
}

const SelectTypePosts = ({classmane,action,value}:props) => {
    /*const {isLoading, data, isError} =  useQuery<OptionSelect[]>("typePosts",getPostTypes, {refetchOnWindowFocus: false})

    const options = data ? data.map( (item) => {
        return {
            value: item.value,
            label: <div>
                {item.label}
            </div>
        } 
    } ) : []*/

    return (
        <ReactDropdown
            //disabled={isLoading || isError}
            arrowClosed={<i className="fa-solid fa-angle-down"></i>}
            arrowOpen={<i className="fa-solid fa-angle-up"></i>}
            className={classmane ? classmane : 'input-select'} 
            options={ options }
            onChange={(data) => action(parseInt(data.value))}
            value={value ? value.toString() : ""} 
            placeholder="Estado"
        />
    )
}

export default SelectTypePosts