import Skeleton from "react-loading-skeleton"
import { useQuery } from "react-query"
import Header from "../../../../components/Header"
import { IProfile } from "../../../../interfaces/profile.interfaces"
import HeaderProfile from "../../components/HeaderProfile"
import SkeletonHeaderProfile from "../../components/HeaderProfile/skeleton"
import UIRoutes from "../../components/UiRoutes"
import { getDataProfile } from "../../services/profile.services"
import { StyledProfileBody } from "./styles"

const ViewProfile = () => {
    const { data, isLoading, isError } = useQuery<IProfile | undefined>("data-profile", getDataProfile, {refetchOnWindowFocus: false} )

    if(isLoading) {
        return(
            <div>
                <Header navigation={["Perfil"]}/>
                <SkeletonHeaderProfile/>
                <StyledProfileBody>
                    <Skeleton width="60%" height="50%"/>
                </StyledProfileBody>
            </div>
        )
    }

    if(!data || Object.keys(data).length === 0 || isError ){
        return(
            <div>
                <Header navigation={["Perfil"]}/>
                <StyledProfileBody>
                    <p>OCURRIO UN ERROR</p>
                </StyledProfileBody>
            </div>
        ) 
    }

    return(
        <div>
            <Header navigation={["Perfil"]}/>
            <HeaderProfile {...data} />
            <StyledProfileBody>
                <UIRoutes {...data}/>
            </StyledProfileBody>
        </div>
    )
}

export default ViewProfile