import { StyledViewAds, StyledViewAdsFilters, StyledViewAdsMain } from "./styles";
import LineChart from "./components/LineChart";
import CircleChart from "./components/CircleChart";
import BarChart from "./components/BarChart";
import { useState } from "react";
import SelectMonths from "../../../../components/Selects/SelectMonths";

interface props {
  idAds: string
}

const ViewAds = ({ idAds }: props) => {
  const [filters, setFilters] = useState({
    type: 1,
    month: (new Date()).getMonth()
  });

  return (
    <StyledViewAdsMain>
      <StyledViewAdsFilters>
        <div className="filter-days">
          <button
            onClick={() => setFilters({ ...filters, type: 1 })} 
            className={filters.type === 1 ? "active" : ""}
          >
            Los ultimos 7 dias
          </button>
          <button
            onClick={() => setFilters({ ...filters, type: 2 })} 
            className={filters.type === 2 ? "active" : ""}
          >
            Los ultimos 28 dias
          </button>
          <button
            onClick={() => setFilters({ ...filters, type: 3 })} 
            className={filters.type === 3 ? "active" : ""}
          >
            Anual
          </button>
        </div>
        <div className="filter-select-container">
          <SelectMonths 
            defaultValue={filters.month} 
            styleName="filter-select" 
            handleChange={(val) => setFilters({ ...filters, month: val.value })} 
          />
        </div>
      </StyledViewAdsFilters>
      <StyledViewAds>
        <div className="ads-main">
          <LineChart filters={filters} idAds={idAds} />
        </div>
        <div className="ads-aside">
          <CircleChart filters={filters} idAds={idAds} />
          <BarChart filters={filters} idAds={idAds} />
        </div>
      </StyledViewAds>
    </StyledViewAdsMain>
  )
}

export default ViewAds