import styled from "styled-components";
import { BG_COLOR, BG_CONTRAST_COLOR, PRIMARY_COLOR, SECONDARY_COLOR, TEXT_COLOR } from "../../../../../../styled-components/variables";

export const StyledCreatePost = styled.div`
    width: 100%;
`

export const StyledBodyCreatePost = styled.div`
    width: 100%;
`

export const StyledHeaderCreatePost = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title{
        font-size: 20px;
        margin: 5px 0;
        color: ${TEXT_COLOR};
    }
    .btnEdit{
        width: 100px;
        font-size: 12px;
        padding: 0.5rem;
    }
    .dropdown{
        min-width: 100px;
        .Dropdown-menu{
            min-width: 180px;
            top: none;
            bottom: -220%;
            right: 0;
            border-radius: 5px;
            box-shadow: 0 4px 13px #e9e9f9;
            border: none;
            padding: 0.5rem;
            font-size: 12px;
            text-align: start;
        }
        .Dropdown-control{
            border-radius: 5px;
            border: none;
            font-size: 14px;
            background-color: ${PRIMARY_COLOR};
            color: ${BG_COLOR};
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.7rem;
            cursor: pointer;
        }
    }
    .disabled{
        .Dropdown-control {
            background-color: #878787;
        }
    }
`

export const StyledContainerBodyPost = styled.div`
    width: 100%;
    .input{
        width: 100%;
        padding: 1rem;
        margin: 5px 0;
        border-radius: 10px;
        border: 0;
        outline: none;
        //background-color: ${BG_CONTRAST_COLOR};
        border: 2px solid  ${BG_CONTRAST_COLOR};
    }
    .input-title{
        text-align: start;
        font-size: 14px;
        margin: 5px 0;
        //font-weight: bold;
        color: ${TEXT_COLOR};
    }
    .main-tabs{
        width: 100%;
        background-color: ${BG_CONTRAST_COLOR};
        border-radius: 10px;
        margin: 20px 0;
        padding: 10px;
        padding-top: 5px;
        .menu-tabs{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 0;
            gap: 5px;
            padding: 0;
        }
        .item-tab{
            width: 140px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            padding: 0;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            a{
                color: ${TEXT_COLOR};
            }
        }
        .active{
            background-color:white;
            color: ${PRIMARY_COLOR};
            a{
                color: ${PRIMARY_COLOR};
            }
        }
    }
    .main-post-content{
        width: 100%;
        background-color: ${BG_COLOR};
        padding: 1rem 0.5rem;
        .textArea{
            width: 100%;
            margin: 5px 0;
            resize: none;
            border: none;
            //background-color: ${BG_CONTRAST_COLOR};
            border: 2px solid  ${BG_CONTRAST_COLOR};
            outline: none;
            min-height: 200px;
            padding: 0.5rem;
        }
        .ck-editor__editable{
            min-height: 200px;
        }
    }
`

export const StyledAsideBodyPost = styled.div`
    width: 100%;
    background-color: ${BG_CONTRAST_COLOR};
    height: auto;
    padding: 1rem;
    border-radius: 10px;
    .input-title{
        text-align: start;
        font-size: 14px;
        font-weight: bold;
        color: ${TEXT_COLOR};
    }
    .rselect{
        width: 100%;
        text-align: start;
        border: none;
    }
    .input-select{
        width: 100%;
        .Dropdown-menu{
            border-radius: 5px;
            box-shadow: 0 4px 13px #e9e9f9;
            border: none;
            padding: 0.5rem;
            font-size: 12px;
            margin: 5px 0;
            text-align: start;
        }
        .Dropdown-control{
            border-radius: 5px;
            margin: 5px 0;
            border: none;
            font-size: 14px;
            color: ${TEXT_COLOR};
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.7rem;
            cursor: pointer;
        }
    }
`
