import { useQuery } from "react-query"
import { getSummaryPublisher } from "../services/analitycs.services"
import Skeleton from "react-loading-skeleton"

interface props {
    filters: { type: number, month: number }
}

const BoxesSummary = ({ filters }: props) => {
    const { isLoading, data, isError } = useQuery(["analitycs", "home", "summary", [filters.type, filters.month]], () => getSummaryPublisher(filters), { refetchOnWindowFocus: false, retry: 1 })


    if (isLoading) {
        return (
            <div className="container-boxs-info">
                <Skeleton width="100%" height="120px"/>
                <Skeleton width="100%" height="100%"/>
                <Skeleton width="100%" height="100%"/>
                <Skeleton width="100%" height="100%"/>
                <Skeleton width="100%" height="100%"/>
            </div>
        )
    }

    if (!data || isError) {
        return (
            <p>Error</p>
        )
    }

    return (
        <div className="container-boxs-info">
            <div className="box-info">
                <p className="box-info-title">Visualizaciones de notas/videos</p>
                <p className="box-info-data">{data.Nota + data.Video}</p>
            </div>
            <div className="box-info">
                <p className="box-info-title">Seguidores Nuevos</p>
                <p className="box-info-data">+{data.totalNewFollows}</p>
            </div>
            <div className="box-info">
                <p className="box-info-title">Visualizaciones de perfil</p>
                <p className="box-info-data">{data.totalViewsProfile}</p>
            </div>
            <div className="box-info">
                <p className="box-info-title">Me gusta</p>
                <p className="box-info-data">{data.totalFavs}</p>
            </div>
            <div className="box-info">
                <p className="box-info-title">Comentarios</p>
                <p className="box-info-data">{data.totalComments}</p>
            </div>
        </div>
    )
}

export default BoxesSummary