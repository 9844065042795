import { IFormEditProfile } from "../../../interfaces/profile.interfaces"
import { isURL, validatePhoneNumber } from "../../../utils/Format"

export const ValidateFormProfile = (form: IFormEditProfile) => {
    const errors:{[key: string]: { error: boolean, msg: string }} = {}

    try {
        if(form.name.length < 2 ) errors["name"] = { error: true, msg: "El nombre no es valido" }
        if(form.lastName.length < 2 ) errors["lastName"] = { error: true, msg: "El apellido no es valido" }
        if(form.occupation.length < 2 ) errors["occupation"] = { error: true, msg: "La ocupacion no es valida" }
        if(form.networks.length > 0) {
            form.networks.map( net => {
                if(!isURL(net.url)) {
                    errors["networks"] = { error: true, msg: "Ingresaste una url no valida" }
                    return
                }
                if( !net.label || net.label === "") {
                    errors["networks"] = { error: true, msg: "No seleccionaste una opcion" }
                    return
                }
            } )
        }
        if(new Date(form.birthDate).getFullYear() < 1920 || new Date(form.birthDate).getFullYear() > new Date().getFullYear()) {
            errors["birthDate"] = { error: true, msg: "Ingrese una fecha correcta" }
        }
        if(!form.gender) errors["gender"] = { error: true, msg: "Ingrese una genero correcto" } 
        if(!form.country) errors["country"] = { error: true, msg: "Ingrese una pais correcto" } 
        if(!validatePhoneNumber(form.phone)) errors["phone"] = { error: true, msg: "Ingrese una telefono correcto" } 
        if(!form.biography || form.biography.length < 2 ) errors["biography"] = { error: true, msg: "La biografia no es valida" }
        if(!form.image && !form.fileImage) errors["fileImage"] = { error: true, msg: "Tu Perfil debe tener una foto" }
    } catch (error) {
        errors["other"] = { error: true, msg: "Ocurrio un error al validar" }
    }
    
    return errors
}