import { ApexOptions } from "apexcharts"
import ReactApexChart from "react-apexcharts"
import AnalitycsLoader from "../../../../../components/Loaders/AnalitycsLoader";
import { generateDateList, getAbbreviatedMonths } from "../../../utils/date";
import { PRIMARY_COLOR, PRIMARY_COLOR_2 } from "../../../../../styled-components/variables";
import { useQuery } from "react-query";
import { getFollowersPerPeriod, getViewsOfPosts } from "../services/analitycs.services";

const stateBarChart = {
    series: [{
        name: 'Views of Notes',
        data: [4, 3.1, 4.0, 10.1, 4.0,10.1, 5.9]
    },
    {
        name: 'Views of Videos',
        data: [4, 5.1, 4.0, 2.1, 15.0,10.1, 5.9]
    }],
    options: {
        chart: {
            type: 'bar',
        },
        plotOptions: {
            bar: {
                borderRadius: 5,
                dataLabels: {
                    position: 'top', // top, center, bottom
                }
            },
        },
        dataLabels: {
            enabled: false,
            formatter: function (val: any) {
                return val;
            },
            offsetY: -20,
            style: {
                fontSize: '12px',
                colors: ["#304758"]
            }
        },
        colors: [PRIMARY_COLOR, PRIMARY_COLOR_2],
        xaxis: {
            categories: generateDateList(),
            position: 'bottom',
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            crosshairs: {
                fill: {
                    type: 'gradient',
                    gradient: {
                        colorFrom: '#D8E3F0',
                        colorTo: '#BED1E6',
                        stops: [0, 100],
                        opacityFrom: 0.4,
                        opacityTo: 0.5,
                    }
                }
            },
            labels: {
                show: true // Oculta las etiquetas de los meses
            },
            tooltip: {
                enabled: true,
            }
        },
        grid: {
            show: false,
        },
        yaxis: {
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false,
            },
            labels: {
                show: true,
                formatter: function (val: any) {
                    return val;
                }
            }

        },
    },
}


interface props {
    filters: {type: number, month: number}
}

const ChartBarFollowers = ({ filters }: props) => {
    const { isLoading, data, isError } = useQuery(["analitycs", "home", "followersGrowth", [filters.type, filters.month]], () => getFollowersPerPeriod(filters), { refetchOnWindowFocus: false, retry: 1 })


    if (isLoading) {
        return (
            <div className="center-loading"><AnalitycsLoader/></div>
        )
    }

    if (!data || isError) {
        return (
            <p>Error</p>
        )
    }

    return (
        <ReactApexChart 
            //options={stateBarChart.options as ApexOptions} 
            options={
                {
                    ...stateBarChart.options, 
                    xaxis: {categories: 
                        filters.type === 1 
                        ? generateDateList()
                        : filters.type === 2 
                            ? generateDateList(28) 
                            :  getAbbreviatedMonths()
                    } 
                } as ApexOptions
            }
            height={300} 
            //series={stateBarChart.series} 
            series={[
                {
                    name: "Seguidores",
                    data: data
                }
            ]}
            type="line" 
        />
    )
}

export default ChartBarFollowers