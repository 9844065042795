import Header from "../../components/Header"
import WrapperApp from "../../components/WrapperApp"
import ViewHome from "./view"

const Home = () => {
    return(
        <WrapperApp>
            <div>
                <Header navigation={["Inicio"]}/>
                <ViewHome/>
            </div>
        </WrapperApp>
    )
}

export default Home