import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import Header from "../../../../components/Header"
import WrapperApp from "../../../../components/WrapperApp"
import { getDetailAds } from "../../services/ads.services"
import ViewCreateAds from "./view"
import { AdapRawDetailPost } from "../../adapters/ads.adapter"

const EditAds = () => {
    const query = useParams();
    const {isLoading, data, isError} =  useQuery(["myads",query.id], () => getDetailAds(query.id || ""), {refetchOnWindowFocus: false})


    if(isLoading){
        return(
            <WrapperApp>
                <p>cargando skeleton..</p>
            </WrapperApp>
        )
    }

    if(!data || isError){
        return(
            <WrapperApp>
                <p>Ocurrio un error</p>
            </WrapperApp>
        )
    }

    return(
        <WrapperApp>
            <div>
                <Header navigation={["Anuncios", "Editar"]} />
                <ViewCreateAds initForm={AdapRawDetailPost(data)} edit={true}/>
            </div>
        </WrapperApp>
    )
}

export default EditAds