import styled from "styled-components";

export const StyledProfileBody = styled.div`
    width: 100%;
    margin: 1rem 0;
    padding: 1rem;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
`
