import HandleLink from "../../containers/HandleLink"
import { useLocation } from "react-router-dom";
import { StyledMenu, StyledMenuList } from "./styles"
import ContextAuth from "../../../../contexts/Auth.context";

const Menu = () => {
    const { pathname } = useLocation();
    const { dataUser } = ContextAuth();

    return (
        <StyledMenu>
            <nav>
                <StyledMenuList>
                    <HandleLink className="link" href="/">
                        <li className={`menu-item ${pathname === "/" && "active"}`}>
                            <i className="fa-solid fa-house fa-xl"></i>&nbsp;Inicio
                        </li>
                    </HandleLink>
                    <HandleLink className="link" href="/profile">
                        <li className={`menu-item ${pathname.includes("/profile") && "active"}`}>
                            <i className="fa-solid fa-id-card-clip fa-xl"></i>&nbsp;Perfil
                        </li>
                    </HandleLink>
                    {dataUser.data?.roles?.includes("Publicador") &&
                        (<HandleLink className="link" href="/post/create"><li className={`menu-item ${pathname.includes("/post") && "active"}`}>

                            <i className="fa-solid fa-file-lines fa-xl"></i>&nbsp;Publicar

                        </li></HandleLink>)
                    }
                    {dataUser.data?.roles?.includes("Publicador") &&
                        (<HandleLink className="link" href="/myposts"><li className={`menu-item ${pathname.includes("/myposts") && "active"}`}>
                            <i className="fa-solid fa-folder-open fa-xl"></i>&nbsp;Publicaciones
                        </li></HandleLink>)
                    }
                    {dataUser.data?.roles?.includes("Anunciante") &&
                        <HandleLink className="link" href="/ads"><li className={`menu-item ${pathname.includes("/ads") && "active"}`}>

                            <i className="fa-solid fa-bullhorn fa-xl"></i>&nbsp;Anuncios
                        </li></HandleLink>
                    }
                </StyledMenuList>
            </nav>
        </StyledMenu>
    )
}

export default Menu