import { IPost } from "../../../../../../interfaces/post.interfaces"
import CardCollaborator from "../../../../components/CardCollaborator"
import { StyledAsidePost, StyledBoxAsidePost, StyledListTagsAsidePost, StyledRowAsidePost, StyledSubtitleAsidePost, StyledTagAsidePost } from "./styles"

const AsidePost = ({created_at, updated_at, collaborations, tags, status, credits}:IPost) => {
    return(
        <StyledAsidePost>
            <StyledRowAsidePost>
                <p className="title">Estado </p>
                <p>{status}</p>
            </StyledRowAsidePost>
            <StyledRowAsidePost>
                <p className="title">Creado </p>
                <p>{new Date(created_at).toLocaleDateString("es-ES", { year: 'numeric', month: 'short', day: 'numeric' })}</p>
            </StyledRowAsidePost>
            <StyledRowAsidePost>
                <p className="title">Actualizado </p>
                <p>{new Date(updated_at).toLocaleDateString("es-ES", { year: 'numeric', month: 'short', day: 'numeric' })}</p>
            </StyledRowAsidePost>
            <StyledBoxAsidePost>
                <StyledSubtitleAsidePost>Colaboradores </StyledSubtitleAsidePost>
                { collaborations.length === 0 &&  <p>Sin Colaboradores</p>  }
                {
                    collaborations.map( (item) => <CardCollaborator key={item.user} title={item.user} image={item.image} /> )
                }
            </StyledBoxAsidePost>
            <StyledBoxAsidePost>
                <StyledSubtitleAsidePost>Etiquetas </StyledSubtitleAsidePost>
                <StyledListTagsAsidePost>
                    { tags.length === 0 &&  <p>Sin etiquetas</p>  }
                    {
                        tags.map( (item) => <StyledTagAsidePost key={item.value}>#{item.label}</StyledTagAsidePost> )
                    }
                </StyledListTagsAsidePost>
            </StyledBoxAsidePost>
            <StyledBoxAsidePost>
                <StyledSubtitleAsidePost>Creditos </StyledSubtitleAsidePost>
                <StyledListTagsAsidePost>
                    <p className="title">{credits}</p>
                </StyledListTagsAsidePost>
            </StyledBoxAsidePost>
        </StyledAsidePost>
    )
}

export default AsidePost