import { useState } from "react"
import { AsyncTypeahead, Token } from "react-bootstrap-typeahead";
import { Collaboration } from "../../../../interfaces/post.interfaces";
import { getPublishers } from '../../services/post.services';
import CardCollaborator from "../CardCollaborator";
import { StyledInputSearchCola } from "./styles";

interface props {
    action: (data: Collaboration[] ) => void
    defaultValues: Collaboration[]
}

const SearchCollaborators = ({action, defaultValues}:props) => {
    const [options, setOptions] = useState<Collaboration[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleSearch = (query: string) => {
        setIsLoading(true)
        getPublishers(query)
            .then((data) => {
                setOptions(data)   
            })
            .catch((error) => console.error(error))
            .finally( () => setIsLoading(false) )
    }

    return (
        <StyledInputSearchCola>
            <AsyncTypeahead
                filterBy={["user"]}
                className="typehead"
                id="async-example"
                isLoading={isLoading}
                labelKey="user"
                minLength={3}
                emptyLabel="Sin resultados"
                multiple={true}
                onChange={(selected) => {
                    //console.log((selected as Collaboration[]).map( (itm) => itm.user ))
                    action(selected as Collaboration[])
                }} 
                defaultSelected={defaultValues}
                onSearch={handleSearch}
                options={options}
                placeholder="Buscar"
                renderToken={ (option, props) => (
                    <Token option={option} disabled={props.disabled} onRemove={props.onRemove}>
                        <CardCollaborator title={(option as Collaboration).user} image={(option as Collaboration).image} />
                    </Token>
                ) }
                renderMenuItemChildren={(option: any) => (
                    <CardCollaborator title={option.user} image={option.image} />
                )}
            />
        </StyledInputSearchCola>
    )
}

export default SearchCollaborators