import Skeleton from "react-loading-skeleton"
import { StyleBodyPost, StyleDescriptionBodyPost, StyledSubHeaderBodyPost, StyleHeaderBodyPost, StyleSubtitleBodyPost, StyleTitleBodyPost } from "./styles"

const SkeletonBodyPost = () => {
    return(
        <StyleBodyPost>
            <StyleHeaderBodyPost>
                <div style={{ margin: "5px 0" }}><Skeleton width="100px" height="20px" /></div>
                <div style={{ margin: "5px 0" }}><Skeleton width="100px" height="20px" /></div>
            </StyleHeaderBodyPost>
            <StyleTitleBodyPost>
                <h1 style={{ width: "100%", margin: "10px 0" }}><Skeleton width="100%" height="30px"/></h1>
            </StyleTitleBodyPost>
            <p style={{ width: "100%", margin: "5px 0", marginTop: "0px", textAlign: "start" }}>
                <Skeleton width="80%" height="20px"/>
            </p>
            <Skeleton width="100%" height="400px"/>

            <StyledSubHeaderBodyPost>
                <p><Skeleton width={80} height={20} /></p>
                <p>&nbsp;•&nbsp;<Skeleton width={80} height={20}/></p>
                <p>&nbsp;•&nbsp;<Skeleton width={80} height={20}/></p>
            </StyledSubHeaderBodyPost>
            <StyleDescriptionBodyPost>
                <p style={{margin: "5px 0"}}><Skeleton width="100%" height={20}/></p>
                <p style={{margin: "5px 0"}}><Skeleton width="100%" height={20}/></p>
                <p style={{margin: "5px 0"}}><Skeleton width="100%" height={20}/></p>
                <p style={{margin: "5px 0"}}><Skeleton width="100%" height={20}/></p>
                <p style={{margin: "5px 0"}}><Skeleton width="100%" height={20}/></p>
                <p style={{margin: "5px 0"}}><Skeleton width="100%" height={20}/></p>
            </StyleDescriptionBodyPost>
        </StyleBodyPost>
    )
}

export default SkeletonBodyPost