export function getSearchParams(url: string): Record<string, string> {
    const searchParams: Record<string, string> = {};
    const queryStartIndex = url.indexOf('?');
  
    if (queryStartIndex !== -1) {
      const queryStr = url.substring(queryStartIndex + 1);
      const queryArr = queryStr.split('&');
  
      queryArr.forEach((pair) => {
        const [key, value] = pair.split('=');
        searchParams[key] = value;
      });
    }
  
    return searchParams;
  }