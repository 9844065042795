import Header from "../../../../components/Header"
import WrapperApp from "../../../../components/WrapperApp"
import ViewCreateAds from "./view"

const CreateAds = () => {
    return(
        <WrapperApp>
            <div>
                <Header navigation={["Anuncios", "Crear"]} />
                <ViewCreateAds/>
            </div>
        </WrapperApp>
    )
}

export default CreateAds