import axios from "axios";
import { URL } from "../../../../../config";
import { AdaptEPPost } from "../../../adapters/post.adapter";
import { Collaboration, IPost } from "../../../interfaces/post.interfaces";
import { getCookie } from "../../../utils/Http";
import { AdaptEPgetPublishers } from "../adapters/post.adapter";
import { IGetPublisher, OptionSelect } from "../interfaces/post.interfaces";

const header = {
  headers: {
    Authorization: `Bearer ${getCookie()}`,
    'Content-Type': 'application/json'
  }
}

export const getTags = async (input ?: string): Promise<OptionSelect[]> => {
  const resp = await axios.post(`${URL}api/publisher/search/tags`,{tag_name: input, size: 6}, header)
  if (resp.status === 200) return resp.data.data
  return []
}

export const createTag = async (name : string): Promise<string | null> => {
  const resp = await axios.post(`${URL}api/publisher/create/tags`, {name} , header)
  if (resp.status === 200) return resp.data.data
  return null
}

export const getCategorys = async (): Promise<OptionSelect[]> => {
  const resp = await axios.get(`${URL}api/publisher/categories`, header)
  if (resp.status === 200) return resp.data.data
  return []
}

export const getPostBySlug = async (slug: string): Promise<IPost | null> => {
  const id = slug.substring(slug.lastIndexOf("-") + 1);
  const resp = await axios.get(`${URL}api/publisher/posts/${id}`, header)
  if (resp.status === 200) return AdaptEPPost(resp.data.data)
  return null
}

export const getPostForEdit = async (id: string): Promise<IPost | null> => {
  const resp = await axios.get(`${URL}api/publisher/posts/${id}/edit`, {
    headers: {
      Authorization: `Bearer ${getCookie()}`,
      'Content-Type': 'multipart/form-data'
    }
  })
  if (resp.status === 200) return AdaptEPPost(resp.data.data)
  return null
}

export const createPost = async (type: string, formdata: FormData) => {
  const resp = await axios.post(`${URL}api/publisher/${type}`, formdata, {
    headers: {
      Authorization: `Bearer ${getCookie()}`,
      'Content-Type': 'multipart/form-data'
    }
  })
  if (resp.status === 201) return resp.data.data
  return null
}

export const updatePost = async (id: string, formdata: FormData) => {
  const resp = await axios.post(`${URL}api/publisher/posts/${id}`, formdata, {
    headers: {
      Authorization: `Bearer ${getCookie()}`,
      'Content-Type': 'multipart/form-data'
    }
  })
  if (resp.status === 200) return resp.data.data
  return null
}

export const deletePost = async (id: string) => {
  const resp = await axios.get(`${URL}api/publisher/post/delete/${id}`, header)
  if (resp.status === 200) return resp.data.data
  return new Error("No se puedo eliminar")
}


export const getPublishers = async (search: string): Promise<Collaboration[]> => {
  const resp = await axios.get(`${URL}api/publisher/collaborations?search=${search}`, header)
  let cleanData = []
  if (Array.isArray(resp.data.data)) {
    cleanData = resp.data.data.map((val: IGetPublisher) => AdaptEPgetPublishers(val))
  }
  if (resp.status === 200) return cleanData
  return []
}