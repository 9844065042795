import Avatar from "react-avatar"
import HandleLink from "../../../../containers/HandleLink";
import { IProfile } from "../../../../interfaces/profile.interfaces";
import { FormatNumber } from "../../../../utils/Format";
import { getIconOfName } from "../../adapters/profile.adapters";
import useUIRouter from "../../contexts/UIRouter.context";
import { StyledBodyHeaderProfile, StyledBtnEditProfile, StyledHeaderProfile, StyledTabsHeaderProfile } from "./styles"
import { ImageAdapter } from "../../../../adapters/image.adapter";

const HeaderProfile = (data: IProfile) => {
    const {
        fullName, image, occupation, totalPosts, totalFollowers, avgRating,
        networks
    } = data
    const { viewActive, handleRoute } = useUIRouter();

    return (
        <StyledHeaderProfile>
            <StyledBodyHeaderProfile>
                <div className="box-picture">
                    <Avatar src={ImageAdapter(image)} name={fullName} size="128" round={true} />
                    <div className="box-picture-text">
                        <p className="box-picture-title"> {fullName} </p>
                        <p className="box-picture-subtitle"> {occupation} </p>
                    </div>
                </div>
                <div className="box-rankings">
                    <div className="box-ranking">
                        <span className="box-ranking-title">{FormatNumber(totalPosts)}</span>
                        <span className="box-ranking-subtitle">Publicaciones</span>
                    </div>
                    <div className="box-ranking">
                        <span className="box-ranking-title">{FormatNumber(totalFollowers)}</span>
                        <span className="box-ranking-subtitle">Seguidores</span>
                    </div>
                    <div className="box-ranking">
                        <span className="box-ranking-title">{FormatNumber(avgRating)}</span>
                        <span className="box-ranking-subtitle">Valoraciones</span>
                    </div>
                </div>
                <div className="box-socials">
                    {
                        networks.map( (item) => 
                        <a href={item.url} target="_blank">
                            <p className="box-socials-text">
                                <i className={getIconOfName(item.label)}></i>&nbsp;{item.value}
                            </p> 
                        </a>
                        )
                    }
                </div>
            </StyledBodyHeaderProfile>
            <StyledTabsHeaderProfile>
                <li onClick={() => handleRoute(0)} className={`box-tab ${viewActive === 0 && "active"}`}>
                    <i className="fa-solid fa-book"></i>&nbsp;Biografia
                </li>
                <li onClick={() => handleRoute(1)} className={`box-tab ${viewActive === 1 && "active"}`}>
                    <i className="fa-solid fa-shield-halved"></i>&nbsp;Cuenta
                </li>
                <li onClick={() => handleRoute(2)} className={`box-tab ${viewActive === 2 && "active"}`}>
                    <i className="fa-solid fa-lock"></i>&nbsp;Cambiar Contraseña
                </li>
                <StyledBtnEditProfile>
                    <HandleLink href="/profile/edit">
                        <><i className="fa-solid fa-user-pen"></i>&nbsp;Editar</>
                    </HandleLink>
                </StyledBtnEditProfile>
            </StyledTabsHeaderProfile>
        </StyledHeaderProfile>
    )
}

export default HeaderProfile