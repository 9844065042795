import styled from "styled-components";
import { BG_COLOR, SECONDARY_COLOR, PRIMARY_COLOR, TEXT_COLOR, BG_CONTRAST_COLOR, PRIMARY_COLOR_2, PRIMARY_COLOR_2_HOVER } from "../../../../../../styled-components/variables";

export const StyledCreateAds = styled.div`
    width: 100%;

`

export const StyledCreateAdsHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .itemNext{
        color: ${SECONDARY_COLOR};
        &.active{
            color: ${PRIMARY_COLOR};
        }
    }
    .itemTab{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 5px;
        color: ${BG_COLOR};
        background-color: ${SECONDARY_COLOR};
        &.active{
            background-color: ${PRIMARY_COLOR};
        }
    }
`

export const StyledSectionConfFinal = styled.div`
    width: 100%;
    .subtitle-form{
        margin: 5px 0;
        text-align: start;
        font-size: 14px;
        margin: 5px 0;
        //font-weight: bold;
        color: ${TEXT_COLOR};
    }
    .footer-form{
        width: 100%;
        margin: 1rem 0;
        text-align: end;
    }
    .menu-loader{
        width: 100%;
        min-height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .button-prev{
        width: 140px;
        margin-right: 10px;
        font-size: 14px;
        background-color: transparent;
        color: ${PRIMARY_COLOR};
        border: 1px solid ${PRIMARY_COLOR};
    }
    .button{
        width: 140px;
        font-size: 14px;
    }
`

export const StyledSectionConf = styled.div`
    display: grid;
    grid-template-columns: 30% 70%;
    gap: 1rem;
    .column-rangeHours{
        display: grid;
        grid-template-columns: 1fr 30px 1fr;
        gap: 5px;
    }
    .columns-2{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 5px;
    }
    .footer-form{
        width: 100%;
        margin: 1rem 0;
        text-align: end;
    }
    .img-preview{
        object-fit: contain;
    }
    .button-prev{
        width: 140px;
        margin-right: 10px;
        font-size: 14px;
        background-color: transparent;
        color: ${PRIMARY_COLOR};
        border: 1px solid ${PRIMARY_COLOR};
    }
    .button{
        width: 140px;
        font-size: 14px;
    }
    .subtitle-form{
        margin: 5px 0;
        padding-top: 1rem;
        text-align: start;
        font-size: 14px;
        margin: 5px 0;
        //font-weight: bold;
        color: ${TEXT_COLOR};
    }
    .select-advertisingSpaces{
        width: 100%;
        text-align: end;
    }
    .listItems-advertisingSpaces{
        width: 100%;
        padding: 0.5rem 0;
        display: flex;
        justify-content: center; 
        align-items: flex-start;
        flex-direction: column;
        gap: 5px;
    }
    .btn-advertisingSpaces{
        background-color: transparent;
        color: ${PRIMARY_COLOR};
        border: none;
        outline: none;
        cursor: pointer;
    }
    .select-form{
        width: 100%;
        .Dropdown-menu{
            min-width: 180px;
            height: 100px;
            top: none;
            bottom: -260%;
            right: 0;
            border-radius: 5px;
            box-shadow: 0 4px 13px #e9e9f9;
            border: none;
            padding: 0.5rem;
            font-size: 12px;
            text-align: start;
        }
        .Dropdown-control{
            border-radius: 5px;
            border: none;
            font-size: 16px;
            background-color: ${BG_CONTRAST_COLOR};
            color: ${TEXT_COLOR};
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 10px 0;
            padding: 1rem;
            cursor: pointer;
        }
    }
    .input{
        width: 100%;
        padding: 1rem;
        margin: 5px 0;
        border-radius: 10px;
        border: 0;
        outline: none;
        font-size: 100%;
        //background-color: ${BG_CONTRAST_COLOR};
        border: 2px solid  ${BG_CONTRAST_COLOR};
    }
    .input-form{
        width: 100%;
        padding: 1rem;
        margin: 5px 0;
        border-radius: 5px;
        border: 0;
        outline: none;
        //background-color: ${BG_CONTRAST_COLOR};
        border: 2px solid  ${BG_CONTRAST_COLOR};
        &.error{
            border: 2px solid  firebrick;
            color: firebrick;
        }
    }
    .content-preview{
        border-left: 1px solid ${BG_CONTRAST_COLOR};
        padding: 0.5rem;
    }
    .header-opt-filter{
        width: 100%;
        padding: 5px;
        margin: 0.1rem 0;
        display: flex;
        justify-content: space-between;
        border-bottom: 2PX solid ${BG_CONTRAST_COLOR};
    }
`

export const StyledPreviewAds = styled.div`
    width: 100%;
    .header-previewAds{
        width: 100%;
        padding: 1rem 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .previews-previewAds, .btns-previewAds{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
    }
    .btn-previewAds{
        background-color: transparent;
        border: none;
        cursor: pointer;
        margin: 0 0.5rem;
        outline: none;
        transition: scale .3s;
        :hover{
            scale: 1.01;
        }
        &.active{
            color:  ${PRIMARY_COLOR};
        }
    }
    .container-preview{
        width: 100%;
    }
    .containerPreview{
        width: 100%;
        position: relative;
        img{
            width: 100%;
            object-fit: cover;
        }
    }
    .link{
        text-decoration: none;
        color: ${TEXT_COLOR};
    }
    .box-preview{
        width: 100%;
        background-color: ${BG_CONTRAST_COLOR};
        padding: 0.5rem;
        border-radius: 5px;
        position: relative;
    }
    .preview-970x90{
        width: 100%;
        object-fit: contain;
    }
    .preview-image{
        width: 100%;
    }
    .preview-data{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 5px;
    }
    .preview-text{
        font-size: 12px;
    }
`

export const StyledModalLocationAds = styled.div`
    background-color: ${BG_COLOR};
    width: 100%;
    height: 70%;
    max-width: 620px;
    padding: 1rem;
    margin: 5px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .modal-header{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .list-ads{
        width: 100%;
        height: 100%;
        min-height: 100px;
        max-height: 600px;
        overflow-y: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 0.5rem;
    }
    .modal-footer{
        margin-top: 1rem;
    }
`

export const StyledCardLocation = styled.div`
    width: 100%;
    max-width: 260px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    transition: scale .3s;
    position: relative;
    :hover{
        scale: 1.02;
    }
    .checked{
        width: 100%;
        text-align: end;
        position: absolute;
        top: 0;
        right: 0;
        margin: 5px;
        input{
            border-radius: 50%;
        }
    }
    .picture{
        width: 100%;
        height: 100px;
        background-color: #ccc;
    }
    .title{
        width: 100%;
        font-size: 12px;
        text-align: center;
    }
`