import styled from "styled-components"
import LogoPz from "../../../assets/imgs/logo-pozicion.png"

interface props {
    style?: string
    width : number 
    height : number 
}

const StyledLogo = styled.img`
    transition: scale .2s;
    :hover{
        scale: 1.03;
    }
`

const LogoPozicion = ({style, width, height}:props) => {
    return(
        <StyledLogo src={LogoPz} alt="pozicion" className={style} width={width} height={height} />
    )
}

export default LogoPozicion