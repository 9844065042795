import styled from "styled-components";
import { TEXT_COLOR } from "../../../../styled-components/variables";

export const StyledTextArea = styled.div`
    text-align: start;
    font-size: 14px;
    &:empty::before {
        position: absolute;
        content: "Escribe aqui";
        color: ${TEXT_COLOR};
    }
`