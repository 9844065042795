import { ApexOptions } from "apexcharts"
import ReactApexChart from "react-apexcharts"
import { useQuery } from "react-query";
import { getDataClicks, getDataViews } from "../services/analitycs.services";
import { PRIMARY_FONT } from "../../../../../../../styled-components/variables";
import AnalitycsLoader from "../../../../../../../components/Loaders/AnalitycsLoader";
import { generateDateList, getAbbreviatedMonths } from "../../../../../utils/date";

const state = {
    series: [
        {
            name: "Views",
            data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
        },
        {
            name: "Clicks",
            data: [20, 35, 45, 58, 64, 78, 82, 97, 110]
        }
    ],
    options: {
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },
        title: {
            text: 'Visualizaciones y clicks del anuncio',
            align: 'left',
            style: {
                fontFamily: PRIMARY_FONT // Cambia la fuente de letra según tus preferencias
              }
        },
        grid: {
            show: false,
        },
        xaxis: {
            categories: generateDateList(),
        }
    }
};

interface props {
    idAds: string
    filters: {type: number, month: number}
}

const LineChart = ({ idAds,filters }: props) => {
    const dataClicks = useQuery(["analitycs", "line", idAds, [filters.type, filters.month]], () => getDataClicks(idAds,filters), { refetchOnWindowFocus: false })
    const dataViews = useQuery(["analitycs", "line-2", idAds,  [filters.type, filters.month]], () => getDataViews(idAds,filters), { refetchOnWindowFocus: false })


    if (dataClicks.isLoading || dataViews.isLoading) {
        return (
            <div className="center-loading"><AnalitycsLoader/></div>
        )
    }

    if ((!dataViews.data || dataViews.isError) || (!dataClicks.data || dataClicks.isError)) {
        return (
            <p>Error</p>
        )
    }

    return (
        <ReactApexChart 
        options={
            {
                ...state.options, 
                xaxis: {categories: 
                    filters.type === 1 
                    ? generateDateList()
                    : filters.type === 2 
                        ? generateDateList(28) 
                        :  getAbbreviatedMonths()
                } 
            } as ApexOptions
        } 
        series={[
            {
                name: "Views",
                data: dataViews.data
            },
            {
                name: "Clicks",
                data: dataClicks.data
            }
        ]} type="line" height={350} />
    )
}

export default LineChart