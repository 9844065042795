import { URL, URL_STORAGE } from "../../../config/index";
import { IEPpost, IPost } from "../interfaces/post.interfaces";


export const AdaptEPPost = (apiPost: IEPpost): IPost => {
    const data = {
        id: apiPost.id,
        UrlPost: `/myposts/${apiPost.slug}`,
        UrlImage: apiPost.image ? URL_STORAGE + apiPost.image : `${URL}img/default.png`,
        datePost: apiPost.updated_at ? apiPost.updated_at :apiPost.created_at,
        score: apiPost.ratings.length,
        title: apiPost.title,
        categorys: apiPost.categories,
        totalComments: apiPost.comments.length,
        subtitle: apiPost.subtitle,
        slug:    apiPost.slug,
        video:   apiPost.video,
        extract: apiPost.extract,
        body:       apiPost.body,
        user:    apiPost.user,
        created_at:     apiPost.created_at,
        updated_at:     apiPost.updated_at,
        tags:           apiPost.tags,
        collaborations: apiPost.collaborators,
        credits:       apiPost.credits,
        status: apiPost.status,
        //statusPost: apiPost.type.toLowerCase(),
        type:           apiPost.type,
        privacy: apiPost.privacy,
    }
    return data;
}