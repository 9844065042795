import { StyledCardStatus } from "./styles"

interface props {
    statusPost: string
    classname?: string
}


const IconStatusPost = ({ statusPost, classname }: props) => {
    if (statusPost.toLowerCase() === "público") {
        return (
            <StyledCardStatus className={`public ${classname}`}>
                <i className="fa-solid fa-globe"></i>
                &nbsp;
                Público
            </StyledCardStatus>
        )
    }
    return (

        <StyledCardStatus className={classname}>
            <i className="fa-solid fa-lock"></i>
            &nbsp;
            Privado
        </StyledCardStatus>

    )
}

export default IconStatusPost