import styled from "styled-components";
import { PRIMARY_COLOR, SECONDARY_COLOR, SECONDARY_FONT, TEXT_COLOR } from "../../../../styled-components/variables";

export const StyledHeader = styled.div`
    width: 100%;
    padding: 0.8rem 0;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 1rem;
    font-family: ${SECONDARY_FONT};
    font-weight: bold;
    font-size: 12px;
    color: ${TEXT_COLOR};
    text-transform: uppercase;
    //border-bottom: 1px solid ${SECONDARY_COLOR};
    .active{
        color: ${PRIMARY_COLOR};
    }
`