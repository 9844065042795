import { useQuery } from "react-query";
import BoxPreviewImg from "../../../../../components/BoxPreviewImg";
import { IAdsFormImages, IAdsLocation } from "../../../interfaces/ads.interfaces";
import { getAdsLocations } from "../../../services/ads.services";
import BoxUploadImg from "../../../../../components/BoxUploadImg";

interface props {
    locations: IAdsLocation[]
    action: (value: IAdsFormImages[]) => void
    initValue: IAdsFormImages[]
}

function getUniqueMeasures(arr: IAdsLocation[]): { id: number, measure: string }[] {
    const uniqueMeasures = arr.reduce((acc, curr) => {
        const measure = curr.measure.measure;
        const id = curr.measure.id;
        const exists = acc.find(item => item.measure === measure);
        if (!exists) {
            acc.push({ id, measure });
        }
        return acc;
    }, [] as { id: number, measure: string }[]);
    return uniqueMeasures;
}

const SelectImages = ({ locations, initValue, action }: props) => {
    const { isLoading, data, isError } = useQuery("ads-locations", getAdsLocations, { refetchOnWindowFocus: false })
    const measures = getUniqueMeasures(locations)
    const listImages = (initValue ? initValue : []);

    const handleListImages = (id: number, file: string, url: string) => {
        const index = listImages.findIndex(item => item.idFormat === id)
        if(index <= -1){
            const tempList = [...listImages]
            tempList.push({idFormat: id, url: url, file})
            action(tempList)
            return
        }
        const tempList = [...listImages]
        tempList[index].file = file
        tempList[index].url = url
        tempList[index].idFormat = id
        action(tempList)
    }

    if(isLoading){
        return(<></>)
    }

    if(!data || isError){
        return(<></>)
    }

    if (measures.length === 0) {
        return (
            <div>
                {
                    getUniqueMeasures(data).map((measure) => {
                        return (
                            <BoxUploadImg
                                key={measure.id}
                                initPlaceholder={`Imagen sugerida ${measure.measure}`}
                                endPlaceHolder ={`Cambiar Imagen ${measure.measure}`}
                                classname="boxImgPrev"
                                action={(file, url) => handleListImages(measure.id, file, url)}
                                urlFileDefault={listImages.find(item => item.idFormat === measure.id)?.url}
                                name={`fileImage ${measure.measure}`}
                            />
                        )
                    })
                }
            </div>
        )
    }

    return (
        <div>
            {
                measures.map((measure) => {
                    return (
                        <BoxUploadImg
                            key={measure.id}
                            initPlaceholder={`Imagen sugerida ${measure.measure}`}
                            endPlaceHolder ={`Cambiar Imagen ${measure.measure}`}
                            classname="boxImgPrev"
                            action={(file, url) => handleListImages(measure.id, file, url)}
                            urlFileDefault={listImages.find(item => item.idFormat === measure.id)?.url}
                            name={`fileImage ${measure.measure}`}
                        />
                    )
                })
            }
        </div>
    )
}

export default SelectImages