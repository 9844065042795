import { useState } from "react";
import Avatar from "react-avatar";
import { IComment } from "../../interfaces/comments.interfaces";
import { StyledComment } from "../../styles";
import Replys from "../Replys";
import ContainerReplys from "../Replys/container";

const Comment = ({ date, author, urlPhoto, comment, totalReplies,idComment }: IComment) => {
    const [openBoxReply, setOpenBoxReply] = useState(false);
    const [openReplys, setOpenReplys] = useState(false);

    return (
        <>
            <StyledComment>
                <div className="container">
                    <Avatar 
                        size="40" className="img" 
                        textSizeRatio={3} maxInitials={2} 
                        src={urlPhoto || ""} title={author} 
                        name={author} 
                    />
                </div>
                <div className="container container-body">
                    <p className="title"> {author} </p>
                    <span className="time"> {date} </span>
                    <p className="description">
                        {comment}
                    </p>
                    <div className="footer">
                        <p className="reply" onClick={() => setOpenBoxReply(!openBoxReply)} >Responder</p>
                        {totalReplies ? <p onClick={() => setOpenReplys(!openReplys)} className="reply">Ver {totalReplies} respuestas </p> : ""}
                    </div>
                </div>
            </StyledComment>
            <StyledComment className="container-reply">
                {openBoxReply && <ContainerReplys id={idComment.toString()} closeReply={() => setOpenBoxReply(false)} />}
                {openReplys && <Replys idComment={idComment.toString()}/> }
            </StyledComment>
        </>
    )
}

export default Comment