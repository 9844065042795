import Dropdown from 'react-dropdown';
import Header from "../../../../components/Header"
import 'react-dropdown/style.css';
import { StyledAsideBodyPost, StyledBodyCreatePost, StyledContainerBodyPost, StyledCreatePost, StyledHeaderCreatePost } from './styles';
import { useState } from 'react';
import AdvanceTrix from '../../../../components/Trix/AdvanceTrix';
import TextArea from '../../../../components/TextArea';
import BoxPreviewImg from '../../../../components/BoxPreviewImg';
import SearchCollaborators from '../../components/SearchCollaborators';
import Rselect from 'react-select'
import AsyncCreatableSelect from 'react-select/async-creatable';
import { createPost, createTag, getCategorys, getTags, updatePost } from "../../services/post.services"
import ContainerGetData from '../../../../containers/GetData.container';
import { StyledContainerPost } from '../../styles';
import { IFormPost, IPost } from '../../../../interfaces/post.interfaces';
import { ValidateForm } from '../../utils/validateForm.util';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import PrimaryLoader from '../../../../../../components/Loaders/PrimaryLoader';

const options = [
    {
        value: '1',
        label: <div>
            <i className="fa-solid fa-floppy-disk"></i>
            &nbsp;Borrador&nbsp;
        </div>
    },
    {
        value: '2',
        label: <div>
            <i className="fa-solid fa-file-lines"></i>
            &nbsp;Nueva Publicacion&nbsp;
        </div>
    },
];

const optionsEstado = [
    {
        value: '1',
        label: <div>
            <i className="fa-solid fa-lock"></i>
            &nbsp;Privado&nbsp;
        </div>
    },
    {
        value: '2',
        label: <div>
            <i className="fa-solid fa-globe"></i>
            &nbsp;Publico&nbsp;
        </div>
    },
];

interface props {
    initData: IPost | null
}

const SectionCreatePost = ({ initData }: props) => {
    const query = useParams();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<{ [key: string]: { error: boolean, msg: string } }>({});

    const [form, setForm] = useState<IFormPost>({
        title: initData?.title ? initData.title : "",
        subtitle: initData?.subtitle ? initData.subtitle : "",
        typePost: initData ? (initData.video ? 2 : 1) : 2,
        linkVideo: initData?.video ? initData.video : "",
        body: (initData && !initData.video) ? initData.body : "",
        bodyvideo: (initData && initData.video) ? initData.body : "",
        extract: initData?.extract ? initData.extract : "",
        credits: initData?.credits ? initData.credits : "",
        image: initData?.UrlImage ? initData.UrlImage : "",
        fileImage: null,
        status: initData ? (initData.privacy === "Público" ? "2" : "1") : "",
        categories: initData?.categorys,
        tags: initData?.tags,
        collaborations: initData?.collaborations ? initData.collaborations : []
    });

    const handleForm = (key: string, value: any) => {
        setForm({ ...form, [key]: value })
    }

    const createFormData = async (mode: string) => {
        const dataErrors = ValidateForm(form, mode)
        if (Object.keys(dataErrors).length !== 0) {
            setErrors(dataErrors)
            setLoading(false)
            return
        }
        //create tags
        const filterValues = (form.tags || []).map(async (x: any) => {
            if (x.__isNew__) {
                const newTag = await createTag(x.value);
                if (newTag) {
                    return {
                        value: newTag,
                        label: x.label
                    }
                }
            }
            return {
                value: x.value,
                label: x.label
            }
        })
        const tagsFormated = await Promise.all(filterValues)

        //send form
        const formData = new FormData();
        formData.append("title", form.title);
        formData.append("subtitle", form.subtitle);
        formData.append("extract", form.extract);
        formData.append("credits", form.credits);
        formData.append("body", form.typePost === 2 ? form.bodyvideo : form.body);
        form.typePost === 2 && formData.append("video", form.linkVideo);
        form.fileImage && formData.append("image", form.fileImage);
        formData.append("status", mode); // borrador o publicado
        formData.append("type", form.typePost.toString()); // nota video
        formData.append("privacy", form.status); // public private
        formData.append("categories", JSON.stringify(form.categories));
        formData.append("tags", JSON.stringify(tagsFormated));
        formData.append("collaborators", JSON.stringify(form.collaborations.map((it) => it.id.toString())));
        return formData
    }

    const submit = async (mode: string) => {
        setLoading(true)
        const formData = await createFormData(mode)
        if (!formData) return
        await createPost(form.typePost === 2 ? "videos" : "notes", formData)
            .then((data) => {
                navigate(`/myposts/${data.slug}`)
            })
            .finally(() => setLoading(false))
    }

    const submitEdit = async (mode: string) => {
        setLoading(true)
        const formData = await createFormData(mode)
        if (!formData) return
        if (!query.id) return
        await updatePost(query.id, formData)
            .then((data) => {
                console.log(data)
                queryClient.refetchQueries(query.id)
                navigate(`/myposts/${data.slug}`)
            })
            .finally(() => setLoading(false))
    }


    return (
        <StyledCreatePost>
            <Header navigation={["Publicacion", initData ? "Editar" : "Crear"]} />
            <StyledBodyCreatePost>
                <StyledHeaderCreatePost>
                    <h2 className='title'>{initData ? "EDITAR PUBLICACION" : "CREAR PUBLICACION"}</h2>
                    {loading
                        ? <div><PrimaryLoader />Editando</div>
                        : <Dropdown
                            arrowClosed={<i className="fa-solid fa-angle-down"></i>}
                            arrowOpen={<i className="fa-solid fa-angle-up"></i>}
                            className={`dropdown ${loading && "disabled"} `} options={options}
                            onChange={initData ? (data) => submitEdit(data.value) : (data) => submit(data.value)}
                            value={initData?.status ? (initData?.status === "Publicado" ? "2" : "1") : ""}
                            placeholder="Crear"
                        />

                    }
                </StyledHeaderCreatePost>
                <StyledContainerPost>
                    <StyledContainerBodyPost>
                        <p className='input-title'>Titulo</p>
                        <input
                            onChange={(e) => handleForm("title", e.currentTarget.value)}
                            className='input' value={form.title}
                            type="text" placeholder="Titulo"
                        />
                        {errors.title && <p className='text-error'> {errors.title.msg} </p>}
                        <p className='input-title'>Subtitulo</p>
                        <input
                            onChange={(e) => handleForm("subtitle", e.currentTarget.value)}
                            className='input' value={form.subtitle}
                            type="text" placeholder="Subtitulo"
                        />
                        {errors.subtitle && <p className='text-error'> {errors.subtitle.msg} </p>}
                        <div className="main-tabs">
                            <menu className="menu-tabs">
                                <div onClick={() => handleForm("typePost", 2)} className={`item-tab ${form.typePost === 2 ? 'active' : ''}`}>
                                    <i className="fa-solid fa-video"></i>
                                    &nbsp;
                                    Video
                                </div>
                                <div onClick={() => handleForm("typePost", 1)} className={`item-tab ${form.typePost === 1 ? 'active' : ''}`}>
                                    <i className="fa-solid fa-file-lines"></i>
                                    &nbsp;
                                    Nota
                                </div>
                            </menu>
                            {
                                form.typePost === 2 &&
                                <div className="main-post-content">
                                    <input
                                        onChange={(e) => handleForm("linkVideo", e.currentTarget.value)}
                                        value={form.linkVideo}
                                        className='input' type="text"
                                        placeholder="Link del video de youtube"
                                    />
                                    {errors.linkVideo && <p className='text-error'> {errors.linkVideo.msg} </p>}
                                    {
                                        form.linkVideo.split("?v=").length > 1
                                            ? <iframe
                                                width="100%" height="300"
                                                src={`https://www.youtube.com/embed/${form.linkVideo.split("?v=")[1]}`}
                                                title={form.title}
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                            />
                                            : <div></div>
                                    }
                                    <textarea
                                        onChange={(e) => handleForm("bodyvideo", e.currentTarget.value)}
                                        className='textArea' value={form.bodyvideo} placeholder='Descripcion'
                                    />
                                    {errors.bodyvideo && <p className='text-error'> {errors.bodyvideo.msg} </p>}
                                </div>
                            }
                            {
                                form.typePost === 1 &&
                                <div className="main-post-content">
                                    <div className="form-primary-section">
                                        <AdvanceTrix
                                            defaultValue={form.body}
                                            onChange={(data: string) => handleForm("body", data)}
                                        />
                                        {errors.body && <p className='text-error'> {errors.body.msg} </p>}
                                    </div>
                                </div>
                            }
                        </div>
                        <div>
                            <p className='input-title'>Extracto</p>
                            <TextArea
                                classname='input' placeholder="Extracto"
                                value={form.extract} handleChange={(data) => handleForm("extract", data)}
                            />
                            {errors.extract && <p className='text-error'> {errors.extract.msg} </p>}
                        </div>
                        <div>
                            <p className='input-title'>Creditos</p>
                            <TextArea
                                classname='input' placeholder="Creditos"
                                value={form.credits} handleChange={(data) => handleForm("credits", data)}
                            />
                        </div>
                    </StyledContainerBodyPost>
                    <StyledAsideBodyPost>
                        <BoxPreviewImg
                            action={(data) => handleForm("fileImage", data)}
                            fileDefault={form.image} name="portada"
                        />
                        {errors.fileImage && <p className='text-error'> {errors.fileImage.msg} </p>}
                        <Dropdown
                            arrowClosed={<i className="fa-solid fa-angle-down"></i>}
                            arrowOpen={<i className="fa-solid fa-angle-up"></i>}
                            className='input-select' options={optionsEstado}
                            onChange={(data) => handleForm("status", data.value)}
                            value={form.status} placeholder="Estado"
                        />
                        {errors.status && <p className='text-error'> {errors.status.msg} </p>}
                        <div>
                            <p className='input-title'>Colaboradores</p>
                            <SearchCollaborators
                                defaultValues={form.collaborations}
                                action={(data) => handleForm("collaborations", data)}
                            />
                        </div>
                        <div>
                            <p className='input-title'>Categorias</p>
                            <ContainerGetData
                                service={getCategorys}
                                name="categories"
                            >
                                {
                                    ({ data, isError }) => {
                                        return (
                                            <Rselect
                                                placeholder="Buscar"
                                                className='rselect'
                                                components={{
                                                    ClearIndicator: () => <div></div>,
                                                    //MultiValue: () => <div></div>
                                                }}
                                                defaultValue={form.categories}
                                                onChange={(data) => handleForm("categories", data)}
                                                isDisabled={isError}
                                                options={data}
                                                isMulti={true}
                                            />
                                        )
                                    }
                                }
                            </ContainerGetData>
                            {errors.categories && <p className='text-error'> {errors.categories.msg} </p>}
                        </div>
                        <div>
                            <p className='input-title'>Etiquetas</p>
                            <AsyncCreatableSelect
                                placeholder="Buscar"
                                className='rselect'
                                components={{
                                    ClearIndicator: () => <div></div>,
                                }}
                                defaultValue={form.tags || []}
                                onChange={(data) => handleForm("tags", data)}
                                //onCreateOption={handleCreateTag}
                                //isDisabled={isError}
                                loadOptions={(input) => getTags(input)}
                                //options={data}
                                isMulti={true}
                            />
                            {errors.tags && <p className='text-error'> {errors.tags.msg} </p>}
                        </div>
                    </StyledAsideBodyPost>
                </StyledContainerPost>
            </StyledBodyCreatePost>
        </StyledCreatePost>
    )
}

export default SectionCreatePost