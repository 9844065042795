import styled from "styled-components";
import { PRIMARY_COLOR, PRIMARY_COLOR_HOVER } from "../../../../../../../../styled-components/variables";

export const StyledSectionComments = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .title{
        width: 100%;
        margin: 5px 0 ;
        text-align: start;
        font-weight: bold;
    }
    .comments{
        width: 100%;
    }
` 

export const StyledComment = styled.div`
    width: 100%;
    margin: 0.5rem 0;
    display: flex;
    position: relative;
    .container-body{
        width: 100%;
    }
    .img{
        width: 40px;
        height: 40px;
        border-radius: 100%;
        margin-right: 0.4rem;
        font-size: 12px;
        object-fit: cover;
    }
    .time{
        position: absolute;
        top: 0;
        right: 0;
        font-size: 0.5rem;
        margin: 0;
    }
    .title{
        font-size: 0.9rem;
        width: 100%;
        display: block;
        font-weight: bold;
        margin: 2px 0;
    }
    .description{
        margin: 0;
        text-align: start;
    }
    .container-replys{
        width: 100%;
    }
    &.container-reply{
        width: 100%;
        margin: 0;
        padding-left: 2rem;
        display: flex;
        flex-direction: column;
    }
    .reply{
        font-size: 0.8rem;
        font-weight: bold;
        margin: 5px 0;
        cursor: pointer;
        color: ${PRIMARY_COLOR};
        &:hover{
            color: ${PRIMARY_COLOR_HOVER};
        }
    }
    .response{
        width: 100%;
        resize: none;
        outline: none;
        padding: 0.4rem;
        border-radius: 5px;
        border: 1px solid #ccc;
        font-size: 0.8rem;
        min-height: 50px;
        text-align: start;
        &:empty::before {
            position: absolute;
            content: "Añadir Comentario";
        }
    }
    .response-footer{
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .responseSubmit{
        text-align: end;
        button{
            width: 100px;
            padding: 0.5rem 0;
            font-size: 12px;
            margin: 5px 0;
            margin-left: 5px;
            cursor: pointer;
        }
    }
    .footer{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        p{
            margin-right: 10px;
        }
    }
`