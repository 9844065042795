import { useQuery } from "react-query"
import { StyledCardLocation, StyledModalLocationAds } from "../styles"
import { getAdsLocations } from "../../../services/ads.services"
import BtnPrimary from "../../../../../../../components/Buttons/BtnPrimary"
import { useState } from "react"
import { IAdsLocation } from "../../../interfaces/ads.interfaces"

interface props {
    handleClose: () => void
    action: (newList: IAdsLocation[]) => void 
    initList ?: IAdsLocation[]
}

const ModalLocationsAds = ({handleClose, action,initList}:props) => {
    const {isLoading, data, isError} =  useQuery("ads-locations", getAdsLocations, {refetchOnWindowFocus: false})
    const [listEspaces, setListEspaces] = useState<IAdsLocation[]>(initList ? initList : []);

    const handleListEspaces = (item: IAdsLocation) => {
        if(listEspaces.includes(item)){
            setListEspaces(listEspaces.filter(x => x.id !== item.id))
            return
        }
        setListEspaces([...listEspaces, item])
    }

    if(isLoading){
        return(
            <StyledModalLocationAds>
                <div className="modal-header">
                    <p>SELECCION TUS ESPACIOS PUBLICITARIOS</p>
                    <i className="fa-solid fa-xmark" onClick={handleClose}></i>
                </div>
                <div className="list-ads">
                    cargando...
                </div>
            </StyledModalLocationAds>
        )
    }
    if(isError || !data){
        return(
            <StyledModalLocationAds>
                <div className="modal-header">
                    <p>SELECCION TUS ESPACIOS PUBLICITARIOS</p>
                    <i className="fa-solid fa-xmark" onClick={handleClose}></i>
                </div>
                <div className="list-ads">
                    Ocurrio un error
                </div>
            </StyledModalLocationAds>
        )
    }

    return(
        <StyledModalLocationAds>
            <div className="modal-header">
                <p>SELECCION TUS ESPACIOS PUBLICITARIOS</p>
                <i className="fa-solid fa-xmark" onClick={handleClose}></i>
            </div>
            <div className="list-ads">
                <StyledCardLocation onClick={() => setListEspaces([])}>
                    <div className="checked"><input type="checkbox" checked={listEspaces.length === 0} /></div>
                    <div className="picture"></div>
                    <div className="title">Todos los espacios</div>
                </StyledCardLocation>
                {
                    data.map( (item) => {
                        return(
                            <StyledCardLocation key={item.id} onClick={() => handleListEspaces(item)}>
                                <div className="checked"><input type="checkbox" checked={listEspaces.findIndex(x => x.id === item.id) > -1} /></div>
                                <div className="picture"></div>
                                <div className="title">{item.location}</div>
                            </StyledCardLocation>
                        )
                    } )
                }
            </div>
            <div className="modal-footer">
                <BtnPrimary 
                    handleClick={() => {
                        action(listEspaces);
                        handleClose()
                    }} 
                    title="Añadir" 
                />
            </div>
        </StyledModalLocationAds>
    )
}

export default ModalLocationsAds