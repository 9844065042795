import Skeleton from "react-loading-skeleton"

import { StyledContainerPost } from "../../../../styles"
import { StyledHeaderShowPost } from "../../styles"
import SkeletonAsidePost from "../AsidePost/skeleton"
import SkeletonBodyPost from "../BodyPost/skeleton"

const SkeletonPost = () => {
    return (
        <>
            <StyledHeaderShowPost>
                <Skeleton width="100px" height="30px"/>
                &nbsp;
                <Skeleton width="100px" height="30px"/>
            </StyledHeaderShowPost>
            <StyledContainerPost>
                <SkeletonBodyPost/>
                <SkeletonAsidePost/>
            </StyledContainerPost>
        </>
    )
}

export default SkeletonPost