import { useState } from "react";
import { IComment } from "../interfaces/comments.interfaces"

const useComments = (
    id: string, 
    service: (form: {id: string,comment: string}) => Promise<IComment>
) => {
    const [comment, setComment] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [tempComments, setTempComments] = useState<IComment[]>([]);


    const handleComment = (str: string) => {
        setComment(str);
    }

    const sendComment = () => {
        setLoading(true)
        service({id, comment})
            .then((data) => {
                console.log(data)
                setTempComments([data,...tempComments])
            })
            .catch((error) => setError("Ocurrio un error"))
            .finally( () => setLoading(false) )
    }

    return {
        handleComment,
        comment,
        error,
        loading,
        tempComments,
        sendComment
    }

}

export default useComments