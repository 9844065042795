import axios from "axios";
import { URL } from "../../../../../config";
import { getCookie } from "../../../utils/Http";
import { IAds, IAdsformat, IAdsLocation, IDetailAds, IDetailAdsRAW } from "../interfaces/ads.interfaces";

const header = {
    headers: {
        Authorization: `Bearer ${getCookie()}`,
        'Content-Type': 'application/json'
    }
}

export const getMyAds = async (): Promise<IAds[]> => {
    const resp = await axios.get(`${URL}api/ads/getAds`, header)
    if (resp.status === 200) return resp.data.data
    return []
}

export const getDetailAds = async (id: string): Promise<IDetailAdsRAW | null> => {
    const resp = await axios.get(`${URL}api/ads/getDetailAds/${id}`, header)
    if (resp.status === 200) return resp.data.data
    return null
}

export const createAds = async (form: FormData, id?:string) => {
    const resp = await axios.post(`${URL}api/ads/createAds${id ? "/"+id : ""}`,form, {
        headers: {
            Authorization: `Bearer ${getCookie()}`,
            'Content-Type': 'multipart/form-data'
        }
    })
    if (resp.status === 200) return resp.data.data
    return new Error("Ocurrio un error")
}

export const uploadImage = async (form: FormData) => {
    const resp = await axios.post(`${URL}api/ads/upload-files`,form, {
        headers: {
            Authorization: `Bearer ${getCookie()}`,
            'Content-Type': 'multipart/form-data'
        }
    })
    if (resp.status === 201) return resp.data.data
    return new Error("Ocurrio un error")
}

export const getAdsFormats = async (): Promise<IAdsformat[]> => {
    const resp = await axios.get(`${URL}api/ads/formats`, header)
    if (resp.status === 200) return resp.data.data
    return []
}
export const getAdsLocations = async (): Promise<IAdsLocation[]> => {
    const cacheData = window.sessionStorage.getItem("adsLocations")
    if(cacheData) return JSON.parse(cacheData)
    
    const resp = await axios.get(`${URL}api/ads/bannerSpaces`, header)
    if (resp.status === 200){
        window.sessionStorage.setItem("adsLocations", JSON.stringify(resp.data.data))
        return resp.data.data
    }
    return []
}