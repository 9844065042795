import { BrowserRouter as Router } from "react-router-dom";
import ContextAuth from "../contexts/Auth.context";
import AppRouter from "../modules/app/App.router";
import AuthRouter from "../modules/auth/Auth.router";
import { QueryClient, QueryClientProvider } from "react-query";
import MainLoader from "../components/Loaders/MainLoader";

const queryClient = new QueryClient()

const RootRouter = () => {
    const { dataUser,loading } = ContextAuth();

    if(loading) {
        return(
            <MainLoader/>
        )
    }

    return (
        <Router basename="/">
            {dataUser.auth 
                ? <QueryClientProvider client={queryClient}><AppRouter /> </QueryClientProvider>
                : <AuthRouter />
            }
        </Router>
    );
}

export default RootRouter