import { ApexOptions } from "apexcharts"
import ReactApexChart from "react-apexcharts"
import { useQuery } from "react-query"
import { getPopularCategories } from "../services/analitycs.services"
import AnalitycsLoader from "../../../../../components/Loaders/AnalitycsLoader"

const state = {
    series: {
        data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380]
    },
    options: {
        chart: {
            width: "100%",
            type: 'bar',
            toolbar: {
                show: false // Para ocultar la barra de herramientas del gráfico
            }
        },
        dataLabels: {
            enabled: true,
            position: 'inside',
            formatter: function (val: string, data:any) {
                return val/*+" - "+(data.globals.labels[data.dataPointIndex] as string).slice(0, 8)*/;
            }
        },
        fill: {
            //colors: ["rgba(19,117,140,1)"]
        },
        yaxis: {
            reversed: true,
            axisTicks: {
                show: false
            },
            lines: {
                show: false // Ocultar las líneas del eje y
            },
            labels: {
                show: false
            },
        },
        xaxis: {
            axisBorder: {
                show: false
            },
            labels: {
                show: false
            },
            axisTicks: {
                show: false // Ocultar las marcas del eje x
            },
            lines: {
                show: false // Ocultar las líneas del eje x
            }
        },
        grid: {
            show: false // Ocultar las líneas de fondo del gráfico
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: true,
                distributed: true
            }
        },
    },
}
const BarCategorys = () => {
    const { isLoading, data, isError } = useQuery(["analitycs", "home", "popularCategories"], () => getPopularCategories(), { refetchOnWindowFocus: false, retry: 1 })


    if (isLoading) {
        return (
            <div className="center-loading"><AnalitycsLoader /></div>
        )
    }

    if (!data || isError) {
        return (
            <p>Error</p>
        )
    }


    return (
        <ReactApexChart
            //options={stateBarChart.options as ApexOptions} 
            options={
                {
                    ...state.options,
                    labels: data.filter((i, x) => x < 5).map(item => item.name),
                } as ApexOptions
            }
            height={280}
            //series={stateBarChart.series} 
            series={[{
                data: data.filter((i, x) => x < 5).map(item => item.total)
            }]}
            type="bar"
        />
    )
}

export default BarCategorys