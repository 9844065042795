import { ImageAdapter } from "../../../adapters/image.adapter";
import { IAdsForm, IDetailAdsRAW } from "../interfaces/ads.interfaces"

export const AdapRawDetailPost = (data: IDetailAdsRAW): IAdsForm => {

    const groupBySizeFile = data.ads.reduce((acc: { [sizeFile: string]: IDetailAdsRAW['ads'][0] }, ad) => {
        const { sizeFile } = ad;
        if (!acc[sizeFile]) {
            acc[sizeFile] = ad;
        }
        return acc;
    }, {});

    const filters = data.filters[0];
    const indexFilterAge = filters.findIndex(item => item.id === 1)
    //const indexFilterGender = filters.findIndex(item => item.id === 4)
    const indexFilterCat = filters.findIndex(item => item.id === 2)
    const indexFilterTags = filters.findIndex(item => item.id === 3)

    return {
        title: data.title,
        startDate: data.startDate,
        endDate: data.endDate,
        hourStart: data.hourStart || "",
        hourEnd: data.hourEnd || "",
        finalURL: data.ads[0]?.link || "",
        advertisingSpaces: data.ads.map(itm => {
            return {
                id: itm.banner_space_id,
                location: itm.banner_space_location,
                measure: {
                    id: itm.measure_id,
                    width: parseInt(itm.sizeFile.split("x")[0]),
                    height: parseInt(itm.sizeFile.split("x")[1]),
                    measure: itm.sizeFile
                },
                measure_id: itm.measure_id
            }
        }),
        images: Object.values(groupBySizeFile).map(item => {
            return {
                idFormat: item.measure_id,
                url: ImageAdapter(item.path_file || ""),
                file: item.id.toString()
            }
        }),
        headline: data.headline,
        nameCallToAccion: data.button_name || "",
        description: data.description || "",
        filterAge: indexFilterAge > -1
            ? {
                active: filters[indexFilterAge].endAge ? ((filters[indexFilterAge].endAge || 0) > 0) : false,
                startAge: filters[indexFilterAge].startAge || 0,
                endAge: filters[indexFilterAge].endAge || 0
            }
            : {
                active: false,
                startAge: 0,
                endAge: 0
            },
        filterGender: {
            gender: 0, // 1 H // 2 M  // 3 ALL
            active: false,
        },
        filterCategorys: indexFilterCat > -1 ? {
            idCategorys: filters[indexFilterCat].categorys || [],
            active: (filters[indexFilterCat].categorys || [])?.length > 0,
        } :{
            idCategorys: [],
            active: false,
        },
        filterTags: indexFilterTags > -1 ? {
            idTags: filters[indexFilterTags].tags || [],
            active: (filters[indexFilterTags].tags || [])?.length > 0,
        } :{
            idTags: [],
            active: false,
        },
        edit: true
    }
}