import { useState } from "react"
import Header from "../../components/Header"
import WrapperApp from "../../components/WrapperApp"
import ListPosts from "./components/ListPosts";
import SelectTypePosts from "./components/SelectTypePosts";
import { IFormPublication } from "./interfaces/publications.interfaces";
import { StyledHeaderPosts, StyledInputPostSearch, StyledMainBtns, StyledMainPosts } from "./styles";


const SectionMainPosts = () => {
    const [form, setForm] = useState<IFormPublication>({
        query: "",
        type: 0,
        pag: 0
    }); 
    const LS_modeCards = window.localStorage.getItem("mode_view_cards")
    const [modeCards, setModeCards] = useState(LS_modeCards ? parseInt(LS_modeCards) : 0); //0 grid 1 list

    const setModeCardsWithLS = (value: number) => {
        setModeCards(value)
        window.localStorage.setItem("mode_view_cards", value.toString())
    }

    return(
        <WrapperApp>
            <StyledMainPosts>
                <Header navigation={["MIS Publicaciones"]}/>
                <StyledHeaderPosts>
                    <StyledInputPostSearch>
                        <input 
                            className="input" value={form.query}
                            type="text" placeholder="Buscar" 
                            onChange={(e) => setForm({...form, "query": e.currentTarget.value })}
                        />
                        <i className="fa-solid fa-magnifying-glass icon"></i>
                    </StyledInputPostSearch>
                    <SelectTypePosts
                        action={(id) => setForm({...form, "type": id, "query": "" })}
                        value={form.type}
                    />
                    <div></div>
                    <StyledMainBtns>
                        <button 
                            onClick={() => setModeCardsWithLS(0)}
                            className={`btn ${modeCards === 0 && "active"}`}
                        >
                            <i className="fa-solid fa-grip"></i>
                        </button>
                        <button 
                            onClick={() => setModeCardsWithLS(1)}
                            className={`btn ${modeCards === 1 && "active"}`}
                        >
                            <i className="fa-solid fa-list-ul"></i>
                        </button>
                    </StyledMainBtns>
                </StyledHeaderPosts>
                <ListPosts typeList={modeCards} dataForm={form} />
            </StyledMainPosts>
        </WrapperApp>
    )
}

export default SectionMainPosts