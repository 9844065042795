import HeaderNav from "../HeaderNav"
import Menu from "../Menu"
import { StyledBodyWrapperApp, StyledContent, StyledWrapperApp } from "./styles"

interface props {
    children: JSX.Element
}

const WrapperApp = ({children}:props) => {
    return (
        <StyledWrapperApp>
            <Menu/>
            <HeaderNav/>
            <StyledBodyWrapperApp>
                <StyledContent>
                    {children}
                </StyledContent>
            </StyledBodyWrapperApp>
        </StyledWrapperApp>
    )
}

export default WrapperApp