import { StyledBtnSecondary } from "./styles"

interface props {
    handleClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    style?: string
    title: string
    icon: JSX.Element
    color?: string
}


const BtnSecondary = ({ handleClick, style, title, icon,color }: props) => {
    return (
        <StyledBtnSecondary 
        color={color}
        onClick={(e:  React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleClick(e)} 
        className={style} >
            <span className="text">{title}</span>
            <span className="icon">
                {icon}
            </span>
        </StyledBtnSecondary>
    )
}

export default BtnSecondary