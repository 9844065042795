import { useState } from "react"
import Modal from "../../../../../components/Modals"
import ModalLocationsAds from "./ModalLocationsAds"
import { IAdsLocation } from "../../../interfaces/ads.interfaces"

interface props {
    classname ?: string
    initValue?: IAdsLocation[]
    action: (newVal: IAdsLocation[]) => void 
}

const SelectLocations = ({classname, initValue, action}:props) => {
    const [isOpenModal, setIsOpenModal] = useState(false);

    const handleListEspaces = (item: IAdsLocation) => {
        if(!initValue) return
        if(initValue.includes(item)){
            action(initValue.filter(x => x.id !== item.id))
            return
        }
        action([...initValue, item])
    }


    return (
        <div className="select-advertisingSpaces">
            <div className="listItems-advertisingSpaces">
                {initValue?.length === 0 &&<div><input type="checkbox" checked={true} />&nbsp;Todos los espacios</div>}
                {
                    initValue?.map( (item, index) => 
                        <div key={index}>
                            <input type="checkbox" onClick={() => handleListEspaces(item)} checked={initValue.includes(item)} />&nbsp;{item.location}
                        </div> 
                    )
                }
            </div>
            <button className="btn-advertisingSpaces" onClick={() => setIsOpenModal(true)}>+ añadir</button>
            <Modal 
                JSXmodal={(props) => ModalLocationsAds({...props, action, initList: initValue})} 
                isOpen={isOpenModal} 
                setIsOpen={setIsOpenModal} 
            />
        </div>
    )
}

export default SelectLocations