import { IAdsForm } from "../../../interfaces/ads.interfaces"

export const ValidateInitForm = (form: IAdsForm) => {
    const errors:{[key: string]: { error: boolean, msg: string }} = {}
    
    try {
        if(form.title.length < 2 ) errors["title"] = { error: true, msg: "El titulo no es valido" }
        if(!form.edit && form.startDate.length < 2 ) errors["startDate"] = { error: true, msg: "Ingrese una fecha de inicio" }
        if(form.endDate.length < 2 ) errors["endDate"] = { error: true, msg: "Ingrese una fecha de fin" }
        if(!form.edit && new Date(form.startDate).getTime() < (new Date()).getTime()){
            errors["startDate"] = { error: true, msg: "La fecha tiene que se mayor a hoy" }
        }
        if(!form.edit && new Date(form.endDate).getTime() < new Date(form.startDate).getTime()){
            errors["endDate"] = { error: true, msg: "La fecha de fin tiene que ser mayor a la fecha de inicio" }
        }
        if(!form.edit && new Date(form.startDate).getTime() === new Date(form.endDate).getTime()){
            errors["endDate"] = { error: true, msg: "La fecha de fin tiene que ser diferente a hoy" }
        }
    } catch (error) {
        errors["other"] = { error: true, msg: "Ocurrio un error al validar" }
    }
    return errors
}

export const ValidateFormConfig = (form: IAdsForm) => {
    const RGX_URL = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
    const errors:{[key: string]: { error: boolean, msg: string }} = {}

    try {
        if (!form.finalURL.match(RGX_URL)) errors["finalURL"] = { error: true, msg: "La url no es valida" }

        if(form.images.length === 0 ) errors["images"] = { error: true, msg: "Debe subir una imagen" }
        if(form.images.length !== 0 && ( form.images[0].file === null && form.images[0].url === "" )) {
            errors["images"] = { error: true, msg: "Debe subir una imagen" }
        }
        if(form.headline.length < 2 || form.headline.length > 100 ) errors["headline"] = { error: true, msg: "El titular no es valido, max 100 caracteres" }
        //if(form.nameCallToAccion.length < 2 || form.nameCallToAccion.length > 50  ) errors["nameCallToAccion"] = { error: true, msg: "El nombre de la accion no es valida, max 50 caracteres" }
        //if(form.description.length < 2 || form.nameCallToAccion.length > 200   ) errors["description"] = { error: true, msg: "La descripcion no es valida, max 200 caracteres" }
    } catch (error) {
        errors["other"] = { error: true, msg: "Ocurrio un error al validar" }
    }
    
    return errors
}

export const ValidateFiltersForm = (form: IAdsForm) => {
    const errors:{[key: string]: { error: boolean, msg: string }} = {}
    
    try {
        if(form.filterAge.active) {
            if(form.filterAge.startAge <= 0 || form.filterAge.startAge > 99 ) errors["startAge"] = { error: true, msg: "La edad inicial es invalida" }
            if(form.filterAge.endAge <= 0 || form.filterAge.endAge > 99 ) errors["endAge"] = { error: true, msg: "La edad final es invalida" }
            if(form.filterAge.endAge < form.filterAge.startAge ) errors["endAge"] = { error: true, msg: "La edad final es menor a la inicial" }
        }
    } catch (error) {
        errors["other"] = { error: true, msg: "Ocurrio un error al validar" }
    }
    return errors
}