import styled from "styled-components";
import { BG_COLOR, PRIMARY_COLOR } from "../../../styled-components/variables";

export const StyledBtnSecondary = styled.button`

 cursor: pointer;
 display: flex;
 align-items: center;
 background: red;
 border: none;
 border-radius: 5px;
 box-shadow: 1px 1px 3px rgba(0,0,0,0.15);
 background-color: ${(props) => props.color ? props.color: PRIMARY_COLOR} ;
 transition: 200ms;

span {
 transition: 200ms;
}

.text {
 transform: translateX(35px);
 color: white;
 font-weight: bold;
}

.icon {
 position: absolute;
 
 transform: translateX(110px);
 height: 40px;
 width: 40px;
 display: flex;
 align-items: center;
 justify-content: center;
 color: ${BG_COLOR}
}


&:hover {
    background-color: ${(props) => props.color ? props.color: PRIMARY_COLOR} ;
}

&:hover .text {
 color: transparent;
}

&:hover .icon {
 width: 150px;
 border-left: none;
 transform: translateX(0);
}

&:focus {
 outline: none;
}

&:active .icon svg {
 transform: scale(0.8);
}
`