import axios from "axios";
import { URL } from "../../../../../../../../../config";
import { getCookie } from "../../../../../../../utils/Http";
import { IEPComment } from "../interfaces/comments.interfaces";

const header = {
    headers: {
        Authorization: `Bearer ${getCookie()}`,
        'Content-Type': 'application/json'
    }
}

export const getComments = async (idPost: string): Promise<IEPComment | null> => {
    const resp = await axios.get(`${URL}api/portal/getPostComment?idPost=${idPost}`,header)
    if(resp.status === 200) return resp.data.data
    return null
}

export const getReplyComments = async (idComment: string): Promise<IEPComment | null>  => {
    const resp = await axios.get(`${URL}api/portal/getReplyComments?idComment=${idComment}`,header)
    if(resp.status === 200) return resp.data.data
    return null
}


export const CommentPost = async (form: { id: string, comment: string }) => {
    //solo ejecutar en cliente
    const data = {
        idPost: form.id,
        comment: form.comment
    }
    const resp = await axios.post(`${URL}api/portal/commentPost`,data,header)
    if(resp.status === 201) return resp.data.data
    return new Error("No se puedo crear el comentario")
}

export const ReplyComment = async (form: { id: string, comment: string })  => {
    //solo ejecutar en cliente
    const data = {
        idComment: form.id,
        comment: form.comment
    }

    const resp = await axios.post(`${URL}api/portal/replyComment`,data,header)
    if(resp.status === 201) return resp.data.data
    return new Error("No se puedo responder el comentario")

}