import BtnSecondary from "../../../../../../../../components/Buttons/BtnSecondary"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { PRIMARY_COLOR } from "../../../../../../../../styled-components/variables"
import { deletePost } from "../../../../services/post.services"
import { useState } from "react"
import PrimaryLoader from "../../../../../../../../components/Loaders/PrimaryLoader"
import SimpleLoader from "../../../../../../../../components/Loaders/SimpleLoader"
import { useNavigate } from "react-router-dom"

const MySwal = withReactContent(Swal)

interface props {
    id: string
}

const BtnDeletePost = ({id}:props) => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const actionDelete = () => {
        setLoading(true)
        deletePost(id)
            .then( () => {
                MySwal.fire({
                    icon:  'success',
                    title: <p style={{margin: 0}}>Publicacion Eliminada</p>,
                    confirmButtonText: 'ok',
                    confirmButtonColor: "#157effd4",
                }).then( () => navigate(`/myposts`) )
            })
            .catch( () =>{ 
                MySwal.fire({
                    icon:  'error',
                    title: <p style={{margin: 0}}>Ocurrio un error</p>,
                    confirmButtonText: 'ok',
                    confirmButtonColor: PRIMARY_COLOR,
                  })
            })
            .finally( () => setLoading(false) )
    }

    const handleDelete = async () => {

        const resp = await MySwal.fire({
            icon:  'error',
            title: <p style={{margin: 0}}>¿Estas seguro de borrar esta publicacion?</p>,
            confirmButtonText: 'Aceptar',
            cancelButtonText: "Cancelar",
            cancelButtonColor: "#157effd4",
            showCancelButton: true,
            confirmButtonColor: PRIMARY_COLOR,
          })
          if(resp.isConfirmed) actionDelete() 
    }

    if(loading){
        return(
            <BtnSecondary
            title="Eliminando"
            handleClick={() => {}}
            icon={<SimpleLoader/>}
            />
        )
    }

    return (
        <BtnSecondary
            title="Eliminar"
            handleClick={handleDelete}
            icon={<i className="fa-solid fa-xmark"></i>}
        />
    )
}

export default BtnDeletePost