import BtnPrimary from "../../../../../../../components/Buttons/BtnPrimary";
import BoxPreviewImg from "../../../../../components/BoxPreviewImg";
import { IAdsForm, IAdsLocation } from "../../../interfaces/ads.interfaces";
import SelectFormats from "../components/SelectFormats";
import SelectLocations from "../components/SelectLocations";
import { StyledSectionConf } from "../styles";
import toast, { Toaster } from 'react-hot-toast';
import { validateSizeFile } from "../utils/ads.validate";
import { useState } from "react";
import { ValidateFormConfig } from "../utils/form.validate";
import TextArea from "../../../../../components/TextArea";
import PreviewAds from "../components/PreviewAds";
import SelectImages from "../components/SelectImages";

interface props {
    nextPage: () => void
    prevPage: () => void
    form: IAdsForm
    handleForm: React.Dispatch<React.SetStateAction<IAdsForm>>
}

const SectionConfig = ({form, handleForm, nextPage,prevPage}:props) => {
    const [errors, setErrors] = useState<{[key: string]: { error: boolean, msg: string }}>({});

    const validateForm = () => {
        const errors = ValidateFormConfig(form)
        if(Object.keys(errors).length !== 0){
            setErrors(errors)
            toast.error('Formulario Invalido');
            return
        }
        nextPage()
    }

    return (
        <StyledSectionConf>
            <div>
                <Toaster position="bottom-right" />
                <div>
                    <p className="subtitle-form">URL FINAL*</p>
                    <input
                        className={`input-form ${errors.finalURL ? "error" : ""}`} type="text"
                        placeholder="www.example.com"
                        onChange={(e) => handleForm({ ...form, "finalURL": e.currentTarget.value })}
                        value={form.finalURL}
                    />
                    {errors.finalURL && <p className='text-error'> {errors.finalURL.msg} </p>}
                </div>
                <div>
                    <div>
                        <p className="subtitle-form">ESPACIOS PUBLICITARIOS</p>
                        <SelectLocations
                            classname="select-form"
                            action={(val) => handleForm({ ...form, "advertisingSpaces": val })}
                            initValue={form.advertisingSpaces}
                        />
                    </div>
                </div>
                <div>
                    <p className="subtitle-form">PORTADAS DEL ANUNCIO*</p>
                    <SelectImages 
                        locations={form.advertisingSpaces} 
                        action={(val) => handleForm({ ...form, "images": val })}
                        initValue={form.images}
                    />
                    {errors.images && <p className='text-error'> {errors.images.msg} </p>}
                </div>
                <div>
                    <p className="subtitle-form">TITULAR*</p>
                    <input
                        className={`input-form ${errors.headline ? "error" : ""}`} type="text"
                        placeholder="Escribe aqui"
                        onChange={(e) => handleForm({ ...form, "headline": e.currentTarget.value })}
                        value={form.headline}
                    />
                    {errors.headline && <p className='text-error'> {errors.headline.msg} </p>}
                </div>
                <div>
                    <p className="subtitle-form">ACCION</p>
                    <input
                        className={`input-form ${errors.nameCallToAccion ? "error" : ""}`} type="text"
                        placeholder="Escribe aqui"
                        onChange={(e) => handleForm({ ...form, "nameCallToAccion": e.currentTarget.value })}
                        value={form.nameCallToAccion}
                    />
                    {errors.nameCallToAccion && <p className='text-error'> {errors.nameCallToAccion.msg} </p>}
                </div>
                <div>
                    <p className="subtitle-form">DESCRIPCION</p>
                    <TextArea 
                        classname={`input-form ${errors.description ? "error" : ""}`} placeholder="Descripcion" 
                        value={form.description} handleChange={(data) => handleForm({ ...form, "description": data })}
                    />
                    {errors.description && <p className='text-error'> {errors.description.msg} </p>}
                </div>
                <div className="footer-form">
                    <BtnPrimary style="button-prev" title="REGRESAR" handleClick={prevPage}/>
                    <BtnPrimary style="button" title="CONTINUAR" handleClick={validateForm}/>
                </div>
            </div>
            <div className="content-preview">
               <PreviewAds form={form} />
            </div>
        </StyledSectionConf>
    )
}

export default SectionConfig