import BtnPrimary from "../../../../components/Buttons/BtnPrimary"
import LogoPozicion from "../../../../components/Logos/LogoPozicion"
import { URL_PORTAL } from "../../../../config"
import { StyledBoxNoAccess, StyledNoAccess } from "./styles"

const NoAccess = () => {
    
    return(
        <StyledNoAccess>
            <StyledBoxNoAccess>
                <a href={URL_PORTAL}>
                    <LogoPozicion style="logo" width={277} height={50} />
                </a>
                <p className="login-title">No Tienes Accesso a este sitio web</p>
                <p >Llena el formulario para convertirte en un publicador</p>
                <br />
                <a href={URL_PORTAL+"solicitud-publicador"}>
                    <BtnPrimary style="login-btn" title="Ir al formulario" handleClick={()=> {}} />
                </a>
            </StyledBoxNoAccess>
        </StyledNoAccess>
    )
}

export default NoAccess