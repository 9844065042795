import styled from "styled-components";
import { PRIMARY_COLOR } from "../../../../../../../../styled-components/variables";

export const StyleBodyPost = styled.div`
    width: 100%;
`

export const StyleHeaderBodyPost = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .starts{
        text-align: end;
    }
`

export const StyleTitleBodyPost = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    h1{
        margin: 5px 0;
    }
    i{
        cursor: pointer;
    }
`

export const StyleSubtitleBodyPost = styled.p`
    width: 100%;
    text-align: start;
    margin: 5px 0;
    margin-bottom: 1rem;
    font-size: 16px;
`

export const StyleIMGBodyPost = styled.img`
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 5px;
`

export const StyledSubHeaderBodyPost = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .active{
        font-weight: bold;
        color: ${PRIMARY_COLOR};
    }
    p{
        margin: 10px 0;
        font-size: 12px;
    }
`

export const StyleDescriptionBodyPost = styled.p`
    width: 100%;
    margin: 10px 0;
    text-align: justify;
    img{
        width: 90%;
        margin: 0 auto;
    }
    iframe{
        width: 90%;
        margin: 0 auto;
        border: none;
    }
`