import BoxAddComment from ".";
import useComments from "../../hooks/useComments";
import { CommentPost } from "../../services/comments.services";
import { StyledComment } from "../../styles";
import Comment from "../Comment";
import SkeletonComment from "../Comment/skeleton";

interface props {
    id: string
}

const ContainerAddComment = ({ id }: props) => {
    //const { dataUser } = useContext(AuthContext);
    const {handleComment, loading,comment,error,sendComment,tempComments} = useComments(id ,CommentPost);

    //if (!dataUser.auth) return (<></>)

    if (loading) {
        return (
            <StyledComment className="flex-center">
                <SkeletonComment/>
            </StyledComment>
        )
    }

    return (
        <>
            <BoxAddComment
                handleComment={handleComment}
                sendComment={sendComment}
                comment={comment}
                error={error}
            />
            {tempComments.map((item) => <Comment {...item} />)}
        </>
    )
}

export default ContainerAddComment