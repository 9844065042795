import styled from "styled-components";

export const StyledShowAds = styled.div`
    width: 100%;
    .table{
        width: 100%;
        padding: 2rem 0;
    }
`

export const StyledShowAdsHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .btn{
        max-width: 200px;
    }
`