import axios from "axios";
import { URL } from "../../../../../config";
import { getCookie } from "../../../utils/Http";
import { IDataLastSubs, IDataPopularCats, IDataViewedOfAds, IDataViewedOfPosts, IsummaryDataAds, IsummaryDataPublisher } from "../interfaces/home.interfaces";

const header = {
    headers: {
        Authorization: `Bearer ${getCookie()}`,
        'Content-Type': 'application/json'
    }
}

export const getSummaryPublisher = async ( filters:{type: number, month: number}): Promise<IsummaryDataPublisher | null> => {
    const resp = await axios.get(`${URL}api/publisher/analytics/getSummaryPublisher`, {...header, params:filters })
    if (resp.status === 200) return resp.data.data
    return null
}

export const getSummaryAds = async ( filters:{type: number, month: number}): Promise<IsummaryDataAds | null> => {
    const resp = await axios.get(`${URL}api/ads/analytics/getSummaryAdvertiser`, {...header, params:filters })
    if (resp.status === 200) return resp.data.data
    return null
}

export const getViewsOfPosts = async ( filters:{type: number, month: number}): Promise<IDataViewedOfPosts[] | null> => {
    const resp = await axios.get(`${URL}api/publisher/analytics/getViewsOfPosts`, {...header, params:filters })
    if (resp.status === 200) return  resp.data.data
    return null
}
 
export const getViewsOfAds = async ( filters:{type: number, month: number}): Promise<IDataViewedOfAds | null> => {
    const resp = await axios.get(`${URL}api/ads/analytics/getViewsClicksOfAdvertiser`, {...header, params:filters })
    if (resp.status === 200) return  resp.data.data
    return null
}

export const getPopularCategories = async (): Promise<IDataPopularCats[] | null> => {
    const resp = await axios.get(`${URL}api/publisher/analytics/getPopularCategories`, header)
    if (resp.status === 200) return  resp.data.data
    return null
}

export const getPopularTags = async (): Promise<IDataPopularCats[] | null> => {
    const resp = await axios.get(`${URL}api/publisher/analytics/getPopularTags`, header)
    if (resp.status === 200) return  resp.data.data
    return null
}

export const getLatestFollowers = async (): Promise<IDataLastSubs[] | null> => {
    const resp = await axios.get(`${URL}api/publisher/analytics/getLatestFollowers`, header)
    if (resp.status === 200) return  resp.data.data
    return null
}

export const getFollowersPerPeriod = async ( filters:{type: number, month: number}): Promise<number[] | null> => {
    const resp = await axios.get(`${URL}api/publisher/analytics/getFollowersPerPeriod`,  {...header, params: filters })
    if (resp.status === 200) return  resp.data.data
    return null
}