import { useQuery } from "react-query"
import Header from "../../../../components/Header"
import WrapperApp from "../../../../components/WrapperApp"
import { getDataProfileForEdit } from "../../services/profile.services"
import SkeletonEditProfile from "./skeleton"
import { StyledMainEditProfile } from "./styles"
import ViewEditProfile from "./view"

const EditProfile = () => {
    const {isLoading, data, isError} =  useQuery("myEditProfile",getDataProfileForEdit, {refetchOnWindowFocus: false})

    if(isLoading){
        return(
            <WrapperApp>
                <SkeletonEditProfile/>
            </WrapperApp>
        )
    }

    if(isError || !data || Object.keys(data).length === 0){
        return(
            <WrapperApp>
                <p>Ocurrio un error</p>
            </WrapperApp>
        )
    }

    return (
        <WrapperApp>
            <ViewEditProfile {...data} />
        </WrapperApp>
    )
}

export default EditProfile