import { useContext, useState, createContext } from "react";

interface UIRouterInterface {
    viewActive: number
    handleRoute: (newRoute: number) => void
}


const UIRouterContext = createContext<UIRouterInterface>(
    {
        viewActive: 0,
        handleRoute: () => {return}
    }
);

interface props {
    children: JSX.Element | JSX.Element[]
}

const UIRouterContextProvider = ({ children }: props) => {
    const [viewActive, setViewActive] = useState(0);

    const handleRoute = (newRoute: number) => setViewActive(newRoute)
    

    return (
        <UIRouterContext.Provider value= {
            {
                viewActive,
                handleRoute
            }
        }>
            {children}
        </UIRouterContext.Provider>
    )
}


export { UIRouterContextProvider };

const useUIRouter = () => {
    return useContext(UIRouterContext);
}

export default useUIRouter;