import axios from "axios";
import { URL } from "../../../../../../../config";
import { getCookie } from "../../../../../utils/Http";

const header = {
    headers: {
        Authorization: `Bearer ${getCookie()}`,
        'Content-Type': 'application/json'
    }
}

export const getDataClicks = async (idAd: string, filters:{type: number, month: number}): Promise<number[]> => {
    const resp = await axios.get(`${URL}api/ads/analytics/getClicks/${idAd}`, {...header, params:filters })
    if (resp.status === 200) return resp.data.data.reverse()
    return []
}

export const getDataViews = async (idAd: string, filters:{type: number, month: number}): Promise<number[]> => {
    const resp = await axios.get(`${URL}api/ads/analytics/getViews/${idAd}`, {...header, params:filters })
    if (resp.status === 200) return resp.data.data.reverse()
    return []
}

export const getDataAges = async (idAd: string, filters:{type: number, month: number}): Promise<{age: number, total: number}[]> => {
    const resp = await axios.get(`${URL}api/ads/analytics/getAgeOfViewers/${idAd}`, {...header, params:filters })
    if (resp.status === 200) return resp.data.data
    return []
}

export const getDataGender = async (idAd: string, filters:{type: number, month: number}): Promise<{totalMales: number, totalFemales: number}> => {
    const resp = await axios.get(`${URL}api/ads/analytics/getGenderOfViewers/${idAd}`, {...header, params:filters })
    if (resp.status === 200) return resp.data.data
    return {totalMales: 0, totalFemales: 0}
}