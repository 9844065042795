import { useQuery } from "react-query"
import Header from "../../../../components/Header"
import WrapperApp from "../../../../components/WrapperApp"
import { getMyAds } from "../../services/ads.services"
import ViewAds from "./view"

const ShowAds = () => {
    const {isLoading, data, isError} =  useQuery("myads", getMyAds, {refetchOnWindowFocus: false})


    if(isLoading){
        return(
            <WrapperApp>
                <p>cargando skeleton..</p>
            </WrapperApp>
        )
    }

    if(!data || isError){
        return(
            <WrapperApp>
                <p>Ocurrio un error</p>
            </WrapperApp>
        )
    }

    return(
        <WrapperApp>
            <div>
                <Header navigation={["Anuncios"]} />
                <ViewAds data={data}/>
            </div>
        </WrapperApp>
    )
}

export default ShowAds