import Skeleton from "react-loading-skeleton"
import { StyledComment } from "../../styles"

const SkeletonComment = () => {

    return (
        <>
            <StyledComment>
                <div className="container">
                    <Skeleton width={40} height={40} borderRadius="50%" />
                </div>
                <div className="container container-body">
                    <p className="title"> <Skeleton width={80} height={15} /> </p>
                    <span className="time"> <Skeleton width={50} height={10} /> </span>
                    <p className="description">
                    <Skeleton width="100%" height={40} />
                    </p>
                    <div className="footer">
                        <p className="reply" ><Skeleton width={100} height={15} /></p>
                        <p className="reply"><Skeleton width={100} height={15} /></p> 
                    </div>
                </div>
            </StyledComment>
        </>
    )
}

export default SkeletonComment