import styled from "styled-components";
import { BG_CONTRAST_COLOR } from "../../../../../../styled-components/variables";

export const StyledCardLargePost = styled.div`
    width: 100%;
    display: grid; 
    padding: 0.8rem;
    margin: 5px 0;
    border-radius: 5px;
    box-shadow: 0 4px 9px #e9e9f9;
    background-color: ${BG_CONTRAST_COLOR};
    grid-template-columns: 26px 90px 1fr 60px 60px 100px;
    justify-content:  center;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    transition: all 0.2s;
    a{
        text-decoration: none;
    }
    &:hover{
        transform: scale(1.01);
        box-shadow: 0 4px 13px #d3d3d3;
    }
    .card-img{
        width: 80px;
        height: 50px;
        object-fit: cover;
        border-radius: 5px;
        display: flex;
        justify-content:  center;
        align-items: center;
    }
    .card-status{
        display: flex;
        justify-content:  center;
        align-items: center;
        width: 24px;
        height: 24px;
        font-size: 0.8rem;
        color: white;
        border-radius: 50%;
        background-color: #A2A2A2;
        &--public{
            background-color: #157EFF;
        }
    }
    .card-title{
        margin: 5px 0;
        margin-top: 2px;
        font-weight: bolder;
        text-transform: uppercase;
        color: black;
        text-align: start;
    }
    .card-tags{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
        height: 26px;
        
    }
    .card-itemTag{
        color: white;
        width: auto;
        height: 18px;
        padding: 2px;
        margin: 2px;
        border-radius: 5px;
        font-size: 0.6rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: center;
        background-color: #A2A2A2;
    }
    .card-feature{
        margin: 0 5px;
        color: black;
        font-size: 12px;
    }
`