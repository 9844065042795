import styled from "styled-components";
import { PRIMARY_COLOR_2 } from "../../../../styled-components/variables";

interface props {
    handleChange: (newVal: boolean) => void
    initialValue?: boolean
}

const Switch = ({handleChange, initialValue = false}:props) => {
    return(
        <StyledSwitch>
            <input type="checkbox" checked={initialValue} onChange={(e) => handleChange(e.currentTarget.checked)}/>
            <span className="slider round"></span>
        </StyledSwitch>
    )
}

const StyledSwitch = styled.label`
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked + .slider {
        background-color:  ${PRIMARY_COLOR_2};
    }

    input:focus + .slider {
        box-shadow: 0 0 1px  ${PRIMARY_COLOR_2};
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
`

export default Switch