import styled from "styled-components";
import { BG_COLOR, SECONDARY_COLOR, PRIMARY_COLOR, TEXT_COLOR, BG_CONTRAST_COLOR, PRIMARY_COLOR_2, PRIMARY_COLOR_2_HOVER } from "../../../../../../../../styled-components/variables";


export const StyledAdsInHomeFeed = styled.div`
    width: 100%;
    border: 2px solid #ccc;
    border-radius: 5px;
    .template-header{
        width: 100%;
        height: 30px;
        background-color: ${BG_COLOR};
        padding: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .template-search{
        width: 160px;
        background-color: #ebebeb;
        height: 20px;
        border-radius: 1px;
    }
    .template-userIcon{
        width: 20px;
        background-color: #ebebeb;
        height: 20px;
        border-radius: 50%;
    }
    .template-body{
        width: 100%;
        display: grid;
        grid-template-columns: 20% 80%;
        min-height: 200px;
        background-color: #ebebeb;
    }
    .template-aside{
        width: 100%;
        height: 100%;
        background-color: ${BG_COLOR};
    }
    .template-containertabs{
        width: 100%;
        padding: 5px;
        border-bottom: 1px solid #ebebeb;
    }
    .template-tab{
        background-color: #ebebeb;
        height: 14px;
        margin: 5px;
    }
    .template-content{
        width: 100%;
        display: grid;
        grid-template-columns: 70% 30%;
    }
    .preview{
        width: 100%;
        padding: 1rem;
    }
`

export const StyledAdsInHomeFeedMobile = styled.div`
    width: 100%;
    max-width: 60%;
    margin: 0 auto;
    border: 2px solid #ccc;
    .template-header{
        width: 100%;
        height: 30px;
        background-color: ${BG_COLOR};
        padding: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .template-userIcon{
        width: 20px;
        background-color: #ebebeb;
        height: 20px;
        border-radius: 50%;
    }
    .template-body{
        width: 100%;
        min-height: 200px;
        background-color: #ebebeb;
    }
    .template-content{
        width: 100%;
    }
    .preview{
        width: 100%;
        padding: 1rem;
    }
`

export const StyledAdsInHomeAside = styled(StyledAdsInHomeFeed)`
    .template-content{
        width: 100%;
        padding: 0.5rem;
        display: grid;
        grid-template-columns: 1fr;
    }
    .template-list{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 5px;
    }
    .template-item{
        width: 100%;
        .template-itemPicture{
            width: 100%;
            height: 80px;
            background-color: #ccc;
        }
        .template-itemTitle{
            width: 90%;
            height: 10px;
            background-color: #ccc;
            margin: 5px 0;
        }
        .template-containerAutor{
            width: 100%;
            margin: 5px 0;
            display: grid;
            grid-template-columns: 20% 80%;
            gap: 3px;
        }
        .template-autoricon{
            width: 100%;
            height: 100%;
            background-color: #ccc;
            border-radius: 50%;
        }
        .template-containerAutorBody{
            display: flex;
            flex-direction: column;
            gap: 3px;
        }
        .template-itemATitle, .template-itemAsubTitle{
            width: 50%;
            height: 8px;
            background-color: #ccc;
        }
        .template-itemAsubTitle{
            width: 40%;
        }
    }
    .template-preview{
        width: 100%;
        display: grid;
        grid-template-columns: 60% 1fr;
        gap: 5px;
        margin: 10px 0;
    }
    .preview-card{
        width: 100%;
        background-color: ${BG_COLOR};
        border-radius: 5px;
        padding: 0.5rem;
        &-header{
            width: 100%;
            display: grid;
            grid-template-columns: 30px 1fr;
            gap: 0.5rem;
        }
        &-img{
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: #ebebeb;
        }
        .container1{
            width: 100%;
            display: flex;
            flex-direction: column;
        }
        &-subheader{
            width: 100%;
            display: grid;
            grid-template-columns: 50px 70px 1fr;
            gap: 5px;
        }
        &-title, &-occupation{
            width: 100%; 
            height: 10px;
            margin: 5px 0;
            background-color: #ebebeb;
        }
        &-datapost{
            width: 100%; 
        }
        &-subtitle{
            width: 100%; 
            height: 10px;
            margin: 5px 0;
            background-color: #ebebeb;
        }
        &-picture{
            width: 100%; 
            height: 200px;
            background-color: #ebebeb;
        }
    }
    .container-ads{
        width: 100%; 
    }
    .preview-top{
        width: 100%;
        padding: 10px 0; 
    }
`

export const StyledAdsInHomeAsideMob = styled(StyledAdsInHomeAside)`
    width: 100%;
    max-width: 60%;
    margin: 0 auto;
    border: 2px solid #ccc;
    .template-header{
        width: 100%;
        height: 30px;
        background-color: ${BG_COLOR};
        padding: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .template-body{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 5px;
        padding: 1rem;
    }
    .template-list{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 5px;
    }
    .template-item{
        width: 100%;
    }
`