import { ApexOptions } from "apexcharts"
import ReactApexChart from "react-apexcharts"
import { useQuery } from "react-query";
import { getDataGender } from "../services/analitycs.services";
import AnalitycsLoader from "../../../../../../../components/Loaders/AnalitycsLoader";

const statePieChart = {
    series: [44, 55],
    options: {
        chart: {
            type: 'pie',
        },
        labels: ['Hombres', 'Mujeres'],
        legend: {
            position: 'bottom'
        },
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    position: 'bottom'
                }
            }
        }]
    },
};

interface props {
    idAds: string
    filters: {type: number, month: number}
}

const CircleChart = ({ filters, idAds }: props) => {
    const { isLoading, data, isError } = useQuery(["analitycs","circle", idAds, [filters.type, filters.month]], () => getDataGender(idAds, filters), { refetchOnWindowFocus: false })


    if (isLoading) {
        return (
            <div className="center-loading"><AnalitycsLoader/></div>
        )
    }

    if (!data || isError) {
        return (
            <p>Error</p>
        )
    }

    return (
        <ReactApexChart options={statePieChart.options as ApexOptions} series={[data.totalMales, data.totalFemales]} type="pie" />
    )
}

export default CircleChart