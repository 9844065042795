import { useQuery } from "react-query"
import { getReplyComments } from "../../services/comments.services"
import { StyledSectionComments } from "../../styles"
import Comment from "../Comment"
import SkeletonComment from "../Comment/skeleton"

interface props {
    idComment: string
}

const Replys = ({idComment}:props) => {
    const { data, isError, isLoading } = useQuery(["replys", idComment], () => getReplyComments(idComment), {refetchOnWindowFocus: false})


    if(isLoading){
        return(
            <StyledSectionComments>
                <div className="comments">
                    <SkeletonComment/>
                    <SkeletonComment/>
                    <SkeletonComment/>
                </div>
            </StyledSectionComments>
        )
    }

    if(!data || isError){
        return(
            <StyledSectionComments>
                <p>Ocurrio un error</p>
            </StyledSectionComments>
        )
    }

    if(data.total === 0){
        return(
            <StyledSectionComments>
                <p className="title" >Sin respuestas</p>
            </StyledSectionComments>
        )
    }

    return(
        <StyledSectionComments>
            <StyledSectionComments className="comments ">
                {data.comments.map( (item) => <Comment {...item} /> )}
            </StyledSectionComments>
        </StyledSectionComments>
    )
}

export default Replys