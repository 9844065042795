import { useQuery } from "react-query"
import { useNavigate, useParams } from "react-router-dom"
import BtnPrimary from "../../../../../../components/Buttons/BtnPrimary"
import BtnSecondary from "../../../../../../components/Buttons/BtnSecondary"
import Header from "../../../../components/Header"
import WrapperApp from "../../../../components/WrapperApp"
import { IPost } from "../../../../interfaces/post.interfaces"
import { getPostBySlug } from "../../services/post.services"
import { StyledContainerPost } from "../../styles"
import AsidePost from "./components/AsidePost"
import BodyPost from "./components/BodyPost"
import BtnDeletePost from "./components/BtnDeletePost"
import Comments from "./components/Comments"
import SkeletonPost from "./components/SkeletonPost"
import { StyledHeaderShowPost, StyledContainerComments } from "./styles"

const ShowPost = () => {
    const navigate = useNavigate();
    const { slug } = useParams();
    const { data, isError, isLoading } = useQuery<IPost | null>(`post-${slug}`, () => getPostBySlug(slug ? slug : ""), {refetchOnWindowFocus: false})

    if(isLoading){
        return(
            <WrapperApp>
                <div>
                    <Header navigation={["Publicacion", "cargando..." ]} />
                    <SkeletonPost/>
                </div>
            </WrapperApp>
        )
    }
    
    if(!slug || !data || isError) {
        return(
            <WrapperApp>
                <div>
                    ocurrio un error
                </div>
            </WrapperApp>
        )
    }

    return(
        <WrapperApp>
            <div>
                <Header navigation={["Publicacion", slug ]} />
                <StyledHeaderShowPost>
                    <BtnSecondary
                        title="Editar" 
                        handleClick={() => navigate(`/post/create/${slug.substring(slug.lastIndexOf("-") + 1)}`)}
                        color="#157effd4"
                        icon={<i className="fa-solid fa-file-pen"></i>}
                    />
                    <BtnDeletePost id={slug.substring(slug.lastIndexOf("-") + 1)}/>
                </StyledHeaderShowPost>
                <StyledContainerPost>
                    <BodyPost {...data} />
                    <AsidePost {...data} />
                </StyledContainerPost>
                <StyledContainerComments>
                    <Comments idPost={data.id.toString()} />
                </StyledContainerComments>
            </div>
        </WrapperApp>
    )
}

export default ShowPost