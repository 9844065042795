import { ApexOptions } from "apexcharts"
import ReactApexChart from "react-apexcharts"
import { useQuery } from "react-query";
import { getDataAges } from "../services/analitycs.services";
import AnalitycsLoader from "../../../../../../../components/Loaders/AnalitycsLoader";

function groupByAgeRange(objects: { age: number, total: number }[]): number[] {
    const ageGroups = [
        { range: '6-15', total: 0 },
        { range: '16-25', total: 0 },
        { range: '26-35', total: 0 },
        { range: '36-45', total: 0 },
        { range: '46+', total: 0 },
    ];

    for (const obj of objects) {
        if (obj.age >= 6 && obj.age <= 15) {
            ageGroups[0].total += obj.total;
        } else if (obj.age >= 16 && obj.age <= 25) {
            ageGroups[1].total += obj.total;
        } else if (obj.age >= 26 && obj.age <= 35) {
            ageGroups[2].total += obj.total;
        } else if (obj.age >= 36 && obj.age <= 45) {
            ageGroups[3].total += obj.total;
        } else if (obj.age >= 46) {
            ageGroups[4].total += obj.total;
        }
    }

    return ageGroups.map((group) => group.total);
}

const stateBarChart = {
    series: [{
        name: 'Inflation',
        data: [4, 3.1, 4.0, 10.1, 4.0]
    }],
    options: {
        chart: {
            height: 350,
            type: 'bar',
        },
        plotOptions: {
            bar: {
                borderRadius: 0,
                dataLabels: {
                    position: 'top', // top, center, bottom
                },
            }
        },
        dataLabels: {
            enabled: true,
            formatter: function (val: any) {
                return val;
            },
            offsetY: -20,
            style: {
                fontSize: '12px',
                colors: ["#304758"]
            }
        },

        xaxis: {
            categories: ["6 - 15 años", "16 - 25 años", "26 - 35 años", "36 - 45 años", "46 a mas años"],
            position: 'bottom',
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            crosshairs: {
                fill: {
                    type: 'gradient',
                    gradient: {
                        colorFrom: '#D8E3F0',
                        colorTo: '#BED1E6',
                        stops: [0, 100],
                        opacityFrom: 0.4,
                        opacityTo: 0.5,
                    }
                }
            },
            labels: {
                show: true // Oculta las etiquetas de los meses
            },
            tooltip: {
                enabled: true,
            }
        },
        grid: {
            show: false,
        },
        yaxis: {
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false,
            },
            labels: {
                show: false,
                formatter: function (val: any) {
                    return val;
                }
            }

        },
    },
}


interface props {
    idAds: string
    filters: { type: number, month: number }
}

const BarChart = ({ idAds, filters }: props) => {
    const { isLoading, data, isError } = useQuery(["analitycs", "bar", idAds, [filters.type, filters.month]], () => getDataAges(idAds, filters), { refetchOnWindowFocus: false })


    if (isLoading) {
        return (
            <div className="center-loading"><AnalitycsLoader /></div>
        )
    }

    if (!data || isError) {
        return (
            <p>Error</p>
        )
    }

    return (
        <ReactApexChart options={stateBarChart.options as ApexOptions} series={[
            {
                name: 'Inflation',
                data: groupByAgeRange(data)
            }
        ]} type="bar" />
    )
}

export default BarChart