import styled from "styled-components"
import { PRIMARY_COLOR } from "../../../styled-components/variables"

const StyledAnalitycsLoader = styled.div`
    width: 150px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .loading-bar {
        width: 10px;
        height: 5px;
        margin: 0 2px;
        background-color: ${PRIMARY_COLOR};
        border-radius: 5px;
        animation: loading-wave-animation 1.4s ease-in-out infinite;
    }

    .loading-bar:nth-child(2) {
        animation-delay: 0.1s;
    }

    .loading-bar:nth-child(3) {
        animation-delay: 0.2s;
    }

    .loading-bar:nth-child(4) {
        animation-delay: 0.3s;
    }

    @keyframes loading-wave-animation {
        0% {
            height: 10px;
        }

        50% {
            height: 50px;
        }

        100% {
            height: 10px;
        }
    }
`

const AnalitycsLoader = () => {
    return (
        <StyledAnalitycsLoader>
            <div className="loading-bar"></div>
            <div className="loading-bar"></div>
            <div className="loading-bar"></div>
            <div className="loading-bar"></div>
        </StyledAnalitycsLoader>
    )
}

export default AnalitycsLoader