import { useEffect, useState } from "react";
import SelectMonths from "../../components/Selects/SelectMonths";
import ChartBarViews from "./components/ChartBarView";
import { StyledViewHome, StyledViewHomeAside, StyledViewHomeBody, StyledViewHomeFilters } from "./styles";
import BoxesSummary from "./components/BoxesSummary";
import BarCategorys from "./components/BarCategorys";
import BarTags from "./components/BarTags";
import ListRecentSubscribers from "./components/ListRecentSubscribers";
import ChartBarFollowers from "./components/ChartBarFollowers";
import BoxesSummaryAds from "./components/BoxesSummaryAds";
import ChartBarViewAds from "./components/ChartBarViewAds";
import ContextAuth from "../../../../contexts/Auth.context";
import Switch from "../../components/Switch";

const ViewHome = () => {
    const { dataUser } = ContextAuth()
    const [summaryView, setSummaryView] = useState(1); // 1 publicador  2 ads
    const [filters, setFilters] = useState({
        type: 1,
        month: (new Date()).getMonth()
    });

    useEffect(() => {
        if(dataUser.data?.roles.includes("Anunciante") && !dataUser.data?.roles.includes("Publicador")){
            setSummaryView(2)
        }
    }, []);

    return (
        <StyledViewHome>
            <StyledViewHomeBody>
            { 
                    (dataUser.data?.roles.includes("Anunciante") && dataUser.data?.roles.includes("Publicador") ) &&
                    <div className="container-switch">
                        <p>Publicador</p>
                        <Switch initialValue={summaryView === 2} handleChange={(val) => setSummaryView(val ? 2 : 1)} />
                        <p>Anunciante</p>
                    </div>
                }
                <StyledViewHomeFilters>
                    <div className="filters">
                        <div className="filter-days">
                            <button
                                onClick={() => setFilters({ ...filters, type: 1 })}
                                className={filters.type === 1 ? "active" : ""}
                            >
                                Los ultimos 7 dias
                            </button>
                            <button
                                onClick={() => setFilters({ ...filters, type: 2 })}
                                className={filters.type === 2 ? "active" : ""}
                            >
                                Los ultimos 28 dias
                            </button>
                            <button
                                onClick={() => setFilters({ ...filters, type: 3 })}
                                className={filters.type === 3 ? "active" : ""}
                            >
                                Anual
                            </button>
                        </div>
                        <div className="filter-select-container">
                            {filters.type !== 3 && <SelectMonths
                                defaultValue={filters.month}
                                styleName="filter-select"
                                handleChange={(val) => setFilters({ ...filters, month: val.value })}
                            />}
                        </div>
                    </div>
                </StyledViewHomeFilters>
                {
                    (summaryView === 1 && dataUser.data?.roles.includes("Publicador") ) &&
                    <>
                        <p className="title">Interaccion de mis publicaciones</p>
                        <BoxesSummary filters={filters} />
                        <p className="subtitle">Visulizaciones de Notas/Videos</p>
                        <div style={{ width: "100%" }}>
                            <ChartBarViews filters={filters} />
                        </div>
                    </>
                }
                {
                    (summaryView === 2 && dataUser.data?.roles.includes("Anunciante") ) &&
                    <>
                        <p className="title">Interaccion de mis anuncios</p>
                        <BoxesSummaryAds filters={filters} />
                        <p className="subtitle">Visulizaciones de anuncios</p>
                        <div style={{ width: "100%" }}>
                            <ChartBarViewAds filters={filters} />
                        </div>
                    </>
                }
                <p className="subtitle">Total seguidores</p>
                <div style={{ width: "100%" }}>
                    <ChartBarFollowers filters={filters} />
                </div>
            </StyledViewHomeBody>
            <StyledViewHomeAside>
                <div>
                    <p className="subtitle">Categorias populares</p>
                    <BarCategorys />
                </div>
                <div>
                    <p className="subtitle">Etiquetas populares</p>
                    <BarTags />
                </div>
                <div>
                    <p className="subtitle">Seguidores recientes</p>
                    <ListRecentSubscribers />
                </div>
            </StyledViewHomeAside>
        </StyledViewHome>
    )
}

export default ViewHome