import { URL_PORTAL } from "../../../config";

export const FormatNumber = (num: number) => {
    num = Math.floor(num);
    if (num >= 1000000) {
        return (num / 1000000).toFixed(1) + 'M';
    } else if (num >= 1000) {
        return (num / 1000).toFixed(1) + 'k';
    } else {
        return num;
    }
}

export function validatePhoneNumber(phoneNumber: string) {
    var phoneRegex = /^\d{9}$/;
    return phoneRegex.test(phoneNumber);
}

export const createUrlPostOfWeb = (slug: string) => {
    return URL_PORTAL+"post/"+slug
}

export function convertToYMD(date: string) {
    const parts = date.split("/");
    return parts[2] + "-" + parts[1] + "-" + parts[0];
  }
  
export function isURL(str: string): boolean {
    const RGX_URL = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
  
    if (!str.match(RGX_URL)) return false
    return true
}