import { IAdsForm } from "../../../interfaces/ads.interfaces"
import { StyledPreviewAds } from "../styles"
import { useState } from "react"
import { useQuery } from "react-query"
import { getAdsLocations } from "../../../services/ads.services"
import AdsInHomeFeed, { AdsInHomeFeedMobile } from "./adsContainers/AdsInHomeFeed"
import PreviewFeed from "./previews/PreviewFeed"
import AdsInHomeAside from "./adsContainers/AdsInHomeAside"
import Preview2 from "./previews/Preview2"
import AdsInSearch, { AdsInSearchMobile } from "./adsContainers/AdsInSearch"
import Preview3 from "./previews/Preview3"

interface props {
    form: IAdsForm
}

const PreviewAds = ({ form }: props) => {
    const { isLoading, data, isError } = useQuery("ads-locations", getAdsLocations, { refetchOnWindowFocus: false })
    const [deviceView, setDeviceView] = useState(1); //1 desktop 2 mobile
    const [viewActive, setViewActive] = useState(form.advertisingSpaces.length !== 0 ? form.advertisingSpaces[0].id : 1);

    return (
        <StyledPreviewAds>
            <div className="header-previewAds">
                <div className="previews-previewAds">
                    <p>Vistas&nbsp;</p>
                    {isLoading && <select name="" />}
                    {!data || isError && <select name="" />}
                    {
                        data &&
                        <select value={viewActive} onChange={(e) => setViewActive(parseInt(e.currentTarget.value))}>
                            {
                                form.advertisingSpaces.length !== 0
                                    ? form.advertisingSpaces.map((item) => <option key={item.id} value={item.id}>{item.location} </option>)
                                    : data.map((item) => {
                                        return <option key={item.id} value={item.measure_id}>{item.location} </option>
                                    })
                            }
                        </select>
                    }
                </div>
                <div className="btns-previewAds">
                    <button
                        onClick={() => setDeviceView(1)}
                        className={`btn-previewAds ${deviceView === 1 ? "active" : ""}`}
                    >
                        <i className="fa-solid fa-display"></i>
                    </button>
                    <button
                        onClick={() => setDeviceView(2)}
                        className={`btn-previewAds ${deviceView === 2 ? "active" : ""}`}
                    >
                        <i className="fa-solid fa-mobile-screen"></i>
                    </button>
                </div>
            </div>
            <div className="container-preview">
                {
                    deviceView === 1 && 
                    <> 
                        {viewActive === 1 && <AdsInHomeAside children={<Preview2 form={form} />}/>} 
                        {viewActive === 2 && <AdsInHomeFeed children={<PreviewFeed form={form} />}/>}
                        {(viewActive >= 3 && viewActive <= 5) && <AdsInSearch children={<Preview3 form={form} />}/>}
                        {viewActive === 8 && <p>Sin vista previa</p>} 
                        {viewActive === 9 && <p>Sin vista previa</p>} 
                    </>
                }
                {
                    deviceView === 2 && 
                    <> 
                        {viewActive === 1 && <p>Sin vista previa</p>} 
                        {viewActive === 2 && <AdsInHomeFeedMobile children={<PreviewFeed form={form} />}/>} 
                        {(viewActive >= 3 && viewActive <= 5) && <AdsInSearchMobile children={<Preview3 form={form} />}/>}
                        {viewActive === 8 && <p>Sin vista previa</p>} 
                        {viewActive === 9 && <p>Sin vista previa</p>} 
                    </>
                }
            </div>
        </StyledPreviewAds>
    )
}

export default PreviewAds