import styled from "styled-components";
import { BG_COLOR, BG_CONTRAST_COLOR, PRIMARY_COLOR, SECONDARY_COLOR, TEXT_COLOR } from "../../../../../../styled-components/variables";

export const StyledMainEditProfile = styled.div`
    width: 100%;
`

export const StyledHeaderEditProfile = styled.h1`
    width: 100%;
    display: flex; 
    justify-content: space-between;
    align-items:center;
    padding: 0 1rem;
    padding-right: 8rem;
    .btn{
        width: 120px;
        font-size: 12px;
    }
`

export const StyledH1EditProfile = styled.h1`
    width: 100%;
    text-align: start;
    font-size: 20px;
    color: ${TEXT_COLOR};
`

export const StyledBodyEditProfile = styled.div`
    width: 100%;
    display: grid; 
    grid-template-columns: 1fr 2.6fr;
    gap: 5px;
    .form-img{
        min-height: 200px;
    }
`


export const StyledAsideEditProfile = styled.div`
    width: 100%;
    padding: 0 1rem;
    border-right: 2px solid ${SECONDARY_COLOR};
`

export const StyledContentEditProfile = styled.div`
    width: 100%;
    padding: 0 1rem;
    padding-right: 8rem;
    .dual-columns{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 5px;
        &-asc{
            grid-template-columns: 0.4fr 1fr;
        }
    }
    .networks-columns{
        width: 100%;
        display: grid;
        grid-template-columns: 0.4fr 1fr 0.1fr;
        justify-content:center;
        align-items: center;
        gap: 5px;
        .btn-del-net{
            color: ${PRIMARY_COLOR};
            cursor: pointer;
        }
    }
    .networks-btnAdd{
        text-align: end;
        margin: 5px 0;
        cursor: pointer;
    }
    .AdvanceTrix .ck-editor__editable{
        min-height: 400px;
    }
    .column{
        width: 100%;
    }
    .subtitle-form{
        margin: 5px 0;
        text-align: start;
        font-size: 14px;
        margin: 5px 0;
        //font-weight: bold;
        color: ${TEXT_COLOR};
    }
    .input-form{
        width: 100%;
        padding: 1rem;
        margin: 5px 0;
        border-radius: 5px;
        border: 0;
        outline: none;
        //background-color: ${BG_CONTRAST_COLOR};
        border: 2px solid  ${BG_CONTRAST_COLOR};
    }
    .dropdown{
        width: 100%;
        .Dropdown-menu{
            min-width: 180px;
            height: 100px;
            top: none;
            bottom: -260%;
            right: 0;
            border-radius: 5px;
            box-shadow: 0 4px 13px #e9e9f9;
            border: none;
            padding: 0.5rem;
            font-size: 12px;
            text-align: start;
        }
        .Dropdown-control{
            border-radius: 5px;
            border: none;
            font-size: 16px;
            background-color: ${BG_CONTRAST_COLOR};
            color: ${TEXT_COLOR};
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 10px 0;
            padding: 1rem;
            cursor: pointer;
        }
    }
`