import styled from "styled-components";

export const StyledCardCollaborator = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 32px 1fr;
    align-items: center;
    gap: 5px;
    .image{
        border-radius: 50%;
        span{
            font-size: 12px;
        }
    }
    .title{
        width: 100%;
        font-size: 12px;
        text-align: start;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
`