import { Navigate, useRoutes } from "react-router-dom";
import CreateAds from "./pages/Ads/views/create";
import EditAds from "./pages/Ads/views/create/edit";
import ShowAds from "./pages/Ads/views/show";
import Home from "./pages/Home";
import SectionPost from "./pages/Posts";
import ShowPost from "./pages/Posts/views/show";
import EditProfile from "./pages/Profile/views/edit";
import Profile from "./pages/Profile/views/show";
import SectionMainPosts from "./pages/Publications";
import AnalitycsAds from "./pages/Ads/views/analitycs";
import SectionCreateLive from "./pages/Lives/views/create";

function AppRouter() {
    const routes = [
      {
        path: "/",
        element: <Home/>,
      },
      {
        path: "/profile",
        element: <Profile/>,
      },
      {
        path: "/profile/edit",
        element: <EditProfile/>,
      },
      {
        path: "/live/create",
        element: <SectionCreateLive/>,
      },
      {
        path: "/post/create",
        element: <SectionPost/>,
      },
      {
        path: "/post/create/:id",
        element: <SectionPost/>,
      },
      {
        path: "/myposts",
        element: <SectionMainPosts/>,
      },
      {
        path: "/myposts/:slug",
        element: <ShowPost/>,
      },
      {
        path: "/ads",
        element: <ShowAds/>,
      },
      {
        path: "/ads/create",
        element: <CreateAds/>,
      },
      {
        path: "/ads/create/:id",
        element: <CreateAds/>,
      },
      {
        path: "/ads/edit/:id",
        element: <EditAds/>,
      },
      {
        path: "/ads/analytics/:id",
        element: <AnalitycsAds/>,
      },
      {
        path: "/error",
        element: <p>error</p>,
      },
      {
        path: "*",
        element: <Navigate to="/" />,
      }
    ];
  
    return useRoutes(routes);
  }
  
  export default AppRouter;