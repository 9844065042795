import { useRef, useState } from "react";
import Avatar from "react-avatar";
import ContextAuth from "../../../../../../../../../../contexts/Auth.context";
import { StyledComment } from "../../styles";
import { URL_STORAGE } from "../../../../../../../../../../config";

interface props {
    handleComment: (str: string) => void
    sendComment: () => void
    actionCloseFooter?: () => void
    comment: string
    error: string
}


const BoxAddComment = ({handleComment,sendComment,comment,error,actionCloseFooter}:props) => {
    const divTxtArea = useRef<HTMLDivElement>(null);
    const { dataUser } = ContextAuth()
    const [focus, setFocus] = useState(false);

    const closeFooter = () => {
        setFocus(false)
        actionCloseFooter && actionCloseFooter() 
        if (divTxtArea.current) {
            divTxtArea.current.textContent = "";
            handleComment("");
        }
    }

    return(
        <StyledComment>
            <div className="container">
                <Avatar
                    size="40" className="img" 
                    maxInitials={2}
                    src={dataUser.data?.image ? URL_STORAGE + dataUser.data.image : dataUser.data?.full_name} title={dataUser.data?.full_name} 
                    name={dataUser.data?.full_name} 
                />
            </div>
            <div className="container container-body">
                <p className="title"> {dataUser.data?.full_name} </p>
                <div ref={divTxtArea} className="response" onClick={() => setFocus(true)} 
                contentEditable onInput={(e) =>  handleComment(e.currentTarget.innerText)}></div>
                <textarea hidden value={comment}></textarea>
                <div className="response-footer">
                <p className="text-error"> {error} </p>
                {
                    focus &&
                    <div className="responseSubmit">
                        <button onClick={closeFooter} className="btn-secondary">Cancelar</button>
                        {
                            comment.length >= 2
                            ? <button onClick={sendComment} className="btn-primary">Comentar</button>
                            : <button className="btn btn-disabled">Comentar</button>
                        }
                    </div>
                }
                </div>
            </div>
        </StyledComment>
    )
}

export default BoxAddComment