import ReactDropdown from "react-dropdown";

const optionsSocialMedia = [
    {
        value: '0',
        label: <div>
            <i className="fa-brands fa-facebook"></i>
            &nbsp;Facebook&nbsp;
        </div>
    },
    {
        value: '1',
        label: <div>
            <i className="fa-brands fa-tiktok"></i>
            &nbsp;TikTok&nbsp;
        </div>
    },
    {
        value: '2',
        label: <div>
            <i className="fa-brands fa-twitter"></i>
            &nbsp;Twitter&nbsp;
        </div>
    },
    {
        value: '4',
        label: <div>
            <i className="fa-brands fa-instagram"></i>
            &nbsp;Instagram&nbsp;
        </div>
    },
    {
        value: '5',
        label: <div>
            <i className="fa-brands fa-youtube"></i>
            &nbsp;Youtube&nbsp;
        </div>
    },
];

interface props {
    classname ?: string
    initValue ?: string
    selectedOptions: string[]
    handleChange: (val: string) => void
}

const SelectSocialMedia = ({classname, initValue,selectedOptions,handleChange}:props) => {
    return (
        <ReactDropdown
            arrowClosed={<i className="fa-solid fa-angle-down"></i>}
            arrowOpen={<i className="fa-solid fa-angle-up"></i>}
            className={classname} 
            options={optionsSocialMedia.filter( (x) => !selectedOptions.includes(x.value))}
            onChange={(data) => handleChange(data.value)}
            value={initValue ? initValue :""}
            placeholder="Redes"
        />
    )
}

export default SelectSocialMedia