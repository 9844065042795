import Skeleton from 'react-loading-skeleton';
import { StyledCardPost } from './styles';

const SkeletonCardPost = () => {
    return (
        <StyledCardPost>
            <div className='card-ContainerImg'>
                <Skeleton width="100%" height={200}/>
            </div>
            <div className="card-subheader">
                <div className="card-littleTitle">
                    <p><Skeleton width={50} height={10}/></p>
                </div>
                <div className="card-stars">
                    <Skeleton width={20} height={10}/>
                    <Skeleton width={20} height={10}/>
                    <Skeleton width={20} height={10}/>
                    <Skeleton width={20} height={10}/>
                    <Skeleton width={20} height={10}/>
                </div>
            </div>
            <p className="card-title"><Skeleton width="100%" height={30}/></p>
            <div className="card-tags">
                <Skeleton width="90%" height={20}/>
                <Skeleton width="90%" height={20}/>
            </div>
            <div className="card-features">
                <div className="card-feature">
                    <Skeleton width={30} height={10}/>
                </div>
                <div className="card-feature">
                    <Skeleton width={30} height={10}/>
                </div>
            </div>
        </StyledCardPost>
    )
}

export default SkeletonCardPost