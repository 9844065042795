import axios from "axios";
import { getCookie } from "../../../utils/Http"
import { URL_STREAM_SERVICE } from "../../../../../config";

const header = {
    headers: {
        Authorization: `Bearer ${getCookie()}`,
        'Content-Type': 'application/json'
    }
}

export const getLiveID = async (): Promise<{stream_id: string,stream_key: string} | null> => {
    const resp = await axios.get(`${URL_STREAM_SERVICE}api/stream/live/generate`, header)
    if (resp.status === 200) return resp.data.data
    return null
}