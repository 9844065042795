import styled from "styled-components";
import { BG_COLOR, PRIMARY_COLOR, SECONDARY_COLOR, TEXT_COLOR } from "../../../../../../styled-components/variables";

export const StyledHeaderProfile = styled.div`
    width: 100%;
    border-radius: 5px;
    //background-color: #F1F5F9;
    margin: 1rem 0;
    display: grid;
    grid-template-rows: 2fr 1fr;
    box-shadow: 0 4px 13px #e9e9f9;
`

export const StyledBodyHeaderProfile = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0.5rem;
    .box-picture{
        display: grid;
        grid-template-columns: 130px 1fr;
        gap: 5px;
        justify-content: center;
        align-items: center;
        &-text{
            text-align: start;
        }
        &-title{
            font-size: 18px;
            font-weight: bold;
            margin: 0;
        }
        &-subtitle{
            margin: 5px 0;
        }
    }
    .box-rankings{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .box-ranking{
        display: flex;
        flex-direction: column;
        margin: 5px;
        &-title{
            font-size: 18px;
            font-weight: bold;
            color: ${PRIMARY_COLOR};
            text-align: center;
        }
        &-subtitle{
            font-size: 12px;
            margin: 5px 0;
        }
    }
    .box-socials{
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        grid-auto-flow: dense;
        text-align: start;
        a{
            text-decoration: none;
            color: ${TEXT_COLOR};
        }
        &-text{
            cursor: pointer;
            margin: 0;
            font-size: 12px;
        }
    }
`

export const StyledTabsHeaderProfile = styled.div`
    position: relative;
    padding: 1rem 2rem;
    display: flex;
    justify-content: flex-start;
    gap: 5px;
    align-items: center;
    list-style: none;
    border-top: 1px solid ${SECONDARY_COLOR};
    .box-tab{
        padding: 1rem;
        font-size: 14px;
        height: 100%;
        cursor: pointer;
        border-radius: 5px;
        transition: .3s background-color;
        &:hover{
            background-color: ${SECONDARY_COLOR};
        }
        &.active{
            border-bottom: 1.8px solid ${SECONDARY_COLOR};
        }
    }
`
export const StyledBtnEditProfile = styled.span`
    padding: 0.6rem;
    font-size: 14px;
    cursor: pointer;
    background-color: ${PRIMARY_COLOR};
    color: ${BG_COLOR};
    border-radius: 5px;
    position: absolute;
    right: 0;
    margin-right: 2rem;
    a{
        color: ${BG_COLOR};
        text-decoration: none;
    }
`