import Avatar from "react-avatar"
import LogoPozicion from "../../../../components/Logos/LogoPozicion"
import HandleLink from "../../containers/HandleLink"
import ContextAuth from "../../../../contexts/Auth.context"
import Skeleton from "react-loading-skeleton"
import { URL_PORTAL, URL_STORAGE } from "../../../../config"
import { StyledHeaderNav } from "./styles"
import { useState } from "react";
import IconWeb from "../../../../assets/icons/web.svg"
import IconExits from "../../../../assets/icons/exit.svg"

const HeaderNav = () => {
    const { dataUser, loading, Logout } = ContextAuth()
    const [openPopUp, setOpenPopUp] = useState(false);

    if (loading) {
        return (
            <StyledHeaderNav>
                <HandleLink href="/">
                    <LogoPozicion width={200} height={40} />
                </HandleLink>
                <div className="header-profile-skeleton">
                    <Skeleton height="100%" />
                </div>
            </StyledHeaderNav>
        )
    }


    return (
        <StyledHeaderNav>
            <HandleLink href="/">
                <LogoPozicion width={200} height={40} />
            </HandleLink>
            <div className="header-profile" onClick={() => setOpenPopUp(!openPopUp)}>
                <Avatar
                    className="header-iconProfile"
                    src={dataUser.data?.image ? URL_STORAGE + dataUser.data.image : dataUser.data?.name}
                    name={dataUser.data?.full_name}
                    alt={dataUser.data?.full_name}
                />
                {openPopUp && <ul className="header-ProfilePopUp">
                    <a href={URL_PORTAL} className="header-itemAc" >
                        <li className="header-itemPopUp">
                            <img src={IconWeb} width={16} height={16} />
                            &nbsp;Volver a Pozicion

                        </li>
                    </a>
                    <li className="header-itemPopUp topLine" onClick={Logout}>
                        <img src={IconExits} width={20} height={20} />
                        &nbsp;Cerrar sesión
                    </li>
                </ul>}
            </div>
        </StyledHeaderNav>
    )
}

export default HeaderNav