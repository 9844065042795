import styled from "styled-components";
import { BG_COLOR, BG_CONTRAST_COLOR, PRIMARY_COLOR, TEXT_COLOR } from "../../../../styled-components/variables";

export const StyledMainPosts = styled.div`
    width: 100%;
`

export const StyledHeaderPosts = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
    gap: 5px;
    .input-select{
        width: 100%;
        .Dropdown-menu{
            border-radius: 5px;
            box-shadow: 0 4px 13px #e9e9f9;
            border: none;
            padding: 0.5rem;
            font-size: 12px;
            margin: 5px 0;
            text-align: start;
        }
        .Dropdown-control{
            background-color: ${BG_CONTRAST_COLOR};
            border-radius: 5px;
            border: none;
            font-size: 14px;
            color: ${TEXT_COLOR};
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.7rem;
            cursor: pointer;
        }
    }
`

export const StyledInputPostSearch = styled.div`
    width: 100%;
    height: 100%;
    padding: 0.2rem;
    background-color: ${BG_CONTRAST_COLOR};
    border-radius: 5px;
    display: grid;
    grid-template-columns: 90% 10%;
    justify-content: center;
    align-items: center;
    color: ${TEXT_COLOR};
    .input{
        width: 100%;
        border: 0;
        outline: none;
        border-radius: 5px;
        background-color: ${BG_CONTRAST_COLOR};
    }
`

export const StyledMainBtns = styled.div`
    width: 100%;
    text-align: end;
    .btn{
        background-color: ${BG_CONTRAST_COLOR};
        padding: 0.4rem 0.5rem;
        margin: 0 5px;
        border-radius: 5px;
        border: none; 
        outline: none;
        cursor: pointer;
        i{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &.active{
            color: ${BG_COLOR};
            background-color: ${PRIMARY_COLOR};
        }
    }
`

export const StyledMainCards = styled.div`
    width: 100%;
    margin-top: 1rem;
    //display: flex;
    //justify-content: center;
    //align-items: center;
    //flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 320px)) ;
    gap: 0.5rem;
`
export const StyledMainCardsY = styled.div`
    width: 100%;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;
`