import { Networks } from "../../../../../../interfaces/profile.interfaces"
import { getIDIconOfName, getNameOfIconID } from "../../../../adapters/profile.adapters"
import SelectSocialMedia from "../SelectSocialMedia"

interface props {
    networks: Networks[]
    action: (newData: Networks[]) => void 
}

const ListInputNetworks = ({networks,action}:props) => {

    const deleteNetwork = (net: Networks) => {
        const filter = networks.filter( (x) => x.label !== net.label )
        action([...filter])
    }

    const handleNetwork = ( current: Networks ,key: string , value: string) => {
        const filter = networks.findIndex( (x) => x.label === current.label )
        const tempNetworks = [...networks];
        tempNetworks[filter] = {...tempNetworks[filter], [key]: value}
        action([...tempNetworks])
    }

    const addNetwork = () => {
        action([...networks, {value: "", label: "", url: ""}])
    }

    return (
        <>
            {networks.length === 0 && <p>Sin Redes Sociales</p>}
            {
                networks.map((net) => (
                    <div className="networks-columns">
                        <div className="column">
                            <SelectSocialMedia
                                handleChange={(val) => handleNetwork(net, "label", getNameOfIconID(val))  }
                                initValue={getIDIconOfName(net.label)}
                                classname="dropdown"
                                selectedOptions={networks.map((x) => x.label !== net.label ? getIDIconOfName(x.label) : "")}
                            />
                        </div>
                        <div className="column">
                            <input
                                value={net.url}
                                onChange={(e) => handleNetwork(net, "url", e.currentTarget.value)  }
                                className="input-form" placeholder="link"
                                type="text" name="" id=""
                            />
                        </div>
                        <div className="column">
                            <i onClick={() => deleteNetwork(net)} className="fa-solid fa-circle-xmark btn-del-net"></i>
                        </div>
                    </div>
                ))
            }
            {networks.length < 5 && <div className="column networks-btnAdd">
                <p onClick={addNetwork}><i className="fa-solid fa-plus"></i>&nbsp;Añadir</p>
            </div>
            }
        </>
    )
}

export default ListInputNetworks