import Avatar from "react-avatar"
import { IAdsForm } from "../../../../interfaces/ads.interfaces"
import ContextAuth from "../../../../../../../../contexts/Auth.context";
import { URL_STORAGE } from "../../../../../../../../config";
import { StyledPreview3 } from "./styles";

interface props {
    form: IAdsForm
}

const Preview3 = ({ form }: props) => {
    const { dataUser } = ContextAuth();

    const UrlImgINDEX = form.images.findIndex( item => item.idFormat === 2)

    return (
        <StyledPreview3>
            {UrlImgINDEX > -1
                ? <img className="preview-image" src={form.images[UrlImgINDEX].url} alt='preview' />
                : <div className="preview"></div>
            }
            <div className="container">
                <div className="account">
                    <Avatar
                        src={dataUser.data?.image ? URL_STORAGE + dataUser.data.image : dataUser.data?.name}
                        name={dataUser.data?.name} className="image-profile"
                        size="20" textSizeRatio={2} round={true}
                    />
                    <div className="account-body">
                        <div className="account-body-2">
                            <p className="account-title">{dataUser.data?.name + " " + dataUser.data?.last_name}</p>
                            <p className="account-occupation"><i className="fa-solid fa-rectangle-ad"></i>&nbsp;Anuncio</p>
                        </div>
                        <p className="title">{form.headline}</p>
                    </div>
                </div>
                <button>{form.nameCallToAccion.length > 0 ? form.nameCallToAccion : "Mas info"}&nbsp;<i className="fa-solid fa-arrow-up-right-from-square"></i></button>
            </div>
        </StyledPreview3>
    )

}

export default Preview3