import styled from "styled-components";
import { TEXT_COLOR } from "../../../../styled-components/variables";

export const StyledBoxImgPreview = styled.div`
    width: 100%;
    margin: 5px 0;
    .content-img{
        width: 100%;
        position: relative;
    }
    .img-preview{
        width: 100%;
        object-fit: cover;
        height: 200px;
        border-radius: 5px;
    }
    .drop-down-content{
        width: 100%;
        height: 100%;
        min-height: 100px;
        border: 1px dashed #ccc;
        color: ${TEXT_COLOR};
        border-width: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 10px;
        cursor: pointer;
    }
    .drop-down{
        width: 100%;
    }
    .change-img{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        background-color:transparent;
        color: transparent;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 5px;
        cursor: pointer;
        transition: all .3s;
        &:hover{
            background-color: #0000003b;
            color: white;
            //background-color: $primary-color-hover;
        }
    }
`