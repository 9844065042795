import { IProfile } from "../../../../interfaces/profile.interfaces";
import useUIRouter from "../../contexts/UIRouter.context"
import SectionAccounts from "../../sections/Account";
import SectionBiografy from "../../sections/Biografy"
import SectionChangePass from "../../sections/ChangePass";

const UIRoutes = (data:IProfile) => {    
    const { viewActive } = useUIRouter();

    const routes = [
        {
            id: 0,
            element: <SectionBiografy biografy={data.biography} />,
        },
        {
            id: 1,
            element: <SectionAccounts/>,
        },
        {
            id: 2,
            element: <SectionChangePass/>,
        },
    ]

    if(!routes[viewActive]) return routes[0].element

    return routes[viewActive].element
}

export default UIRoutes