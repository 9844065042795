import LogoPozicion from "../../Logos/LogoPozicion"
import PrimaryLoader from "../PrimaryLoader"

import styled from "styled-components"


const StyledMainLoader = styled.div`
    display: flex;
    justify-content: center; 
    align-items: center;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
`

interface props {
    title?: string
}

const MainLoader = ({title} : props) => {
    return (
        <StyledMainLoader>
            <LogoPozicion width={277} height={50} />
            {title ? <p>{title}</p> : <br />}
            <PrimaryLoader/>
        </StyledMainLoader>
    )
}

export default MainLoader