
export function generateDateList(limit: number = 7) {
    var dateList = [];
    var today = new Date();

    for (var i = 0; i < limit; i++) {
        var date = new Date(today.getTime() - i * 24 * 60 * 60 * 1000);
        var month = date.toLocaleString('es-pe', { month: "short" });
        var day = date.getDate();
        var formattedDate = month + ' ' + day;
        dateList.push(formattedDate);
    }

    return dateList.reverse();
}

export function getAbbreviatedMonths(): string[] {
    const abbreviatedMonths: string[] = [];
    const date = new Date();

    for (let month = 0; month < 12; month++) {
        date.setMonth(month);
        const abbreviatedMonth = date.toLocaleString('en', { month: 'short' });
        abbreviatedMonths.push(abbreviatedMonth);
    }

    return abbreviatedMonths;
}