import styled from "styled-components";

export const StyledInputPass = styled.div`
    width: 100%;
    position: relative;
    input{
        padding-right: 10px;
    }
    .password-visible{
        position: absolute;
        top: 25px;
        right: 10px;
        cursor: pointer;
    }
`