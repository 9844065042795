import axios from "axios";
import { URL } from "../../../../../config"
import { IProfile } from "../../../interfaces/profile.interfaces";
import { getCookie } from "../../../utils/Http";
import { IFormPublication } from "../interfaces/publications.interfaces";

const header = {
    headers:{
        Authorization: `Bearer ${getCookie()}`,
        'Content-Type': 'application/json'
    }
}

export const getMyPublications = async (form: IFormPublication) =>{
    let mod = "?"
    let query = ""
    if(form.query){
        query += `${mod}search=${form.query}`
        mod="&"
    }
    if(form.type && form.type !== 0){
        query += `${mod}post-type=${form.type}`
        mod="&"
    }
    if(form.pag && form.pag !== 0){
        query += `${mod}pag=${form.pag}`
        mod="&"
    }

    const resp = await axios.get(`${URL}api/publisher/posts/search${query}`,header)
    //const data = {"name":"Publicador","last_name":"Publicador","full_name":"Publicador Publicador","slug":"publicador-publicador-2","biography":null,"occupation":null,"contact_email":"jguku2002@hotmail.com","facebook":null,"instagram":null,"tiktok":null,"twitter":null,"youtube":null,"image":null,"countPosts":1525,"countFollowers":5,"avgRatings":2.888888888888889}
    if(resp.status === 200) return resp.data.data
    return undefined
}

export const getPostTypes = async () =>{
    const resp = await axios.get(`${URL}api/publisher/post-statuses`,header)
    //const data = {"name":"Publicador","last_name":"Publicador","full_name":"Publicador Publicador","slug":"publicador-publicador-2","biography":null,"occupation":null,"contact_email":"jguku2002@hotmail.com","facebook":null,"instagram":null,"tiktok":null,"twitter":null,"youtube":null,"image":null,"countPosts":1525,"countFollowers":5,"avgRatings":2.888888888888889}
    if(resp.status === 200) return resp.data.data
    return undefined
}