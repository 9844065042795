import IconStatusPost from "../../../../components/IconStatusPost";
import HandleLink from "../../../../containers/HandleLink";
import { IPost } from "../../../../interfaces/post.interfaces";
import { OptionSelect } from "../../../Posts/interfaces/post.interfaces";
import { StyledCardLargePost } from "./styles";

interface props {
    UrlPost: string
    UrlImage: string
    datePost: string
    score: number
    title: string
    privacy: string
    categorys: OptionSelect[]
    totalComments: number
}

const CardLargePost = ({ UrlPost, UrlImage, datePost, title, privacy, categorys, totalComments }: props) => {
    return (
        <StyledCardLargePost>
            <div>
                {
                    privacy.toLowerCase() === "público"
                        ? <div className="card-status card-status--public">
                            <i className="fa-solid fa-globe"></i>
                        </div>
                        : <div className="card-status">
                            <i className="fa-solid fa-lock"></i>
                        </div>
                }
            </div>
            <div>
                <HandleLink href={UrlPost}>
                    <img className="card-img" src={UrlImage} alt={title} />
                </HandleLink>
            </div>
            <div>
                <HandleLink href={UrlPost}>
                    <p className="card-title">{title}</p>
                </HandleLink>
                <div className="card-tags">
                    {
                        categorys.map((cat) => {
                            return <div title={cat.label} className="card-itemTag">
                                {cat.label}
                            </div>
                        })
                    }
                </div>
            </div>
            <div className="card-feature">
                <i className="fa-solid fa-eye"></i>
                &nbsp;
                300k
            </div>
            <div className="card-feature">
                <i className="fa-solid fa-message"></i>
                &nbsp;
                {totalComments}
            </div>
            <div className="card-feature">
                <p>{datePost}</p>
            </div>
        </StyledCardLargePost>
    )
}

export default CardLargePost