import LogoPozicion from "../../../../../../../../components/Logos/LogoPozicion"
import { StyledAdsInHomeFeed,StyledAdsInHomeFeedMobile } from "./styles"

interface props {
    children: JSX.Element
}

const AdsInHomeFeed = ({children}:props) => {
    return(
        <StyledAdsInHomeFeed>
            <div className="template-header">
                <LogoPozicion width={80} height={20} />
                <div className="template-search"></div>
                <div className="template-userIcon"></div>
            </div>
            <div className="template-body">
                <div className="template-aside">
                    <div className="template-containertabs">
                        <div className="template-tab"></div>
                        <div className="template-tab"></div>
                        <div className="template-tab"></div>
                    </div>
                </div>
                <div className="template-content">
                    <div className="preview">
                        {children}
                    </div>
                    <div></div>
                </div>
            </div>
        </StyledAdsInHomeFeed>
    )
}

export default AdsInHomeFeed


export const AdsInHomeFeedMobile = ({children}:props) => {
    return(
        <StyledAdsInHomeFeedMobile>
            <div className="template-header">
                <LogoPozicion width={80} height={20} />
                <div className="template-userIcon"></div>
            </div>
            <div className="template-body">
                <div className="template-content">
                    <div className="preview">
                        {children}
                    </div>
                </div>
            </div>
        </StyledAdsInHomeFeedMobile>
    )
}