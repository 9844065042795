import useComments from "../../hooks/useComments";
import { ReplyComment } from "../../services/comments.services";
import { StyledComment } from "../../styles";
import BoxAddComment from "../BoxAddComment";
import Comment from "../Comment";
import SkeletonComment from "../Comment/skeleton";

interface props {
    id: string
    closeReply: () => void
}

const ContainerReplys = ({ id,closeReply }: props) => {
    //const { dataUser } = useContext(AuthContext);
    const {handleComment, loading,comment,error,sendComment,tempComments} = useComments(id ,ReplyComment);

    //if (!dataUser.auth) return (<></>)

    if (loading) {
        return (
            <StyledComment className="flex-center">
                <SkeletonComment/>
            </StyledComment>
        )
    }

    return (
        <>
            <BoxAddComment
                handleComment={handleComment}
                actionCloseFooter={closeReply}
                sendComment={sendComment}
                comment={comment}
                error={error}
            />
            {tempComments.map((item) => <Comment {...item} />)}
        </>
    )
}

export default ContainerReplys