import LogoPozicion from "../../../../../../../../components/Logos/LogoPozicion"
import { StyledAdsInHomeAside } from "./styles"

interface props {
    children: JSX.Element
}

const AdsInHomeAside = ({ children }: props) => {
    return (
        <StyledAdsInHomeAside>
            <div className="template-header">
                <LogoPozicion width={80} height={20} />
                <div className="template-search"></div>
                <div className="template-userIcon"></div>
            </div>
            <div className="template-body">
                <div className="template-aside">
                    <div className="template-containertabs">
                        <div className="template-tab"></div>
                        <div className="template-tab"></div>
                        <div className="template-tab"></div>
                    </div>
                </div>
                <div className="template-content">
                    <div className="template-list">

                        <div className="template-item">
                            <div className="template-itemPicture"></div>
                            <div className="template-itemTitle"></div>
                            <div className="template-containerAutor">
                                <div className="template-autoricon"></div>
                                <div className="template-containerAutorBody">
                                    <div className="template-itemATitle"></div>
                                    <div className="template-itemAsubTitle"></div>
                                </div>
                            </div>
                        </div>
                        <div className="template-item">
                            <div className="template-itemPicture"></div>
                            <div className="template-itemTitle"></div>
                            <div className="template-containerAutor">
                                <div className="template-autoricon"></div>
                                <div className="template-containerAutorBody">
                                    <div className="template-itemATitle"></div>
                                    <div className="template-itemAsubTitle"></div>
                                </div>
                            </div>
                        </div>
                        <div className="template-item">
                            <div className="template-itemPicture"></div>
                            <div className="template-itemTitle"></div>
                            <div className="template-containerAutor">
                                <div className="template-autoricon"></div>
                                <div className="template-containerAutorBody">
                                    <div className="template-itemATitle"></div>
                                    <div className="template-itemAsubTitle"></div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="template-preview">
                        <div className="preview-card">
                            <div className="preview-card-header">
                                <div className="preview-card-img"></div>
                                <div className="container1">
                                    <div className="preview-card-subheader">
                                        <p className="preview-card-title"></p>
                                        <p className="preview-card-occupation"></p>
                                    </div>
                                    <div className="preview-card-datapost">
                                        <p className="preview-card-subtitle"></p>
                                    </div>
                                </div>
                            </div>
                            <div className="body">
                                <div className="preview-card-picture"></div>
                            </div>
                        </div>
                        <div className="container-ads">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </StyledAdsInHomeAside>
    )
}

export default AdsInHomeAside