import { useQuery } from "react-query"
import { IEPpost } from "../../../../interfaces/post.interfaces"
import { AdaptEPPost } from "../../../../adapters/post.adapter"
import { getMyPublications } from "../../services/publication.services"
import { StyledMainCards, StyledMainCardsY } from "../../styles"
import CardLargePost from "../CardLargePost"
import CardPost from "../CardPost"
import { IFormPublication } from "../../interfaces/publications.interfaces"
import { useEffect } from "react"
import SkeletonCardPost from "../CardPost/skeleton"
import SkeletonCardLargePost from "../CardLargePost/skeleton"

interface props {
    typeList: number
    dataForm: IFormPublication
}

const ListPosts = ({typeList,dataForm}: props) => {
    const { data, isLoading, isError,isFetching, refetch} = useQuery<IEPpost[]>("myposts", () => getMyPublications(dataForm), {refetchOnWindowFocus: false})
    
    useEffect( () => {
        refetch()
    },[dataForm.pag, dataForm.query, dataForm.type])

    if(isLoading || isFetching) {
        if(typeList === 0){
            return(
                <StyledMainCards>
                    {[1,1,1,1,1,1].map( () => typeList === 0 ? <SkeletonCardPost/> : <SkeletonCardLargePost/> )}
                </StyledMainCards>
            )
        }
        return(
            <StyledMainCardsY>
                {[1,1,1,1,1,1].map( () => typeList === 0 ? <SkeletonCardPost/> : <SkeletonCardLargePost/> )}
            </StyledMainCardsY>
        )
    }

    if(isError) {
        return(
            <StyledMainCards>
                <div>Ocurrio un error</div>
            </StyledMainCards>
        )
    }

    if(!data || data.length === 0 || isError) {
        return(
            <StyledMainCards>
                <div>Sin Resultados</div>
            </StyledMainCards>
        )
    }

    if(typeList === 0){
        return(
            <StyledMainCards>
                {
                    data.map( (post) => <CardPost {...AdaptEPPost(post)} /> )
                }
            </StyledMainCards>
        )
    }

    return(
        <StyledMainCardsY>
            {
                data.map( (post) => <CardLargePost {...AdaptEPPost(post)} /> )
            }
        </StyledMainCardsY>
    )
}

export default ListPosts