import styled from "styled-components";

export const StyledContainerModal = styled.div`
    width: 100%;
    height: 100%;
    min-height:100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #2222227d;
    z-index: 101;
    display: flex;
    justify-content: center;
    align-items: center;
`