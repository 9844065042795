import LogoPozicion from "../../../../../../../../components/Logos/LogoPozicion"
import { StyledAdsInHomeAside, StyledAdsInHomeAsideMob } from "./styles"

interface props {
    children: JSX.Element
}

const AdsInSearch = ({ children }: props) => {
    return (
        <StyledAdsInHomeAside>
            <div className="template-header">
                <LogoPozicion width={80} height={20} />
                <div className="template-search"></div>
                <div className="template-userIcon"></div>
            </div>
            <div className="template-body">
                <div className="template-aside">
                    <div className="template-containertabs">
                        <div className="template-tab"></div>
                        <div className="template-tab"></div>
                        <div className="template-tab"></div>
                    </div>
                </div>
                <div className="template-content">
                    <div className="preview-top">
                        {children}
                    </div>
                    <div className="template-list">

                        <div className="template-item">
                            <div className="template-itemPicture"></div>
                            <div className="template-itemTitle"></div>
                            <div className="template-containerAutor">
                                <div className="template-autoricon"></div>
                                <div className="template-containerAutorBody">
                                    <div className="template-itemATitle"></div>
                                    <div className="template-itemAsubTitle"></div>
                                </div>
                            </div>
                        </div>
                        <div className="template-item">
                            <div className="template-itemPicture"></div>
                            <div className="template-itemTitle"></div>
                            <div className="template-containerAutor">
                                <div className="template-autoricon"></div>
                                <div className="template-containerAutorBody">
                                    <div className="template-itemATitle"></div>
                                    <div className="template-itemAsubTitle"></div>
                                </div>
                            </div>
                        </div>
                        <div className="template-item">
                            <div className="template-itemPicture"></div>
                            <div className="template-itemTitle"></div>
                            <div className="template-containerAutor">
                                <div className="template-autoricon"></div>
                                <div className="template-containerAutorBody">
                                    <div className="template-itemATitle"></div>
                                    <div className="template-itemAsubTitle"></div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="template-list">

                        <div className="template-item">
                            <div className="template-itemPicture"></div>
                            <div className="template-itemTitle"></div>
                            <div className="template-containerAutor">
                                <div className="template-autoricon"></div>
                                <div className="template-containerAutorBody">
                                    <div className="template-itemATitle"></div>
                                    <div className="template-itemAsubTitle"></div>
                                </div>
                            </div>
                        </div>
                        <div className="template-item">
                            <div className="template-itemPicture"></div>
                            <div className="template-itemTitle"></div>
                            <div className="template-containerAutor">
                                <div className="template-autoricon"></div>
                                <div className="template-containerAutorBody">
                                    <div className="template-itemATitle"></div>
                                    <div className="template-itemAsubTitle"></div>
                                </div>
                            </div>
                        </div>
                        <div className="template-item">
                            <div className="template-itemPicture"></div>
                            <div className="template-itemTitle"></div>
                            <div className="template-containerAutor">
                                <div className="template-autoricon"></div>
                                <div className="template-containerAutorBody">
                                    <div className="template-itemATitle"></div>
                                    <div className="template-itemAsubTitle"></div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </StyledAdsInHomeAside>
    )
}

export default AdsInSearch

export const AdsInSearchMobile = ({ children }: props) => {
    return (
        <StyledAdsInHomeAsideMob>
            <div className="template-header">
                <LogoPozicion width={80} height={20} />
                <div className="template-userIcon"></div>
            </div>
            <div className="template-body">

                    <div className="preview-top">
                        {children}
                    </div>
                    <div className="template-list">

                        <div className="template-item">
                            <div className="template-itemPicture"></div>
                            <div className="template-itemTitle"></div>
                            <div className="template-containerAutor">
                                <div className="template-autoricon"></div>
                                <div className="template-containerAutorBody">
                                    <div className="template-itemATitle"></div>
                                    <div className="template-itemAsubTitle"></div>
                                </div>
                            </div>
                        </div>
                        <div className="template-item">
                            <div className="template-itemPicture"></div>
                            <div className="template-itemTitle"></div>
                            <div className="template-containerAutor">
                                <div className="template-autoricon"></div>
                                <div className="template-containerAutorBody">
                                    <div className="template-itemATitle"></div>
                                    <div className="template-itemAsubTitle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="template-list">

                        <div className="template-item">
                            <div className="template-itemPicture"></div>
                            <div className="template-itemTitle"></div>
                            <div className="template-containerAutor">
                                <div className="template-autoricon"></div>
                                <div className="template-containerAutorBody">
                                    <div className="template-itemATitle"></div>
                                    <div className="template-itemAsubTitle"></div>
                                </div>
                            </div>
                        </div>
                        <div className="template-item">
                            <div className="template-itemPicture"></div>
                            <div className="template-itemTitle"></div>
                            <div className="template-containerAutor">
                                <div className="template-autoricon"></div>
                                <div className="template-containerAutorBody">
                                    <div className="template-itemATitle"></div>
                                    <div className="template-itemAsubTitle"></div>
                                </div>
                            </div>
                        </div>
                    </div>

            </div>
        </StyledAdsInHomeAsideMob>
    )
}