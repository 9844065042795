import { IFormPost } from "../../../interfaces/post.interfaces";


function isValidYoutubeUrl(url: string) {
    var pattern = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?([a-zA-Z0-9_-]{11})/;
    return pattern.test(url);
}

export const ValidateForm = (form: IFormPost, mode: string) => {
    const errors:{[key: string]: { error: boolean, msg: string }} = {}

    if(form.title.length < 2 || form.subtitle.length > 255 ) errors["title"] = { error: true, msg: "El titulo no es valido, max 255 caracteres" }
    if(mode === "0") return errors
    if(form.subtitle.length < 2 || form.subtitle.length > 255) errors["subtitle"] = { error: true, msg: "El subtitulo no es valido, max 255 caracteres" }
    if(form.typePost === 2 && !isValidYoutubeUrl(form.linkVideo) ) errors["linkVideo"] = { error: true, msg: "El link de youtube no es valido" }
    if(form.typePost === 2 && form.bodyvideo.length > 600 ) errors["bodyvideo"] = { error: true, msg: "La descripcion del video no debe tener mas de 600 caracteres" }
    if(form.typePost === 1 && form.body.length < 100 ) errors["body"] = { error: true, msg: "Una nota debe tener minimo 100 caracteres" }
    if(form.extract.length < 2 || form.extract.length > 255 ) errors["extract"] = { error: true, msg: "El extracto no es valido, max 255 caracteres" }
    if(!form.image && !form.fileImage) errors["fileImage"] = { error: true, msg: "Tu publicacion debe tener portada" }
    if(form.status === "" ) errors["status"] = { error: true, msg: "Selecciona un estado" }
    if(!form.categories || form.categories?.length === 0 ) errors["categories"] = { error: true, msg: "Selecciona minimo una categoria" }
    if(!form.tags || form.tags?.length === 0 ) errors["tags"] = { error: true, msg: "Selecciona minimo una etiqueta" }
    return errors
}