import { URL_STORAGE } from "../../../../../config/index";
import { IProfile,IEPProfile, IAccount, IEPAccount, IEditProfile, IEPEditProfile } from "../../../interfaces/profile.interfaces";
import { convertToYMD } from "../../../utils/Format";


export const AdaptEPProfile = (ep: IEPProfile):IProfile => {
    const data = {
        name:  ep.name,
        lastName:   ep.last_name,
        fullName:   ep.full_name,
        slug:  ep.slug,
        biography: ep.biography,
        occupation: ep.occupation,
        networks: ep.networks || [],
        email: ep.contact_email,
        image: ep.image ? URL_STORAGE + ep.image : "",
        totalPosts:  ep.countPosts,
        totalFollowers: ep.countFollowers,
        avgRating: ep.avgRatings,
    }

    return data;
}


export const getIconOfName = (name: string) => {
    const networks: {[key: string]: string} = {
        "email": "fa-regular fa-envelope",
        "instagram": "fa-brands fa-instagram",
        "twitter": "fa-brands fa-twitter",
        "facebook": "fa-brands fa-facebook",
        "youtube": "fa-brands fa-youtube",
        "tiktok": "fa-brands fa-tiktok"
    }
    return networks[name]
}

export const getIDIconOfName = (name: string) => {
    const networks: {[key: string]: string} = {
        "instagram":"4",
        "twitter": "2",
        "facebook": "0",
        "youtube": "5",
        "tiktok":"1"
    }
    return networks[name]
}

export const getNameOfIconID = (name: string) => {
    const networks: {[key: string]: string} = {
        "4": "instagram",
        "2": "twitter",
        "0": "facebook",
        "5": "youtube",
        "1": "tiktok"
    }
    return networks[name]
}

export const AdaptEPEditProfile = (ep: IEPEditProfile):IEditProfile => {
    const data = {
        name:  ep.name,
        lastName:   ep.last_name,
        fullName:   ep.full_name,
        slug:  ep.slug,
        biography: ep.biography,
        occupation: ep.occupation || "",
        email: ep.contact_email,
        networks: ep.networks,
        image: ep.image ? URL_STORAGE + ep.image : "",
        documentNumber:     ep.document_number,
        documentType:     ep.document_type,
        country:     ep.country.id.toString(),
        birthDate:     convertToYMD(ep.birth_date),
        gender:  ep.gender.id.toString(),
        phone: ep.phone,
        website: ep.website
    }

    return data;
}


export const AdaptEPAccount = (ep: IEPAccount):IAccount => {
    const data = {
        name:           ep.name,
        lastName:       ep.last_name,
        fullName:       ep.full_name,
        slug:            ep.slug,
        email:       ep.email,
        typeDocument:      ep.document_type,
        numberDocument:   ep.document_number,
        phone:        ep.phone,
        birthDate:       ep.birth_date,
        gender:          ep.gender,
        country:         ep.country,
        categories:   ep.categories
    }

    return data;
}