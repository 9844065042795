import { useState } from "react"
import BtnPrimary from "../../../../../../components/Buttons/BtnPrimary"
import InputPassword from "../../../../../../components/InputPassword"
import PrimaryLoader from "../../../../../../components/Loaders/PrimaryLoader"
import { changePassword } from "../../services/profile.services"
import { StyledContentEditProfile } from "../../views/edit/styles"
import { StyledChangePass } from "./styles"

const SectionChangePass = ({}) => {
    const [form, setForm] = useState({
        currentPass: "",
        newPass: ""
    })
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)

    const handleForm = (e: React.ChangeEvent<HTMLInputElement>) => {
        setForm({...form, [e.currentTarget.name]: e.currentTarget.value})
    }

    const submit = () => {
        setLoading(true)
        changePassword(form)
            .then( () => setSuccess(true))
            .catch( (e) => console.error(e) )
            .finally( () => setLoading(false) )
    }

    
    if(loading){
        return(
            <StyledChangePass>
                <StyledContentEditProfile>
                    <PrimaryLoader/>
                    <p>Actualizando Contraseña</p>
                </StyledContentEditProfile>
            </StyledChangePass>
        )
    }

    if(success){
        return(
            <StyledChangePass>
                <StyledContentEditProfile>
                    <i className="fa-solid fa-circle-check fa-4x"></i>
                    <p>Contraseña Actualizada</p>
                </StyledContentEditProfile>
            </StyledChangePass>
        )
    }

    return (
        <StyledChangePass>
            <StyledContentEditProfile>
                <div className="column">
                    <p className="subtitle-form">Contraseña Actual</p>
                    <InputPassword 
                        name="currentPass" 
                        handleChange={handleForm} 
                        placeholder=""
                        value={form.currentPass}
                        style="input-form"
                    />
                </div>
                <div className="column">
                    <p className="subtitle-form">Nueva Contraseña</p>
                    <InputPassword 
                        name="newPass" 
                        handleChange={handleForm} 
                        placeholder=""
                        value={form.newPass}
                        style="input-form"
                    />
                </div>
                <div>&nbsp;</div>
                <div className="dual-columns">
                    <div>&nbsp;</div>
                    <BtnPrimary style="btn" title="Actualizar" handleClick={submit} />
                </div>
            </StyledContentEditProfile>
        </StyledChangePass>
    )
}

export default SectionChangePass