import IconStatusPost from '../../../../components/IconStatusPost';
import ListStars from '../../../../components/ListStars';
import HandleLink from '../../../../containers/HandleLink';
import { OptionSelect } from '../../../Posts/interfaces/post.interfaces';
import { StyledCardPost } from './styles';

interface props {
    UrlPost: string
    UrlImage: string
    datePost: string
    score: number
    title: string
    privacy: string
    categorys: OptionSelect[]
    totalComments: number
}

const CardPost = ({ UrlPost, UrlImage, datePost, score, title, privacy, categorys, totalComments }: props) => {

    return (
        <StyledCardPost>
            <HandleLink href={UrlPost}>
                <div className='card-ContainerImg'>
                    <img className="card-img" src={UrlImage} alt={title} />
                    <IconStatusPost classname='card-status' statusPost={privacy} />
                </div>
            </HandleLink>
            <div className="card-subheader">
                <div className="card-littleTitle">
                    <p>{datePost}</p>
                </div>
                <div className="card-features">
                    <div className="card-feature">
                        <i className="fa-solid fa-eye"></i>
                        &nbsp;
                        300k
                    </div>
                    <div className="card-feature">
                        <i className="fa-solid fa-message"></i>
                        &nbsp;
                        {totalComments}
                    </div>
                </div>
            </div>
            <HandleLink href={UrlPost}>
                <p className="card-title">{title}</p>
            </HandleLink>
            <div className="card-tags">
                {
                    categorys.map((cat) => {
                        return <div title={cat.label} className="card-itemTag">
                            {cat.label}
                        </div>
                    })
                }
            </div>
        </StyledCardPost>
    )
}

export default CardPost