import styled from "styled-components";
import { BG_COLOR, BG_CONTRAST_COLOR, PRIMARY_COLOR, TEXT_COLOR } from "../../../../../../styled-components/variables";

export const StyledHeaderShowPost = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    button{
        width: 150px;
        height: 40px;
        margin: 0 5px ;
        font-size: 12px;
    }
`
export const StyledContainerComments = styled.div`
    width: 100%;
    max-width: 750px;
`
