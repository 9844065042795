import Skeleton from "react-loading-skeleton"
import Header from "../../../../components/Header"
import { StyledAsideEditProfile, StyledBodyEditProfile, StyledContentEditProfile, StyledH1EditProfile, StyledHeaderEditProfile, StyledMainEditProfile } from "./styles"

const SkeletonEditProfile = () => {
    return (
        <StyledMainEditProfile>
            <Header navigation={["Perfil", "Editar..."]} />
            <StyledHeaderEditProfile>
                <StyledH1EditProfile><Skeleton width={200} height={30} /></StyledH1EditProfile>
                <div className="btn"><Skeleton width={100} height={30}/></div>
            </StyledHeaderEditProfile>
            <StyledBodyEditProfile>
                <StyledAsideEditProfile>
                    <div>
                        <Skeleton width="100%" height={200}/>
                    </div>
                </StyledAsideEditProfile>
                <StyledContentEditProfile>
                    <div className="dual-columns">
                        <div className="column">
                            <p className="subtitle-form"><Skeleton width={80} height={20} /></p>
                            <Skeleton width="100%" height={50} />
                        </div>
                        <div className="column">
                            <p className="subtitle-form"><Skeleton width={80} height={20} /></p>
                            <Skeleton width="100%" height={50} />
                        </div>
                    </div>
                    <div className="column">
                        <p className="subtitle-form"><Skeleton width={80} height={20} /></p>
                        <Skeleton width="100%" height={50} />
                    </div>
                    <div className="dual-columns dual-columns-asc">
                        <div className="column">
                            <p className="subtitle-form"><Skeleton width={80} height={20} /></p>
                            <Skeleton width="100%" height={50} />
                        </div>
                        <div className="column">
                            <p className="subtitle-form">&nbsp;</p>
                            
                            <Skeleton width="100%" height={50} />
                        </div>
                    </div>
                    <div className="dual-columns">
                        <div className="column">
                            <p className="subtitle-form"><Skeleton width={80} height={20} /></p>
                            <Skeleton width="100%" height={50} />
                        </div>
                        <div className="column">
                            <p className="subtitle-form"><Skeleton width={80} height={20} /></p>
                            <Skeleton width="100%" height={50} />
                        </div>
                    </div>
                    <div className="dual-columns">
                        <div className="column">
                            <p className="subtitle-form"><Skeleton width={80} height={20} /></p>
                            <Skeleton width="100%" height={50} />
                        </div>
                        <div className="column">
                            <p className="subtitle-form"><Skeleton width={80} height={20} /></p>
                            <Skeleton width="100%" height={50} />
                        </div>
                    </div>
                    <div className="column">
                        <p className="subtitle-form"><Skeleton width={80} height={20} /></p>
                        <Skeleton width="100%" height={400}/>
                    </div>
                </StyledContentEditProfile>
            </StyledBodyEditProfile>
        </StyledMainEditProfile>
    )
}

export default SkeletonEditProfile