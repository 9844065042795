import Avatar from "react-avatar"
import { IAdsForm } from "../../../../interfaces/ads.interfaces"
import ContextAuth from "../../../../../../../../contexts/Auth.context";
import { URL_STORAGE } from "../../../../../../../../config";
import { StyledPreviewFeed } from "./styles";

interface props {
    form: IAdsForm
}

const PreviewFeed = ({ form }: props) => {
    const { dataUser } = ContextAuth();

    const UrlImgINDEX = form.images.findIndex( item => item.idFormat === 3)

    return(
        <StyledPreviewFeed>
            <div className="header">
                <Avatar
                    src={dataUser.data?.image ? URL_STORAGE + dataUser.data.image : dataUser.data?.name}
                    name={dataUser.data?.name} className="image-profile"
                    size="30" textSizeRatio={2} round={true}
                />
                <div className="container1">
                    <div className="subheader">
                        <p className="title">{dataUser.data?.name + " " + dataUser.data?.last_name}</p>
                        <p className="occupation"><i className="fa-solid fa-rectangle-ad"></i>&nbsp;Anuncio</p>
                    </div>
                    <div className="datapost">
                        <p className="subtitle">
                            {form.description.length > 90 ? form.description.slice(0, 70) + "..." : form.description}&nbsp;
                            {form.description.length > 90 && <a className="highlight" href="#" target="_blank">Leer Más</a>}
                        </p>
                    </div>
                </div>
            </div>
            <div className="body">
                {UrlImgINDEX > -1
                    ? <img className="preview-image" src={form.images[UrlImgINDEX].url} alt='preview' />
                    : <div className="preview"></div>
                }
            </div>
            <div className="footer">
                <p>{form.headline}</p>
                <button>{form.nameCallToAccion.length > 0 ? form.nameCallToAccion : "Mas info"}&nbsp;<i className="fa-solid fa-arrow-up-right-from-square"></i></button>
            </div>
        </StyledPreviewFeed>
    )

}

export default PreviewFeed