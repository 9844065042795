import styled from "styled-components";
import { PRIMARY_COLOR, BG_CONTRAST_COLOR } from "../../../../styled-components/variables";

export const StyledNoAccess = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const StyledBoxNoAccess = styled.div`
    width: 100%;
    max-width: 400px;
    padding: 1rem;
    .logo{
        cursor: pointer;
    }
    .login-btn{
        max-width: 180px;
    }
    .login-title{
        font-size: 20px;
        font-weight: bold;
        font-style: normal;
        margin: 5px 0;
    }
`