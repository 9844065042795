import styled from "styled-components";
import { BG_COLOR, PRIMARY_COLOR_2, PRIMARY_COLOR_2_HOVER, TEXT_COLOR } from "../../../../styled-components/variables";

export const StyledViewHome = styled.div`
    width: 100%;
    display: grid; 
    grid-template-columns: 70% 30%;
    gap: 1rem;
`
export const StyledViewHomeAside = styled.div`
    width: 100%;
    padding: 1rem;
    .subtitle{
        width: 100%;
        margin: 0;
        text-align: start;
        font-weight: bold;
        font-size: 14px;
    }
    .list-lastsubs{
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 1rem 0;
    }
    .cardSubscriber {
        width: 100%;
        display: grid;
        grid-template-columns: 32px 1fr;
        align-items: center;
        gap: 5px;
        .image{
            border-radius: 50%;
            span{
                font-size: 12px;
            }
        }
        .title{
            width: 100%;
            font-size: 12px;
            text-align: start;
            margin: 5px 0;
            font-weight: 600;
        }
        .subt{
            width: 100%;
            font-size: 12px;
            text-align: start;
            margin: 5px 0;
        }
    }
`

export const StyledViewHomeBody = styled.div`
    width: 100%;
    .title{
        width: 100%;
        text-align: start;
        margin: 5px 0;
        font-size: 20px;
        font-weight: bold;
    }
    .subtitle{
        width: 100%;
        text-align: start;
        font-weight: bold;
        font-size: 14px;
    }
    .filters{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .container-boxs-info{
        width: 100%;
        display: grid; 
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        gap: 5px;
    }
    .center-loading{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 200px;
    }
    .box-info{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 1rem;
        background-color: ${PRIMARY_COLOR_2_HOVER};
        color: ${PRIMARY_COLOR_2};
        border-radius: 10px;
        font-size: 12px;
        cursor: pointer;
        &-title{
            margin: 5px 0;
        }
        &-data{
            font-weight: bold;
            font-size: 18px;
            margin: 5px 0;
        }
    }
    .container-switch{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 5px;
        font-size: 12px;
    }
`
export const StyledViewHomeFilters = styled.div`
    width: 100%;
    margin: 1rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .filter-days{
        display: flex;
        gap: 5px;
        button{
            background-color: ${PRIMARY_COLOR_2_HOVER};
            color: ${PRIMARY_COLOR_2};
            border: none;
            outline: none;
            border-radius: 10px;
            padding: 5px 10px;
            font-size: 12px;
            cursor: pointer;
            &.active{
                background-color: ${PRIMARY_COLOR_2};
                color: ${BG_COLOR};
            }
        }
    }
    .filter-select-container{
        width: 100%;
        max-width: 140px;
    }
    .filter-select{
        background-color: ${PRIMARY_COLOR_2_HOVER};
        color: ${PRIMARY_COLOR_2};
        font-size: 12px;
        z-index: 15;
        width: 100%;
        .Dropdown-menu{
            border-radius: 5px;
            box-shadow: 0 4px 13px #e9e9f9;
            border: none;
            padding: 0.5rem;
            font-size: 12px;
            margin: 5px 0;
            text-align: start;
        }
        .Dropdown-control{
            border-radius: 5px;
            margin: 5px 0;
            border: none;
            font-size: 14px;
            color: ${TEXT_COLOR};
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.7rem;
            cursor: pointer;
        }
    }
`
