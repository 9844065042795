import { useParams } from "react-router-dom";
import WrapperApp from "../../../../components/WrapperApp";
import Header from "../../../../components/Header";
import ViewAds from "./view";
import { useQuery } from "react-query";
import { getDetailAds } from "../../services/ads.services";
import AnalitycsLoader from "../../../../../../components/Loaders/AnalitycsLoader";
import { StyledViewAdsContainer } from "./styles";

const AnalitycsAds = () => {
    const query = useParams();
    const { isLoading, data, isError } = useQuery(["myads", query.id], () => getDetailAds(query.id || ""), { refetchOnWindowFocus: false })


    if (isLoading) {
        return (
            <WrapperApp>
                <div>
                    <Header navigation={["Anuncios", "Estadisticas"]} />
                    <StyledViewAdsContainer>
                        <AnalitycsLoader/>
                        <p>Procesando Informacion</p>
                    </StyledViewAdsContainer>
                </div>
            </WrapperApp>
        )
    }

    if (!data || isError || !query.id) {
        return (
            <WrapperApp>
                <div>
                    <Header navigation={["Anuncios", "Estadisticas"]} />
                    <StyledViewAdsContainer>
                        <p>Ocurrio un error</p>
                    </StyledViewAdsContainer>
                </div>
            </WrapperApp>
        )
    }


    return (
        <WrapperApp>
            <div>
                <Header navigation={["Anuncios", "Estadisticas"]} />
                <StyledViewAdsContainer>
                    <p>Anuncio: {data.title} - {query.id || ""}</p>
                    <ViewAds idAds={query.id} />
                </StyledViewAdsContainer>
            </div>
        </WrapperApp>
    )
}

export default AnalitycsAds