import { useState } from "react"
import { useQueryClient } from "react-query"
import { useNavigate, useParams } from "react-router-dom"
import BtnPrimary from "../../../../../../../components/Buttons/BtnPrimary"
import PrimaryLoader from "../../../../../../../components/Loaders/PrimaryLoader"
import { IAdsForm } from "../../../interfaces/ads.interfaces"
import { createAds, getAdsLocations } from "../../../services/ads.services"
import PreviewAds from "../components/PreviewAds"
import { StyledSectionConfFinal } from "../styles"

interface props {
    prevPage: () => void
    form: IAdsForm
    isEdit ?: boolean
}

const SectionFinal = ({form,prevPage, isEdit = false}:props) => {
    const navigate = useNavigate();
    const query = useParams();
    const queryClient = useQueryClient();
    const [loading, setLoading] = useState(false);

    const submitForm = async () => {
        setLoading(true)
        const locations = await getAdsLocations()
        const formData = new FormData();
        const formSpace = form.advertisingSpaces.length === 0 ? locations : form.advertisingSpaces
        const ids = formSpace.reduce((acc: number[], obj) => {
            if (!acc.includes(obj.id)) acc.push(obj.id);
            return acc;
        }, []);
        const measureIDS = formSpace.reduce((acc: number[], obj) => {
            if (!acc.includes(obj.measure_id)) acc.push(obj.measure_id);
            return acc;
        }, []);
        formData.append("title", form.title);
        formData.append("startDate", form.startDate);
        formData.append("endDate", form.endDate);
        formData.append("hourStart", form.hourStart);
        formData.append("hourEnd", form.hourEnd);
        formData.append("advertisingSpaces", ids.length === 0 ? JSON.stringify(locations.map( x => x.id))  : JSON.stringify(ids)); 
        formData.append("description",form.description);
        formData.append("finalURL",form.finalURL);
        formData.append("headline",form.headline);
        formData.append("nameCallToAccion",form.nameCallToAccion);
        formData.append("filterGender",JSON.stringify(form.filterGender));
        formData.append("filterAge",JSON.stringify(form.filterAge));
        formData.append("filterTags",JSON.stringify(form.filterTags));
        formData.append("filterCategorys",JSON.stringify(form.filterCategorys));
        formData.append("images",JSON.stringify(form.images.filter( it => measureIDS.includes(it.idFormat) )));
        await createAds(formData,query.id)
            .then( () => {
                queryClient.refetchQueries("myads")
                navigate(`/ads`)
            } )
            .finally( () => setLoading(false) )
    }

    if(loading){
        return(
            <StyledSectionConfFinal>
                <div className="menu-loader">
                    <PrimaryLoader/>
                    <br />
                    Enviando
                </div>
            </StyledSectionConfFinal>
        )
    }

    return(
        <StyledSectionConfFinal>
            <PreviewAds form={form}/>
            <div className="footer-form">
                <BtnPrimary style="button-prev" title="REGRESAR" handleClick={prevPage}/>
                <BtnPrimary style="button" title={isEdit ? "EDITAR" : "FINALIZAR"} handleClick={submitForm}/>
            </div>
        </StyledSectionConfFinal>
    )
}

export default SectionFinal