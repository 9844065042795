import IconStatusPost from "../../../../../../components/IconStatusPost"
import ListStars from "../../../../../../components/ListStars"
import { IPost } from "../../../../../../interfaces/post.interfaces"
import { createUrlPostOfWeb } from "../../../../../../utils/Format"
import DOMPurify from 'isomorphic-dompurify';
import { StyleBodyPost, StyleDescriptionBodyPost, StyledSubHeaderBodyPost, StyleHeaderBodyPost, StyleIMGBodyPost, StyleSubtitleBodyPost, StyleTitleBodyPost } from "./styles"

const BodyPost = ({ title, subtitle, body, privacy, score, categorys, updated_at, UrlImage, video, slug }: IPost) => {
    return (
        <StyleBodyPost>
            <StyleHeaderBodyPost>
                <IconStatusPost statusPost={privacy} />
                <div></div>
            </StyleHeaderBodyPost>
            <StyleTitleBodyPost>
                <h1>{title}</h1>
                <a href={createUrlPostOfWeb(slug)} target="_blank">
                    <i className="fa-solid fa-arrow-up-right-from-square"></i>
                </a>
            </StyleTitleBodyPost>
            <StyleSubtitleBodyPost>{subtitle}</StyleSubtitleBodyPost>
            {
                video
                    ? <iframe
                        width="100%" height="400"
                        src={`https://www.youtube.com/embed/${video.split("?v=").length > 1 && video.split("?v=")[1]}`}
                        title={title}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                    : <StyleIMGBodyPost src={UrlImage} alt={title} />
            }
            <StyledSubHeaderBodyPost>
                <p className="active">{new Date(updated_at).toLocaleDateString("es-ES", { year: 'numeric', month: 'short', day: 'numeric' })}</p>
                {categorys.map((item) => <p>&nbsp;•&nbsp;{item.label}</p>)}
            </StyledSubHeaderBodyPost>
            {
                body
                    ? <StyleDescriptionBodyPost dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(body)
                    }} />
                    : <p>No tiene contenido</p>
            }
        </StyleBodyPost>
    )
}

export default BodyPost