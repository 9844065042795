import DataTable from "react-data-table-component"
import BtnPrimary from "../../../../../../components/Buttons/BtnPrimary"
import HandleLink from "../../../../containers/HandleLink"
import { IAds } from "../../interfaces/ads.interfaces"
import { StyledShowAds, StyledShowAdsHeader } from "./styles"

const cols = [

    {
        name: "ID",
        selector: (row: any) => row.id
    },
    {
        name: "TITULO",
        selector: (row: any) => row.title
    },
    {
        name: "PERIODO",
        selector: (row: any) => {
            return (
                <div className="row-date">
                    <p>{new Date(row.startDate).toLocaleDateString()}</p>
                    <p>{new Date(row.endDate).toLocaleTimeString()}</p>
                </div>
            )
        }
    },
    {
        name: "ESTADO",
        selector: (row: any) => row.status
    },
    {
        name: "VIEWS",
        selector: (row: any) => row.views
    },
    {
        name: "CLICKS",
        selector: (row: any) => row.clicks
    },
    {
        name: "",
        selector: (row: any) => {
            return (
                <div className="item-btn">
                    <HandleLink href={`/ads/analytics/${row.id}`} >
                        <><i className="fa-solid fa-chart-line"></i></>
                    </HandleLink>
                </div>
            )
        }
    },
    {
        name: "",
        selector: (row: any) => {
            return (
                <div className="item-btn">
                    <HandleLink href={`/ads/edit/${row.id}`} >
                        <><i className="fa-solid fa-pen-to-square"></i></>
                    </HandleLink>
                </div>
            )
        }
    }
]

interface props {
    data: IAds[]
}

const ViewAds = ({ data }: props) => {
    return (
        <StyledShowAds>
            <StyledShowAdsHeader>
                <HandleLink href={`/ads/create`} >
                    <BtnPrimary style="btn" handleClick={() => { }} title="+ Crear Anuncio" />
                </HandleLink>
            </StyledShowAdsHeader>
            <DataTable
                className="table"
                columns={cols}
                data={data}
                responsive
                pagination
                noDataComponent={<>No hay anuncios por mostrar</>}
            />
        </StyledShowAds>
    )
}

export default ViewAds