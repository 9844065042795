import { useState } from "react";
import { StyledBoxImgUpload } from "./styles";
import { uploadImage } from "../../pages/Ads/services/ads.services";

interface props {
    name: string
    urlFileDefault?: string
    classname?: string
    action: (file: string, url: string) => void
    initPlaceholder?: string
    endPlaceHolder?: string
}

const BoxUploadImg = ({ name, classname, urlFileDefault, action, initPlaceholder, endPlaceHolder }: props) => {
    const [file, setFile] = useState(urlFileDefault);
    const [uploadingImg, setUploadingImg] = useState(false);
    const [error, setError] = useState(false);

    const uploadSingleFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) return;
        setFile(URL.createObjectURL(e.target.files[0]))
        setUploadingImg(true)
        const formData = new FormData();
        formData.append("file",e.target.files[0]);

        uploadImage(formData)
            .then( (data) => {
                action(data.id, data.url)
            } )
            .catch( () => setError(true) )
            .finally( () => setUploadingImg(false) )
        //upload
    }

    return (
        <StyledBoxImgUpload>
            {file
                ? <div className="content-img">
                    <img className="img-preview" src={file} alt='preview' />
                    {uploadingImg && <span className="load-img">Uploading...</span>}
                    {error && 
                        <span className="load-img err">
                            <i className="fa-solid fa-xmark fa-4x"></i>
                            <br />
                            Ocurrio un error
                        </span>
                    }
                    {!uploadingImg && <label className="change-img" htmlFor={name}>
                        <i className="fa-regular fa-image fa-3x"></i>
                        &nbsp;{endPlaceHolder ? endPlaceHolder : "Cambiar portada"}
                    </label>}
                </div>
                : <label htmlFor={name} className={`drop-down-content ${classname}`}>
                    <i className="fa-regular fa-image fa-2x"></i>
                    {initPlaceholder ? initPlaceholder : "Subir portada"}
                </label>
            }

            <input
                className="drop-down" hidden type="file"
                accept="image/*" name={name} onChange={uploadSingleFile} id={name}
            />
        </StyledBoxImgUpload>
    )
}

export default BoxUploadImg