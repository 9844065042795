import styled from "styled-components";
import { BG_COLOR, PRIMARY_COLOR } from "../../../../styled-components/variables";

export const StyledWrapperApp = styled.div`
    width: 100%;
    height: 100%;
    //background-color: ${PRIMARY_COLOR};
`

export const StyledBodyWrapperApp = styled.div`
    width: 100%;
    min-height: 100vh;
    //padding: 10px;
    padding-left: 240px;
    //background-color: ${PRIMARY_COLOR};
`

export const StyledContent = styled.div`
    width: 100%;
    min-height: 100vh;
    padding: 10px;
    border-radius: 1rem;
    background-color: ${BG_COLOR};
    padding: 1rem;
    padding-top: 80px;
`
