import { useEffect, useState } from "react";
import { useQuery } from "react-query";

interface childProps<T> {
    isLoading: boolean
    data: T | undefined
    isError: boolean
}

interface props<T> {
    name: string
    service: () => Promise<T>
    children: ({ isLoading, data, isError }: childProps<T>) => JSX.Element
}

const ContainerGetData = <T>({ name, children, service }: props<T>) => {
    const { isLoading, isError, data } = useQuery(name, service, { refetchOnWindowFocus: false })

    return children({ isLoading, data, isError });
};

export default ContainerGetData;