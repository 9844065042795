import styled from "styled-components";

export const StyledCardStatus = styled.div`
    max-width: 100px;
    margin: 5px;
    padding: 5px 10px;
    font-size: 0.8rem;
    color: white;
    border-radius: 5px;
    background-color: #a2a2a2d4;
    &.public{
        background-color: #157effd4;
    }
`