import ReactDropdown from "react-dropdown";
import { useQuery } from "react-query";
import { getGenders } from "../../../../../../services/app.services";

interface props {
    classname ?: string
    initValue?: string
    action: (newVal: string) => void 
}

const SelectGender = ({classname, initValue, action}:props) => {

    const {isLoading, data, isError} =  useQuery("genders",getGenders, {refetchOnWindowFocus: false})

    const options = data ? data.map( (item) => {
        return {
            value: item.id.toString(),
            label: <div>
                &nbsp;{item.name}&nbsp;
            </div>
        } 
    } ) : []

    return (
        <ReactDropdown
            disabled={isLoading || isError}
            arrowClosed={<i className="fa-solid fa-angle-down"></i>}
            arrowOpen={<i className="fa-solid fa-angle-up"></i>}
            className={classname} options={options}
            onChange={(data) => action(data.value)}
            value={initValue ? options.find(x => x.value === initValue) :""}
            placeholder="Genero"
        />
    )
}

export default SelectGender