import InputPassword from "../../../../../../components/InputPassword";
import { URL_STREAM_CLIENT, URL_STREAM_OBS } from "../../../../../../config";
import Header from "../../../../components/Header"
import { StyledBodyCreateLive, StyledBodyCredencialsLive, StyledCreateLive } from "./styles"
import ReactPlayer from 'react-player';

interface props {
    id_live: string
    id_secret: string
}

const ViewCreateLive = ({id_live, id_secret}:props) => {
    return (
        <StyledCreateLive>
            <Header navigation={["En vivo", "Crear"]} />
            <StyledBodyCreateLive>
                <div>
                    <ReactPlayer
                        url={`${URL_STREAM_CLIENT}live${id_live}/${id_secret}.flv`}
                        width="100%"
                        height="400px"
                        controls // Mostrar controles de reproducción (pausa, reproducción, etc.)
                    />
                    <StyledBodyCredencialsLive>
                        <p className="title">Credenciales de transmision</p>
                        <p className='input-title'>Stream URL</p>
                        <input 
                            //onChange={(e) => handleForm("title", e.currentTarget.value )}
                            className='input' value={URL_STREAM_OBS+id_live} 
                            type="text" placeholder="Titulo" readOnly
                        />
                        <p className='input-title'>Stream KEY</p>
                        <InputPassword 
                            handleChange={() => {}} name="key"
                            style='input' value={id_secret} 
                            placeholder="key" readOnly={true}
                        />
                    </StyledBodyCredencialsLive>
                </div>
                <div>aside</div>
            </StyledBodyCreateLive>
        </StyledCreateLive>
    )
}

export default ViewCreateLive