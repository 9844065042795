import styled from "styled-components";
import { BG_CONTRAST_COLOR, PRIMARY_COLOR, SECONDARY_COLOR } from "../../../../../../styled-components/variables";

export const StyledCardPost = styled.div`
 //width: 100%;
    //width: 300px;
    width: 100%;
    height: 320px;
    //box-shadow: 0 4px 9px #e9e9f9;
    border-radius: 5px;
    //padding: 1rem;
    position: relative;
    //background-color: ${BG_CONTRAST_COLOR};
    cursor: pointer;
    transition: all 0.2s;
    a{
        text-decoration: none;
    }
    &:hover{
        transform: scale(1.02);
        //box-shadow: 0 4px 13px #d3d3d3;
    }
    .card-ContainerImg{
        position: relative;
    }
    .card-img{
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 5px;
    }
    .card-subheader{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p{
            font-size: 0.5rem;
            color: black;
            margin: 0;
        }
    }
    .card-stars{
        display: flex;
        justify-content: flex-end;
        color: ${SECONDARY_COLOR};
        &.active{
            color: ${PRIMARY_COLOR};
        }
        p{
            font-size: 0.5rem;
            color: black;
            margin: 0;
        }
    }
    .card-title{
        margin: 10px 0;
        margin-top: 2px;
        font-weight: bolder;
        text-transform: uppercase;
        color: black;
        text-align: start;
    }
    .card-features{
        width: 100%;
        padding: 5px;
        display: flex;
        justify-content: flex-end;
    }
    .card-feature{
        margin: 0 5px;
        color: black;
        font-size: 10px;
    }
    .card-status{
        position: absolute;
        top: 0;
        left: 0;
    }
    .card-delete{
        position: absolute;
        color: white;
        cursor: pointer;
        background-color: rgb(232, 39, 39);
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        top: -5px;
        right: -5px;
        &:hover{
            background-color: #FA3D3D;
        }
    }
    .card-tags{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
        height: 44px;
        
    }
    .card-itemTag{
        color: white;
        border-radius: 5px;
        width: auto;
        height: 18px;
        padding: 2px;
        margin: 2px;
        font-size: 0.6rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: center;
        background-color: #A2A2A2;
    }
    .card-buttons{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 10px 0;
    }
`