import axios from "axios";
import { URL } from "../../../../../config"
import { IAccount, IEditProfile, IFormChangePass, IProfile } from "../../../interfaces/profile.interfaces";
import { getCookie } from "../../../utils/Http";
import { AdaptEPAccount, AdaptEPEditProfile, AdaptEPProfile } from "../adapters/profile.adapters";

const header = {
    headers: {
        Authorization: `Bearer ${getCookie()}`,
        'Content-Type': 'application/json'
    }
}

export const getDataProfile = async (): Promise<IProfile | undefined> => {
    const resp = await axios.get(`${URL}api/publisher/profiles`, header)
    //const data = {"name":"Publicador","last_name":"Publicador","full_name":"Publicador Publicador","slug":"publicador-publicador-2","biography":null,"occupation":null,"contact_email":"jguku2002@hotmail.com","facebook":null,"instagram":null,"tiktok":null,"twitter":null,"youtube":null,"image":null,"countPosts":1525,"countFollowers":5,"avgRatings":2.888888888888889}
    if (resp.status === 200) return AdaptEPProfile(resp.data.data)
    return undefined
}

export const getDataProfileForEdit = async (): Promise<IEditProfile | undefined> => {
    const resp = await axios.get(`${URL}api/publisher/profile-detail`, header)
    if (resp.status === 200) return AdaptEPEditProfile(resp.data.data)
    return undefined
}

export const editProfile = async (form: FormData) => {
    const resp = await axios.post(`${URL}api/publisher/edit/profile`, form, {
        headers: {
            Authorization: `Bearer ${getCookie()}`,
            'Content-Type': 'multipart/form-data'
        }
    })
    if (resp.status === 200) return resp.data.data
    return new Error("Ocurrio un error")
}

export const getDataAccount = async (): Promise<IAccount | undefined> => {
    const resp = await axios.get(`${URL}api/publisher/users`, header)
    //const data = {"name":"Publicador","last_name":"Publicador","full_name":"Publicador Publicador","slug":"publicador-publicador-2","biography":null,"occupation":null,"contact_email":"jguku2002@hotmail.com","facebook":null,"instagram":null,"tiktok":null,"twitter":null,"youtube":null,"image":null,"countPosts":1525,"countFollowers":5,"avgRatings":2.888888888888889}
    if (resp.status === 200) return AdaptEPAccount(resp.data.data)
    return undefined
}

export const changePassword = async (form: IFormChangePass) => {
    const resp = await axios.post(`${URL}api/publisher/change/password`, form, header)
    //const data = {"name":"Publicador","last_name":"Publicador","full_name":"Publicador Publicador","slug":"publicador-publicador-2","biography":null,"occupation":null,"contact_email":"jguku2002@hotmail.com","facebook":null,"instagram":null,"tiktok":null,"twitter":null,"youtube":null,"image":null,"countPosts":1525,"countFollowers":5,"avgRatings":2.888888888888889}
    if (resp.status === 200) return resp.data.data
    return new Error("Ocurrio un error")
}