import styled from "styled-components";
import { BG_COLOR, BG_CONTRAST_COLOR, PRIMARY_COLOR, PRIMARY_COLOR_2, PRIMARY_COLOR_2_HOVER, PRIMARY_COLOR_HOVER, TEXT_COLOR } from "../../../../styled-components/variables";

export const StyledMenu = styled.aside`
    width: 240px;
    position: fixed;
    height: 100vh;
    background-color: ${BG_COLOR};
    padding-left: 0.5rem;
    padding-top: 80px;
`

export const StyledMenuList = styled.ul`
    padding: 0;
    margin-top: 0;
    cursor: pointer;
    text-align: start; 
    padding: 0.5rem ;
    .link{
        color: ${TEXT_COLOR};
        text-decoration: none;
        .menu-item:hover{
            background-color: ${BG_CONTRAST_COLOR};
        }
        &.active{
            color: ${PRIMARY_COLOR_2};
            .menu-item{
                background-color: ${PRIMARY_COLOR_2_HOVER};
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                position: relative;
            }      
        }
    }
    .menu-item{
        padding: 0.8rem 0.5rem;
        font-weight: 400;
        font-size: 15px;
        margin: 5px 0;
        list-style: none;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }
`

export const StyledBtnExit = styled.div`
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 10px;
    margin: 5px 0;
    list-style: none;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    .item-exit{
        border: none;
        color: white;
        width: 100%;
        color: ${TEXT_COLOR};
        text-align: start;
        font-size: 14px;
        cursor: pointer;
        background-color: transparent;
    }
`