import { AuthContextProvider } from './contexts/Auth.context';
import RootRouter from './router/root.router';

function App() {
  return (
    <div className="App">
      <AuthContextProvider>
        <RootRouter/>
      </AuthContextProvider>
    </div>
  );
}

export default App;
