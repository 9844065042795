import { useState } from "react"
import { useNavigate } from "react-router-dom"
import BtnPrimary from "../../../../../../components/Buttons/BtnPrimary"
import PrimaryLoader from "../../../../../../components/Loaders/PrimaryLoader"
import BoxPreviewImg from "../../../../components/BoxPreviewImg"
import Header from "../../../../components/Header"
import AdvanceTrix from "../../../../components/Trix/AdvanceTrix"
import { IEditProfile, IFormEditProfile } from "../../../../interfaces/profile.interfaces"
import { editProfile } from "../../services/profile.services"
import { ValidateFormProfile } from "../../utils/validate.utils"
import ListInputNetworks from "./components/ListInputsNetworks"
import SelectCountrys from "./components/SelectCountrys"
import SelectGender from "./components/SelectGender"
import { StyledAsideEditProfile, StyledBodyEditProfile, StyledContentEditProfile, StyledH1EditProfile, StyledHeaderEditProfile, StyledMainEditProfile } from "./styles"
import { useQueryClient } from 'react-query';

const ViewEditProfile = (data: IEditProfile) => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<{[key: string]: { error: boolean, msg: string }}>({});
    const [form, setForm] = useState<IFormEditProfile>({...data, fileImage: null})
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const submit = () => {
        const dataErrors = ValidateFormProfile(form)
        if(Object.keys(dataErrors).length !== 0){
            setErrors(dataErrors)
            return
        }
        setLoading(true)
        const formData = new FormData();
        formData.append("name", form.name);
        formData.append("lastname", form.lastName);
        formData.append("occupation", form.occupation);
        formData.append("links", JSON.stringify(form.networks));
        formData.append("birthDate", form.birthDate);
        formData.append("gender", form.gender);
        formData.append("country",form.country);
        formData.append("phoneNumber", form.phone);
        formData.append("description", form.biography || "");
        if(form.fileImage) formData.append("image", form.fileImage);
        editProfile(formData)
            .then( () => {
                queryClient.refetchQueries("data-profile")
                queryClient.refetchQueries("myEditProfile")
                navigate(`/profile`)
            } )
            .catch( (err) => {
                console.error(err)
                setLoading(false) 
            } )
    }

    return (
        <StyledMainEditProfile>
            <Header navigation={["Perfil", "Editar"]} />
            <StyledHeaderEditProfile>
                <StyledH1EditProfile>EDITAR PERFIL</StyledH1EditProfile>
                {loading 
                        ? <div><PrimaryLoader/></div>
                        : <BtnPrimary style="btn" title="Guardar" handleClick={submit} />
                        
                    }
            </StyledHeaderEditProfile>
            <StyledBodyEditProfile>
                <StyledAsideEditProfile>
                    <div>
                        <BoxPreviewImg
                            action={(data) => setForm({...form, "fileImage": data})}
                            classname="form-img"
                            fileDefault={data.image} 
                            name="portada"
                        />
                        {errors.fileImage && <p className='text-error'> {errors.fileImage.msg} </p>}
                    </div>
                </StyledAsideEditProfile>
                <StyledContentEditProfile>
                    <div className="dual-columns">
                        <div className="column">
                            <p className="subtitle-form">Nombre</p>
                            <input 
                                className="input-form" type="text" 
                                onChange={(e) => setForm({...form, "name": e.currentTarget.value})}  
                                value={form.name} placeholder="Escribe aqui"
                            />
                            {errors.name && <p className='text-error'> {errors.name.msg} </p>}
                        </div>
                        <div className="column">
                            <p className="subtitle-form">Apellido</p>
                            <input 
                                className="input-form" type="text" 
                                onChange={(e) => setForm({...form, "lastName": e.currentTarget.value})}  
                                value={form.lastName} placeholder="Escribe aqui"
                            />
                            {errors.lastName && <p className='text-error'> {errors.lastName.msg} </p>}
                        </div>
                    </div>
                    <div className="column">
                        <p className="subtitle-form">Ocupacion</p>
                        <input 
                            className="input-form" 
                            type="text" onChange={(e) => setForm({...form, "occupation": e.currentTarget.value})}  
                            value={form.occupation} placeholder="Escribe aqui" 
                        />
                        {errors.occupation && <p className='text-error'> {errors.occupation.msg} </p>}
                    </div>
                    <p className="subtitle-form" >Redes Sociales</p>
                    <ListInputNetworks 
                        networks={form.networks} 
                        action={(data) => setForm({...form, "networks": data})} 
                    />
                    {errors.networks && <p className='text-error'> {errors.networks.msg} </p>}
                    <div className="dual-columns">
                        <div className="column">
                            <p className="subtitle-form">Nacimiento</p>
                            <input 
                                className="input-form" type="date" 
                                onChange={(e) => setForm({...form, "birthDate": e.currentTarget.value})}  
                                value={form.birthDate} 
                            />
                            {errors.birthDate && <p className='text-error'> {errors.birthDate.msg} </p>}
                        </div>
                        <div className="column">
                            <p className="subtitle-form">Genero</p>
                            <SelectGender 
                                classname="dropdown" 
                                action={(data) => setForm({...form, "gender": data})}
                                initValue={form.gender}
                            />
                            {errors.gender && <p className='text-error'> {errors.gender.msg} </p>}
                        </div>
                    </div>
                    <div className="dual-columns">
                        <div className="column">
                            <p className="subtitle-form">Pais</p>
                            <SelectCountrys 
                                classname="dropdown" 
                                action={(data) => setForm({...form, "country": data})}
                                initValue={form.country}
                            />
                            {errors.country && <p className='text-error'> {errors.country.msg} </p>}
                        </div>
                        <div className="column">
                            <p className="subtitle-form">Telefono</p>
                            <input 
                                className="input-form" 
                                onChange={(e) => setForm({...form, "phone": e.currentTarget.value})}  
                                type="text" value={form.phone} 
                                placeholder="Escribe aqui"
                            />
                            {errors.phone && <p className='text-error'> {errors.phone.msg} </p>}
                        </div>
                    </div>
                    <div className="column">
                        <p className="subtitle-form">Biografia</p>
                        <AdvanceTrix
                            defaultValue={form.biography || ""}
                            onChange={(data: string) => setForm({...form, "biography": data})}
                        />
                        {errors.biography && <p className='text-error'> {errors.biography.msg} </p>}
                    </div>
                </StyledContentEditProfile>
            </StyledBodyEditProfile>
        </StyledMainEditProfile>
    )
}

export default ViewEditProfile