import styled from "styled-components";
import { BG_COLOR, PRIMARY_COLOR_2, PRIMARY_COLOR_2_HOVER, TEXT_COLOR } from "../../../../../../styled-components/variables";

export const StyledViewAdsContainer = styled.div`
    width: 100%;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
`

export const StyledViewAdsMain = styled.div`
    width: 100%;

`

export const StyledViewAdsFilters = styled.div`
    width: 100%;
    margin: 1rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .filter-days{
        display: flex;
        gap: 5px;
        button{
            background-color: ${PRIMARY_COLOR_2_HOVER};
            color: ${PRIMARY_COLOR_2};
            border: none;
            outline: none;
            border-radius: 10px;
            padding: 5px 10px;
            font-size: 12px;
            cursor: pointer;
            &.active{
                background-color: ${PRIMARY_COLOR_2};
                color: ${BG_COLOR};
            }
        }
    }
    .filter-select-container{
        width: 100%;
        max-width: 140px;
    }
    .filter-select{
        background-color: ${PRIMARY_COLOR_2_HOVER};
        color: ${PRIMARY_COLOR_2};
        font-size: 12px;
        z-index: 10;
        width: 100%;
        .Dropdown-menu{
            border-radius: 5px;
            box-shadow: 0 4px 13px #e9e9f9;
            border: none;
            padding: 0.5rem;
            font-size: 12px;
            margin: 5px 0;
            text-align: start;
        }
        .Dropdown-control{
            border-radius: 5px;
            margin: 5px 0;
            border: none;
            font-size: 14px;
            color: ${TEXT_COLOR};
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.7rem;
            cursor: pointer;
        }
    }
`

export const StyledViewAds = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 70% 30%;
    gap: 0.5rem;
    .center-loading{
        display: flex; 
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        max-height: 400px;
    }
`