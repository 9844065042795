import { StyledBtnPrimary } from "./styles"

interface props {
    handleClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    style?: string
    title: string
    children ?: JSX.Element
}

const BtnPrimary = ({handleClick,style,title,children}:props) => {
    return(
        <StyledBtnPrimary onClick={(e) => handleClick(e)} className={style}>
            { children ? children : title} 
        </StyledBtnPrimary>
    )
}

export default BtnPrimary