import { Fragment } from "react";
import { StyledHeader } from "./styles"

interface props {
    navigation: string[]
}

const Header = ({navigation}:props) => {
    return(
        <StyledHeader>
            Studio&nbsp;
            {
                navigation.map( (item, i) => {
                    const isLast = i === navigation.length - 1;
                    return (
                        <Fragment key={i} >
                            <i className={`fa-solid fa-angle-right`}></i>
                            &nbsp;<span className={isLast ? "active" : ""}>{item}</span>&nbsp;
                        </Fragment>
                    )
                })
            }
        </StyledHeader>
    )
}

export default Header