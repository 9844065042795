import Avatar from "react-avatar"
import { StyledCardCollaborator } from "./styles"

interface props {
    title:  string;
    image: string | null;
}

const CardCollaborator = ({title, image}:props) => {
    return (
        <StyledCardCollaborator>
            <Avatar size="30" className="image" textSizeRatio={2} maxInitials={2} src={image || ""} title={title} name={title} />
            <p className="title">{title}</p>
        </StyledCardCollaborator>
    )
}

export default CardCollaborator