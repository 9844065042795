import axios from "axios";
import Cookies from "js-cookie";
import { createContext, useEffect, useState, useContext } from "react";
import { MYURL, TOKEN_NAME, URL_AUTH, URL_PORTAL } from "../config";
import { Iuser } from "../interfaces/user.interfaces";
import { changeTicket, getDataUser, LoginOut } from "../services/auth.services";
import { getSearchParams } from "../utils/url.utils";

interface AuthInterface {
    dataUser: {
        auth: boolean,
        data: Iuser | null
    },
    Logout: () => void
    loading: boolean
}

const AuthContext = createContext<AuthInterface>(
    {
        dataUser: {
            auth: false,
            data: null
        },
        Logout: () => {},
        loading: true
    }
);

interface Props {
    children: JSX.Element | JSX.Element[]
}

const AuthContextProvider = ({ children }: Props) => {
    const [loading, setLoading] = useState(true);
    const [dataUser, setDataUser] = useState({
        auth: false,
        data: null
    });
    const token = Cookies.get(TOKEN_NAME)
    const  params  = getSearchParams(window.location.href)
    const ticket = params.ticket || null

    const Logout = () => {
        LoginOut()
        .then( (resp) => console.error(resp) )
        .catch( (error) => console.error(error) )
        .finally( () =>{ 
            Cookies.remove(TOKEN_NAME)
            window.location.href = `${URL_PORTAL}`
        });
    }

    const getData = () => {
        setLoading(true)
        getDataUser()
            .then((resp) => {
                if (resp.status !== 200) throw new Error("No auth")
                if(!resp.data.data.roles.includes("Publicador") ) {
                    if(!resp.data.data.roles.includes("Anunciante")){
                        throw new Error("No Access")
                    }
                }
                setDataUser({ auth: true, data: {...resp.data.data} })
            })
            .catch((error) => {
                console.error(error)
                Cookies.remove(TOKEN_NAME)
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        setLoading(true)

        if(ticket){
            changeTicket(ticket)
                .then((data) => {
                    if (data) {
                        axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
                        Cookies.set(TOKEN_NAME, data.token)
                        window.location.href = "/"
                    }
                })
                .catch((error) => console.error(error))
            return 
        }

        if(!token){
            window.location.href = `${URL_AUTH}login?continue=${MYURL}`
            return
        }

        getData()
    }, []);

    return (
        <AuthContext.Provider value={
            {
                loading,
                Logout,
                dataUser
            }
        }>
            {children}
        </AuthContext.Provider>
    )
}


export { AuthContextProvider };

const ContextAuth = () => {
    return useContext(AuthContext);
}

export default ContextAuth;