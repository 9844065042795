import axios from "axios";
import { URL } from "../../../config"
import { country, Gender, ICategory, ITags } from "../interfaces/global.interfaces";
import { getCookie } from "../utils/Http";

const header = {
    headers:{
        Authorization: `Bearer ${getCookie()}`,
        'Content-Type': 'application/json'
    }
}


export const getCountrys = async (): Promise<country[]> =>{
    const resp = await axios.get(`${URL}api/countries`,header)
    if(resp.status === 200) return resp.data.data
    return []
}

export const getGenders = async (): Promise<Gender[]> =>{
    const resp = await axios.get(`${URL}api/genders`,header)
    if(resp.status === 200) return resp.data.data
    return []
}

export const getCategorysWithoutToken = async () : Promise<ICategory[]> =>{
    const resp = await axios.get(`${URL}api/portal/categories`,header)
    if(resp.status === 200) return resp.data.data
    return []
  }

export const getTagsWithoutToken = async () : Promise<ITags[]> =>{
    const resp = await axios.get(`${URL}api/portal/tags`,header)
    if(resp.status === 200) return resp.data.data
    return []
  }