import ReactSelect from "react-select"


const monthOptions = [
    { label: "Enero", value: 1 },
    { label: "Febrero", value: 2 },
    { label: "Marzo", value: 3 },
    { label: "Abril", value: 4 },
    { label: "Mayo", value: 5 },
    { label: "Junio", value: 6 },
    { label: "Julio", value: 7 },
    { label: "Agosto", value: 8 },
    { label: "Septiembre", value: 9 },
    {  label: "Octubre", value: 10 },
    {  label: "Noviembre", value: 11 },
    {  label: "Diciembre", value: 12 }
];

interface props {
    styleName?: string
    defaultValue?: number
    handleChange: (val: {label: string, value: number} ) => void
}

const SelectMonths = ({styleName,defaultValue = 0, handleChange }: props) => {
    return (
        <ReactSelect
            placeholder="Mes"
            className={styleName ? styleName : 'rselect'}
            components={{
                ClearIndicator: () => <div></div>,
            }}
            defaultValue={monthOptions[defaultValue]}
            onChange={(val) => handleChange({label: val?.label || "", value: val?.value || 0})}
            isDisabled={false}
            options={monthOptions.filter( op => op.value <= (new Date()).getMonth() + 1 )}
            isMulti={false}
        />
    )
}

export default SelectMonths