import styled from "styled-components";

export const StyledSectionAccounts = styled.div`
    width: 70%;
    padding: 0 2rem;
`
export const StyledRowSectionAccounts = styled.div`
    width: 100%;
    display: flex; 
    justify-content: space-between;
    align-items: center;
    .title{
        font-weight: bold;
    }
`
export const StyledColumnSectionAccounts = styled.div`
    width: 100%;
    display: flex; 
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    .title{
        font-weight: bold;
    }
    .containerCategories {
        width: 100%;
        display: inline-block;
    }
`
