import styled from "styled-components";
import { Appear } from "../../../../../../styled-components/animates";
import {
  BG_COLOR,
  BG_CONTRAST_COLOR,
  TEXT_COLOR,
} from "../../../../../../styled-components/variables";

export const StyledInputSearchCola = styled.div`
  width: 100%;
  position: relative;
  .typehead {
    position: relative;
    input {
      width: 100%;
      outline: none;
      border-radius: 5px;
      margin: 5px 0;
      border: none;
      font-size: 14px;
      background-color: ${BG_COLOR} !important;
      color: ${TEXT_COLOR};
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.7rem !important;
      cursor: pointer;
    }
    .dropdown-menu {
        width: 100%;
        position: absolute;
        border-radius: 5px;
        top: 110%;
        z-index: 10;
        padding: 0.4rem;
        box-shadow: 0 4px 13px #e9e9f9;
        background-color: ${BG_COLOR};
        a{
            color: ${TEXT_COLOR};
            text-decoration: none;
        }
    }
    .rbt-token{
        width: 100%;
        background-color: #E6E6E6;
        display: flex;
        justify-content: space-between;
        text-align: start;
        align-items: center;
        padding: 0.2rem 0.4rem;
        margin: 5px 0;
        font-size: 12px;
        border-radius: 5px;
        outline: none;
        .rbt-token-remove-button{
            cursor: pointer;
            background-color: transparent;
            border: none;        
        }
    }
  }
`;
