import { useState } from "react";
import { IAdsForm } from "../../../interfaces/ads.interfaces";
import { StyledSectionConf } from "../styles";
import toast, { Toaster } from 'react-hot-toast';
import BtnPrimary from "../../../../../../../components/Buttons/BtnPrimary";
import PreviewAds from "../components/PreviewAds";
import { ValidateInitForm } from "../utils/form.validate";

interface props {
    nextPage: () => void
    form: IAdsForm
    handleForm: React.Dispatch<React.SetStateAction<IAdsForm>>
}

const SectionInit = ({ form, handleForm, nextPage }: props) => {
    const [errors, setErrors] = useState<{ [key: string]: { error: boolean, msg: string } }>({});

    const validateForm = () => {
        const errors = ValidateInitForm(form)
        if (Object.keys(errors).length !== 0) {
            setErrors(errors)
            toast.error('Formulario Invalido');
            return
        }
        nextPage()
    }

    return (
        <StyledSectionConf>
            <div>
                <Toaster position="bottom-right" />
                <div>
                    <p className="subtitle-form">TITULO</p>
                    <input
                        className={`input-form ${errors.title ? "error" : ""}`}type="text"
                        placeholder="Escribe aqui"
                        onChange={(e) => handleForm({ ...form, "title": e.currentTarget.value })}
                        value={form.title}
                    />
                    {errors.title && <p className='text-error'> {errors.title.msg} </p>}
                </div>
                <div>
                    <p className="subtitle-form">FECHA INICIO</p>
                    <div className="columns-2">
                        <div>
                            <input
                                className={`input-form ${errors.startDate ? "error" : ""}`} type="date"
                                onChange={(e) => handleForm({ ...form, "startDate": e.currentTarget.value })}
                                value={form.startDate}
                            />
                            {errors.startDate && <p className='text-error'> {errors.startDate.msg} </p>}
                        </div>
                        <div>
                            <input
                                className={`input-form ${errors.hourStart ? "error" : ""}`} type="time"
                                onChange={(e) => handleForm({ ...form, "hourStart": e.currentTarget.value })}
                                value={form.hourStart}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <p className="subtitle-form">FECHA FINAL</p>
                    <div className="columns-2">
                        <div>
                            <input
                                className={`input-form ${errors.endDate ? "error" : ""}`} type="date"
                                onChange={(e) => handleForm({ ...form, "endDate": e.currentTarget.value })}
                                value={form.endDate}
                            />
                            {errors.endDate && <p className='text-error'> {errors.endDate.msg} </p>}
                        </div>
                        <div>
                            <input
                                className={`input-form ${errors.hourEnd ? "error" : ""}`} type="time"
                                onChange={(e) => handleForm({ ...form, "hourEnd": e.currentTarget.value })}
                                value={form.hourEnd}
                            />
                        </div>
                    </div>
                </div>

                <div className="footer-form">
                    <BtnPrimary style="button" title="CONTINUAR" handleClick={validateForm} />
                </div>
            </div>
            <div className="content-preview">
                <PreviewAds form={form} />
            </div>
        </StyledSectionConf>
    )
}

export default SectionInit