import DOMPurify from 'isomorphic-dompurify';
import { StyledSectionBiografy } from "./styles"

interface props {
    biografy: string | null
}

const SectionBiografy = ({ biografy }: props) => {
    return (
        <StyledSectionBiografy>
            {
                biografy
                    ? <div dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(biografy)
                    }} />
                    : <p className='text-default'>No tiene biografia</p>
            }
        </StyledSectionBiografy>
    )
}

export default SectionBiografy