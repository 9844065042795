import styled from "styled-components";
import { BG_CONTRAST_COLOR, TEXT_COLOR } from "../../../../../../styled-components/variables";

export const StyledCreateLive = styled.div`
    width: 100%;
`

export const StyledBodyCreateLive = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 60% 40%;
    gap: 1rem;
`


export const StyledBodyCredencialsLive = styled.div`
    width: 100%;
    padding: 1rem 0;
    .title{
        text-align: start;
        font-weight: 500;
    }
    .input{
        width: 100%;
        padding: 1rem;
        margin: 5px 0;
        border-radius: 10px;
        border: 0;
        outline: none;
        //background-color: ${BG_CONTRAST_COLOR};
        border: 2px solid  ${BG_CONTRAST_COLOR};
    }
    .input-title{
        text-align: start;
        font-size: 14px;
        margin: 5px 0;
        //font-weight: bold;
        color: ${TEXT_COLOR};
    }
`

