import { useState } from "react";
import { IAdsForm } from "../../interfaces/ads.interfaces";
import SectionConfig from "./sections/SectionConfig";
import SectionFilters from "./sections/SectionFilters";
import SectionFinal from "./sections/SectionFinal";
import { StyledCreateAds, StyledCreateAdsHeader } from "./styles"
import SectionInit from "./sections/SectionInit";

interface props {
    initForm?: IAdsForm
    edit?: boolean
}

const ViewCreateAds = ({initForm, edit = false}: props) => {
    const [viewActive, setViewActive] = useState(0);
    const [form, setForm] = useState<IAdsForm>(initForm ? initForm : {
        title: "",
        startDate: "",
        endDate: "",
        hourStart: "",
        hourEnd: "",
        finalURL: "",
        advertisingSpaces: [],
        images: [],
        headline: "",
        nameCallToAccion: "",
        description: "",
        filterAge: {
            active: false,
            startAge: 0,
            endAge: 0
        },
        filterGender: {
            gender: 0, // 1 H // 2 M  // 3 ALL
            active: false,
        },
        filterCategorys: {
            idCategorys: [],
            active: false,
        },
        filterTags: {
            idTags: [],
            active: false,
        },
        edit: edit || false
    });

    return (
        <StyledCreateAds>
            <StyledCreateAdsHeader>
                <div className={`itemTab ${viewActive >= 0 && "active"}`}>1</div>
                <i className={`fa-solid fa-angle-right itemNext ${viewActive >= 0 && "active"}`}></i>
                <div className={`itemTab ${viewActive >= 1 && "active"}`}>2</div>
                <i className={`fa-solid fa-angle-right itemNext ${viewActive >= 1 && "active"}`}></i>
                <div className={`itemTab ${viewActive >= 2 && "active"}`}>3</div>
                <i className={`fa-solid fa-angle-right itemNext ${viewActive >= 2 && "active"}`}></i>
                <div className={`itemTab ${viewActive >= 3 && "active"}`}>4</div>
            </StyledCreateAdsHeader>
            {viewActive === 0 && <p>CONFIGURACION INICIAL</p>}
            {viewActive === 1 && <p>CONFIGURACION DEL ANUNCIO</p>}
            {viewActive === 2 && <p>FILTROS</p>}
            {viewActive === 3 && <p>FINALIZAR</p>}
            {viewActive === 0 && <SectionInit nextPage={() => setViewActive(1)} form={form} handleForm={setForm} />}
            {viewActive === 1 && <SectionConfig prevPage={() => setViewActive(0)} nextPage={() => setViewActive(2)} form={form} handleForm={setForm} />}
            {viewActive === 2 && <SectionFilters nextPage={() => setViewActive(3)} form={form} handleForm={setForm} prevPage={() => setViewActive(1)} />}
            {viewActive === 3 && <SectionFinal form={form} prevPage={() => setViewActive(2)} isEdit={edit} />}
        </StyledCreateAds>
    )
}

export default ViewCreateAds