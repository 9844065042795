import Skeleton from "react-loading-skeleton";
import { StyledCardLargePost } from "./styles";

const SkeletonCardLargePost = () => {
    return (
        <StyledCardLargePost>
            <div>
                <Skeleton width={20} height={20} borderRadius="100%" />
            </div>
            <div>
                <Skeleton width={80} height={50} borderRadius="5%" />
            </div>
            <div>
                <p className="card-title"><Skeleton width="60%" height={20} /></p>
                <div className="card-tags">
                    <Skeleton width="90%" height={10} />
                    <Skeleton width="90%" height={10} />
                </div>
            </div>
            <div className="card-feature">
                <Skeleton width={30} height={10}/>
            </div>
            <div className="card-feature">
                <Skeleton width={30} height={10}/>
            </div>
            <div className="card-feature">
                <p><Skeleton width={50} height={10}/></p>
            </div>
        </StyledCardLargePost>
    )
}

export default SkeletonCardLargePost