import { URL,TOKEN_NAME, URL_AUTH_SERVICE } from "../config";
import axios from "axios";
import Cookies from "js-cookie";

const header = {
    headers:{
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${Cookies.get(TOKEN_NAME)}`,
    }
}

export const getDataUser = () => {
    return axios.post(`${URL}api/me`,{},header);
}

export const LoginOut = () => {
    return axios.post(`${URL}api/auth/logout`,{},{headers: header.headers});
}

export const changeTicket = async (ticket: string): Promise<{token: string} | null> => {
    const resp = await axios.post(`${URL_AUTH_SERVICE}api/v1/changeTicket`,{ssoTicket: ticket},{headers: header.headers})
        .catch( e => {
            throw new Error(e.response.data.error || e.message)
        } )

    if(resp && resp.status === 200) {
        return resp.data.data
    }
    
    return null
}