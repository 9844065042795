import styled from "styled-components";
import { PRIMARY_COLOR, PRIMARY_COLOR_HOVER } from "../../../styled-components/variables";

export const StyledBtnPrimary = styled.button`
    width: 100%;
    padding: 10px 30px;
    font-size: 16px;
    color: white;
    background-color: ${PRIMARY_COLOR};
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    &:hover{
        background-color: ${PRIMARY_COLOR_HOVER};
    }
`