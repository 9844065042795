import ReactSelect from "react-select"
import BtnPrimary from "../../../../../../../components/Buttons/BtnPrimary"
import ContainerGetData from "../../../../../containers/GetData.container"
import { getCategorysWithoutToken, getTagsWithoutToken } from "../../../../../services/app.services"
import { IAdsForm} from "../../../interfaces/ads.interfaces"
import { StyledSectionConf } from "../styles"
import PreviewAds from "../components/PreviewAds"
import { ValidateFiltersForm } from "../utils/form.validate"
import { useState } from "react"
import toast, { Toaster } from 'react-hot-toast';

interface props {
    nextPage: () => void
    prevPage: () => void
    form: IAdsForm
    handleForm: React.Dispatch<React.SetStateAction<IAdsForm>>
}

const SectionFilters = ({ nextPage, prevPage, form, handleForm }: props) => {
    const [errors, setErrors] = useState<{ [key: string]: { error: boolean, msg: string } }>({});

    const submitForm = () => {
        const errors = ValidateFiltersForm(form)
        if (Object.keys(errors).length !== 0) {
            setErrors(errors)
            toast.error('Formulario Invalido');
            return
        }
        nextPage()
    }
    return (
        <StyledSectionConf>
            <div>
                <Toaster position="bottom-right" />
                <div>
                    <div className="header-opt-filter">
                        <p>RANGO DE EDAD</p>
                        <input type="checkbox" checked={form.filterAge.active} onChange={(e) => handleForm({ ...form, "filterAge": { ...form.filterAge, "active": e.currentTarget.checked } })} />
                    </div>
                    {form.filterAge.active && <div className="columns-2">
                        <div>
                            <p className="subtitle-form">MIN</p>
                            <input
                                className={`input-form ${errors.startAge ? "error" : ""}`} type="number"
                                placeholder="Escribe aqui"
                                onChange={(e) => handleForm({ ...form, "filterAge": { ...form.filterAge, "startAge": parseInt(e.currentTarget.value) } })}
                                value={form.filterAge.startAge}
                            />
                            {errors.startAge && <p className='text-error'> {errors.startAge.msg} </p>}
                        </div>
                        <div>
                            <p className="subtitle-form">MAX</p>
                            <input
                                className={`input-form ${errors.endAge ? "error" : ""}`} type="number"
                                placeholder="Escribe aqui"
                                onChange={(e) => handleForm({ ...form, "filterAge": { ...form.filterAge, "endAge": parseInt(e.currentTarget.value) } })}
                                value={form.filterAge.endAge}
                            />
                            {errors.endAge && <p className='text-error'> {errors.endAge.msg} </p>}
                        </div>
                    </div>}
                </div>
                <div>
                    <div className="header-opt-filter">
                        <p>CATEGORIAS</p>
                        <input
                            type="checkbox"
                            checked={form.filterCategorys.active}
                            onChange={(e) => handleForm({ ...form, "filterCategorys": { ...form.filterCategorys, "active": e.currentTarget.checked } })}
                        />
                    </div>
                    {form.filterCategorys.active && <div>
                        <ContainerGetData
                            service={getCategorysWithoutToken}
                            name="categories"
                        >
                            {
                                ({ data, isLoading, isError }) => {
                                    if (isLoading) return <></>
                                    return (
                                        <ReactSelect
                                            placeholder="Buscar"
                                            className='rselect'
                                            components={{
                                                ClearIndicator: () => <div></div>,
                                            }}

                                            defaultValue={form.filterCategorys.idCategorys.map((f) => {
                                                return { value: f, id: f, label: data ? (data.find(x => x.id === f))?.label : "" }
                                            })}
                                            onChange={
                                                (data) => handleForm({
                                                    ...form,
                                                    "filterCategorys": {
                                                        ...form.filterCategorys,
                                                        "idCategorys": data.map((e) => e ? e.id.toString() : "")
                                                    }
                                                })
                                            }
                                            isDisabled={isError}
                                            options={data ? data.map((e) => { return { ...e, value: e.id } }) : []}
                                            isMulti={true}

                                        />
                                    )
                                }
                            }
                        </ContainerGetData>
                    </div>}
                </div>
                <div>
                    <div className="header-opt-filter">
                        <p>ETIQUETAS</p>
                        <input 
                            type="checkbox" 
                            checked={form.filterTags.active} 
                            onChange={(e) => handleForm({ ...form, "filterTags": { ...form.filterTags, "active": e.currentTarget.checked } })}
                        />
                    </div>
                    <div>
                        {form.filterTags.active && <div>
                            <ContainerGetData
                                service={getTagsWithoutToken}
                                name="tags"
                            >
                                {
                                    ({ data, isError }) => {
                                        return (
                                            <ReactSelect
                                                placeholder="Buscar"
                                                className='rselect'
                                                components={{
                                                    ClearIndicator: () => <div></div>,
                                                    //MultiValue: () => <div></div>
                                                }}

                                                /*defaultValue={form.filterTags.idTags.map((f) => {
                                                    return { value: f, label: data ? (data.find(x => x.value === f))?.label : "" }
                                                })}*/
                                                onChange={
                                                    (data) => handleForm({
                                                        ...form,
                                                        "filterTags": {
                                                            ...form.filterTags,
                                                            "idTags": data.map((e) => e ? e.value.toString() : "")
                                                        }
                                                    })
                                                }
                                                isDisabled={isError}
                                                options={data}
                                                isMulti={true}

                                            />
                                        )
                                    }
                                }
                            </ContainerGetData>
                        </div>}
                    </div>
                </div>
                <div className="footer-form">
                    <BtnPrimary style="button-prev" title="REGRESAR" handleClick={prevPage} />
                    <BtnPrimary style="button" title="CONTINUAR" handleClick={submitForm} />
                </div>
            </div>
            <div className="content-preview">
                <PreviewAds form={form} />
            </div>
        </StyledSectionConf>
    )
}

export default SectionFilters