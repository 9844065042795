import styled from "styled-components";

export const StyledSectionBiografy = styled.div`
    width: 90%;
    margin: 0 auto;
    text-align: start;
    img{
        width: 100%;
        max-height: 300px;
        object-fit: contain;
        text-align: center;
    }
    .text-default{
        text-align: center;
    }
`
