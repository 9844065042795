import { useEffect, useRef, useState } from "react";
import { StyledTextArea } from "./styles";

interface props {
    handleChange: (txt: string) => void
    value: string
    classname: string
    placeholder: string
}

const TextArea = ({handleChange, value, classname,placeholder}:props) => {
    const divTxtArea = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if(value.length > 0){
            if (divTxtArea.current) {
                divTxtArea.current.textContent = value;
            }
        }
    }, []);

    return (
        <>
            <StyledTextArea
                title={placeholder}
                ref={divTxtArea} className={classname}
                contentEditable onInput={(e) => handleChange(e.currentTarget.innerText)}
            />
            <textarea hidden value={value}></textarea>
        </>
    )
}

export default TextArea