import styled from "styled-components";
import { BG_COLOR, BG_CONTRAST_COLOR, PRIMARY_COLOR, TEXT_COLOR } from "../../../../styled-components/variables";

export const StyledHeaderNav = styled.header`
    width: 100%;
    position: fixed;
    background-color: ${BG_COLOR};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    height: 70px;
    border-bottom: 1px solid #e3e3e3;
    z-index: 100;
    .header-profile{
        position: relative;
    }
    .header-iconProfile{
        width: 30px !important;
        height: 30px !important;
        border-radius: 50%;
        object-fit: cover;
        cursor: pointer;
    }
    .header-ProfilePopUp{
        width: 200px;
        position: absolute;
        top: 20px;
        right: 0;
        background-color: ${BG_COLOR};
        padding: 1rem 0;
        list-style: none;
        border-radius: 10px;
        box-shadow: rgb(0 0 0 / 12%) 0px 4px 16px;
    }
    .header-itemPopUp{
        width: 100%;
        font-size: 14px;
        padding: 0.6rem 0.8rem;
        cursor: pointer;
        display: flex;
        justify-content: start;
        align-items: center;
        color: ${TEXT_COLOR};
        a{
            color: ${TEXT_COLOR};
            text-decoration: none;
        }
        &:hover{
            background-color: ${BG_CONTRAST_COLOR};
        }
        &.topLine{
            margin-top: 1rem;
            border-top: 1px solid rgb(227, 227, 227);
        }
    }
    .header-itemAc{
        display: flex;
        justify-content: start;
        align-items: center;
        text-decoration: none;
    }
`