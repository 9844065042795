import WrapperApp from "../../../../components/WrapperApp"
import { UIRouterContextProvider } from "../../contexts/UIRouter.context"
import ViewProfile from "./view"

const Profile = () => {
    return(
        <WrapperApp>
            <UIRouterContextProvider>
                <ViewProfile/>
            </UIRouterContextProvider>
        </WrapperApp>
    )
}

export default Profile