import styled from "styled-components";
import {
  BG_COLOR,
  BG_CONTRAST_COLOR,
  PRIMARY_COLOR_2,
  PRIMARY_COLOR_2_HOVER,
} from "../../../../../../../../styled-components/variables";

export const StyledPreviewFeed = styled.div`
  width: 100%;
  padding: 1rem 0;
  padding-bottom: 0;
  background-color: white;
  //box-shadow: 0 4px 13px #e9e9f9;
  border-radius: 10px;
  margin-bottom: 1rem;
  .header {
    width: 100%;
    display: grid;
    grid-template-columns: 50px 1fr;
    padding: 0 0.5rem;
  }
  .image-profile {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
  .container1 {
    width: 100%;
  }
  .subheader {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    .title,
    .occupation,
    .date {
      margin: 5px 0;
    }
    .title {
      font-size: 10px;
      font-weight: bold;
    }
    .occupation,
    .date {
      font-size: 8px;
    }
  }
  .datapost {
    width: 100%;
    .title {
      font-size: 15px;
      font-weight: bold;
      margin: 5px 0;
    }
    .subtitle {
      text-align: start;
      font-size: 10px;
      margin: 5px 0;
    }
    .highlight {
      color: ${PRIMARY_COLOR_2};
      font-weight: 500;
    }
  }
  .body {
    .preview-image {
      width: 100%;
      object-fit: cover;
      height: 200px;
    }
    .preview {
      background-color: ${BG_CONTRAST_COLOR};
      width: 100%;
      height: 200px;
    }
  }
  &.mobile {
    top: 22%;
    left: 25%;
    max-width: 350px;
    .body {
      .preview-image {
        width: 100%;
        object-fit: cover;
        height: 300px;
      }
      .preview {
        background-color: ${BG_CONTRAST_COLOR};
        width: 100%;
        height: 300px;
      }
    }
  }
  .footer {
    background-color: ${PRIMARY_COLOR_2_HOVER};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0.5rem;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    p {
      color: ${PRIMARY_COLOR_2};
      font-weight: 600;
      margin: 5px 0;
      text-align: start;
      font-size: 10px;
    }
    button {
      color: white;
      border-radius: 10px;
      padding: 0.5rem 1rem;
      border: none;
      outline: none;
      font-size: 8px;
      background-color: ${PRIMARY_COLOR_2};
      cursor: pointer;
    }
  }
`;

export const StyledPreview2 = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 40% 60%;
  gap: 5px;
  .preview-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    min-height: 100px;
    max-height: 200px;
  }
  .preview {
    background-color: ${BG_CONTRAST_COLOR};
    width: 100%;
    height: 100%;
    min-height: 100px;
    max-height: 200px;
  }
  .container{
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    gap: 5px;
  }
  .title {
    text-align: start;
    font-weight: bold;
    font-size: 8px;
    margin: 5px 0;
    margin-bottom: 0;
  }
  .subtitle {
    text-align: start;
    font-size: 8px;
    margin: 5px 0;
  }
  .account {
    display: grid;
    grid-template-columns: 20px 1fr;
    gap: 5px;
  }
  .account-body {
    width: 100%;
    font-size: 8px;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    p {
      margin: 2px 0;
    }
  }
  button {
    color: white;
    border-radius: 10px;
    padding: 0.2rem 0.5rem;
    border: none;
    outline: none;
    font-size: 6px;
    background-color: ${PRIMARY_COLOR_2};
    cursor: pointer;
  }
`;

export const StyledPreview3 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  .preview-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    min-height: 70px;
    max-height: 70px;
  }
  .preview {
    background-color: ${BG_CONTRAST_COLOR};
    width: 100%;
    height: 100%;
    min-height: 70px;
    max-height: 70px;
  }
  .container{
    width: 100%;
    background-color: ${BG_COLOR};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.2rem;
  }
  .account{

    display: grid;
    grid-template-columns: 20px 1fr;
    gap: 5px;
  }
  .account-body {
    width: 100%;
    font-size: 8px;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    p {
      margin: 2px 0;
    }
    .title{
      font-weight: bold;
    }
  }
  .account-body-2{
    width: 100%;
    display: flex;
    gap: 5px;
  }
  button {
    color: white;
    border-radius: 10px;
    padding: 0.2rem 0.5rem;
    border: none;
    outline: none;
    font-size: 6px;
    background-color: ${PRIMARY_COLOR_2};
    cursor: pointer;
  }
`