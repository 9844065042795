import { Collaboration } from "../../../interfaces/post.interfaces";
import { IGetPublisher } from "../interfaces/post.interfaces";

export const AdaptEPgetPublishers = (ep: IGetPublisher): Collaboration => {
    const data = {
        id:        ep.id,
        user:      ep.full_name,
        image:  ep.image
    }
    return data;
}