import { useQuery } from "react-query"
import { getDataAccount } from "../../services/profile.services"
import { StyledColumnSectionAccounts, StyledRowSectionAccounts, StyledSectionAccounts } from "./styles"

const SectionAccounts = () => {

   const {data, isLoading, isError} = useQuery("account",getDataAccount, {refetchOnWindowFocus: false})

    if(isLoading) {
        return(
            <div>
                cargando...
            </div>
        )
    }

    if(isError || !data){
        return(
            <div>ocurrio un error</div>
        )
    }

    return (
        <StyledSectionAccounts>
            <StyledRowSectionAccounts>
                <p className="title">Nombre</p>
                <p>{data.name}</p>
            </StyledRowSectionAccounts>
            <StyledRowSectionAccounts>
                <p className="title">Apellido</p>
                <p>{data.lastName}</p>
            </StyledRowSectionAccounts>
            <StyledRowSectionAccounts>
                <p className="title">Correo Electronico</p>
                <p>{data.email}</p>
            </StyledRowSectionAccounts>
            <StyledRowSectionAccounts>
                <p className="title">Numero de Telefono</p>
                <p>{data.phone}</p>
            </StyledRowSectionAccounts>
            <StyledRowSectionAccounts>
                <p className="title">País</p>
                <p>{data.country}</p>
            </StyledRowSectionAccounts>
            <StyledColumnSectionAccounts>
                <p className="title">Intereses</p>
                
                <div className="containerCategories">
                    {
                        data.categories.length === 0 && <p>Sin Intereses</p>
                    }
                    {
                        data.categories.map( (item) => <p key={item.value}>{item.label}</p> )
                    }
                </div>
            </StyledColumnSectionAccounts>
        </StyledSectionAccounts>
    )
}

export default SectionAccounts