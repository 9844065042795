import { StyledContainerModal } from "./styles"

interface props {
    JSXmodal: ({handleClose}: {handleClose: () => void}) => JSX.Element
    isOpen: boolean
    setIsOpen: (currentValue: boolean) => void
}

const Modal = ({JSXmodal,isOpen, setIsOpen}:props) => {

    if(!isOpen) return <></>

    return(
        <StyledContainerModal>
            <JSXmodal handleClose={() => setIsOpen(!isOpen)}/>
        </StyledContainerModal>
    )
}

export default Modal