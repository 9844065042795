import Avatar from "react-avatar"
import AnalitycsLoader from "../../../../../components/Loaders/AnalitycsLoader"
import { useQuery } from "react-query"
import { getLatestFollowers } from "../services/analitycs.services"
import { ImageAdapter } from "../../../adapters/image.adapter"

const ListRecentSubscribers = () => {
    const { isLoading, data, isError } = useQuery(["analitycs", "home", "lastSubs"], () => getLatestFollowers(), { refetchOnWindowFocus: false, retry: 1 })


    if (isLoading) {
        return (
            <div className="center-loading"><AnalitycsLoader /></div>
        )
    }

    if (!data || isError) {
        return (
            <p>Error</p>
        )
    }

    return(
        <div className="list-lastsubs">
            {
                data.map( item => (
                    <div key={item.id} className="cardSubscriber">
                        <Avatar size="30" className="image" textSizeRatio={2} maxInitials={2} src={ImageAdapter(item.photo || "")} title={item.name} name={item.name} />
                        <div style={{width: "100%"}}>
                            <p className="title">{item.name}</p>
                            <p className="subt">{item.roles[0]}</p>
                        </div>
                    </div>
                ) )
            }
        </div>
    )
}

export default ListRecentSubscribers