import Skeleton from "react-loading-skeleton"
import { StyledAsidePost, StyledBoxAsidePost, StyledListTagsAsidePost, StyledRowAsidePost, StyledSubtitleAsidePost, StyledTagAsidePost } from "./styles"

const SkeletonAsidePost = () => {
    return(
        <StyledAsidePost>
            <StyledRowAsidePost>
                <p className="title"><Skeleton width={100} height={20} /></p>
                <p><Skeleton width={60} height={20}/></p>
            </StyledRowAsidePost>
            <StyledRowAsidePost>
                <p className="title"><Skeleton width={100} height={20} /></p>
                <p><Skeleton width={60} height={20}/></p>
            </StyledRowAsidePost>
            <StyledRowAsidePost>
                <p className="title"><Skeleton width={100} height={20} /></p>
                <p><Skeleton width={60} height={20}/></p>
            </StyledRowAsidePost>
            <StyledBoxAsidePost>
                <StyledSubtitleAsidePost><Skeleton width={120} height={20} /> </StyledSubtitleAsidePost>
                <div>
                    <p style={{margin: "5px 0"}}><Skeleton width="100%" height={20}/></p>
                    <p style={{margin: "5px 0"}}><Skeleton width="100%" height={20}/></p>
                </div>
            </StyledBoxAsidePost>
            <StyledBoxAsidePost>
                <StyledSubtitleAsidePost><Skeleton width={120} height={20} /></StyledSubtitleAsidePost>
                <StyledListTagsAsidePost>
                    <Skeleton width="100px" height={20}/>
                    <Skeleton width="80px" height={20}/>
                    <Skeleton width="60px" height={20}/>
                    <Skeleton width="120px" height={20}/>
                    <Skeleton width="70px" height={20}/>
                    <Skeleton width="110px" height={20}/>
                </StyledListTagsAsidePost>
            </StyledBoxAsidePost>
            <StyledBoxAsidePost>
                <StyledSubtitleAsidePost><Skeleton width={120} height={20} /></StyledSubtitleAsidePost>
                <StyledListTagsAsidePost>
                    <Skeleton width="150px" height={20}/>
                    <Skeleton width="150px" height={20}/>
                </StyledListTagsAsidePost>
            </StyledBoxAsidePost>
        </StyledAsidePost>
    )
}

export default SkeletonAsidePost