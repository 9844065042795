import { useState } from "react";
import { StyledBoxImgPreview } from "./styles";

interface props {
    name: string
    fileDefault?: string
    classname?: string
    action: (file: File) => void 
    validate?: (file: File) => Promise<boolean>
    InitPlaceholder?: string
    EndPlaceHolder ?: string
}

const BoxPreviewImg = ({ name,classname, fileDefault,action,validate,InitPlaceholder,EndPlaceHolder }: props) => {
    const [file, setFile] = useState(fileDefault);

    const uploadSingleFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) return;
        const val = validate ? await validate(e.target.files[0]) : true
        if(!val) return;
        setFile(URL.createObjectURL(e.target.files[0]))
        action(e.target.files[0])
    }

    return (
        <StyledBoxImgPreview>
            {file
                ? <div className="content-img">
                    <img className="img-preview" src={file} alt='preview' />

                        <label className="change-img" htmlFor={name}>
                            <i className="fa-regular fa-image fa-3x"></i>
                            &nbsp;{EndPlaceHolder ? EndPlaceHolder : "Cambiar portada"}
                        </label>
                   
                </div>
                : <label htmlFor={name} className={`drop-down-content ${classname}`}>
                    <i className="fa-regular fa-image fa-2x"></i>
                    {InitPlaceholder ? InitPlaceholder : "Subir portada"}
                </label>
            }

            <input
                className="drop-down" hidden type="file"
                accept="image/*" name={name} onChange={uploadSingleFile} id={name}
            />
        </StyledBoxImgPreview>
    )
}

export default BoxPreviewImg