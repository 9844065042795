import styled from "styled-components";
import { BG_COLOR, SECONDARY_COLOR } from "../../../../../../../../styled-components/variables";

export const StyledAsidePost = styled.aside`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    border-left: 2px solid ${SECONDARY_COLOR};
    padding: 1rem;
`
export const StyledRowAsidePost = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title{
        font-weight: bold;
    }
    p{
        margin: 8px 0;
    }
`

export const StyledBoxAsidePost = styled.div`
    width: 100%;
`


export const StyledSubtitleAsidePost = styled.p`
    width: 100%;
    text-align: start;
    font-weight: bold;
    margin: 8px 0;
`

export const StyledListTagsAsidePost = styled.div`
    width: 100%;
    gap: 5px;
    display: flex; 
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    .title{
        margin: 5px 0;
    }
`

export const StyledTagAsidePost = styled.p`
    width: auto;
    margin: 0;
    padding: 0.5rem;
    font-size: 12px;
    border-radius: 5px;
    background-color: ${SECONDARY_COLOR};
`