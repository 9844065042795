import { Navigate, useRoutes } from "react-router-dom";
import NoAccess from "./pages/NoAccess";


const AuthRouter = () => {
    const routes = [
      {
        path: "/no-access",
        element: <NoAccess/>,
      },
      {
          path: "*",
          element: <Navigate to="/no-access" />,
      },
    ];
  
    return useRoutes(routes);
  }
  
  export default AuthRouter;