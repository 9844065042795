import { useQuery } from "react-query"
import WrapperApp from "../../../../components/WrapperApp"
import { getLiveID } from "../../services/lives.services"
import ViewCreateLive from "./view"

const SectionCreateLive = () => {
    const {isLoading, data, isError} =  useQuery("getLiveID", getLiveID, {refetchOnWindowFocus: false, retry: 1})

    if(isLoading){
        return(
            <WrapperApp>
                <p>loading...</p>
            </WrapperApp>
        )
    }

    if(isError || !data){
        return(
            <WrapperApp>
                <p>Ocurrio un error</p>
            </WrapperApp>
        )
    }

    return(
        <WrapperApp>
            <ViewCreateLive id_live={data.stream_id} id_secret={data.stream_key}/>
        </WrapperApp>
    )
}

export default SectionCreateLive