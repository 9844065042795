import { useQuery } from "react-query"
import ContainerAddComment from "./components/BoxAddComment/container"
import Comment from "./components/Comment"
import SkeletonComment from "./components/Comment/skeleton"
import { getComments } from "./services/comments.services"
import { StyledSectionComments } from "./styles"

interface props {
    idPost: string
}

const Comments = ({idPost}: props) => {
    const { data, isError, isLoading } = useQuery(["comments", idPost], () => getComments(idPost), {refetchOnWindowFocus: false})

    if(isLoading){
        return(
            <StyledSectionComments>
                <div className="comments">
                    <SkeletonComment/>
                    <SkeletonComment/>
                    <SkeletonComment/>
                </div>
            </StyledSectionComments>
        )
    }

    if(!data || isError){
        return(
            <StyledSectionComments>
                <p>ocurrio un error</p>
            </StyledSectionComments>
        )
    }

    if(data.total === 0){
        return(
            <StyledSectionComments>
                <p className="title" >Sin commentarios</p>
                <div className="comments">
                    <ContainerAddComment id={idPost} />
                </div>
            </StyledSectionComments>
        )
    }

    return(
        <StyledSectionComments>
            <p className="title" >{data.total} commentarios</p>
            <div className="comments">
                <ContainerAddComment id={idPost} />
                {data.comments.map( (item) => <Comment {...item} /> )}
            </div>
        </StyledSectionComments>
    )
}

export default Comments