import { StyledBodyHeaderProfile, StyledHeaderProfile, StyledTabsHeaderProfile } from "./styles"
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const SkeletonHeaderProfile = () => {
    return(
        <StyledHeaderProfile>
            <StyledBodyHeaderProfile>
                <div className="box-picture">
                    <div>
                        <Skeleton width={128} height={128} borderRadius="50%" /> 
                    </div>
                    <div className="box-picture-text">
                        <p className="box-picture-title"><Skeleton /> </p>
                        <p className="box-picture-subtitle"><Skeleton width={80} /> </p>
                    </div>
                </div>
                <div className="box-rankings">
                    <div className="box-ranking">
                        <span className="box-ranking-title"><Skeleton width={50} /></span>
                        <span className="box-ranking-subtitle">Publicaciones</span>
                    </div>
                    <div className="box-ranking">
                        <span className="box-ranking-title"><Skeleton width={50} /></span>
                        <span className="box-ranking-subtitle">Seguidores</span>
                    </div>
                    <div className="box-ranking">
                        <span className="box-ranking-title"><Skeleton width={50} /></span>
                        <span className="box-ranking-subtitle">Valoraciones</span>
                    </div>
                </div>
                <div className="box-socials">
                    <p className="box-socials-text">&nbsp;<Skeleton width={80} /></p>
                    <p className="box-socials-text">&nbsp;<Skeleton width={80} /></p>
                    <p className="box-socials-text">&nbsp;<Skeleton width={80} /></p>
                    <p className="box-socials-text">&nbsp;<Skeleton width={80} /></p>
                    <p className="box-socials-text">&nbsp;<Skeleton width={80} /></p>
                </div>
            </StyledBodyHeaderProfile>
            <StyledTabsHeaderProfile>
                <li className={`box-tab`}>
                    <i className="fa-solid fa-book"></i>&nbsp;Biografia
                </li>
                <li className={`box-tab`}>
                    <i className="fa-solid fa-shield-halved"></i>&nbsp;Cuenta
                </li>
                <li className={`box-tab`}>
                    <i className="fa-solid fa-lock"></i>&nbsp;Cambiar Contraseña
                </li>
            </StyledTabsHeaderProfile>
        </StyledHeaderProfile>
    )
}

export default SkeletonHeaderProfile